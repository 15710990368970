/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setTeamManagement,
    setTmAmount,
    setTradeRelationship,
    setTrAmount,
} from '../../redux/features/SharedSalaryModule/evaluationDataSlice';
import { styles } from './styles';

const KPI_FIELDS = {
    TRADE_RELATIONSHIP: 'tr',
    TEAM_MANAGEMENT: 'tm',
};

export default function VariablePayCalculate({
    title,
    percentage,
    totalPayableAmount,
    allocatedAmount,
    data,
}) {
    const dispatch = useDispatch();
    const { trAmount, tmAmount, totalKpiAmount, variablePayTotal } = useSelector(
        (state) => state?.currentEvaluation
    );

    useEffect(() => {
        // dispatch(setInitialKpiAmount(totalPayableAmount));
    }, [dispatch, totalPayableAmount]);

    return (
        <div style={{ marginBottom: '20px' }}>
            <h4 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '-6px' }}>
                {title} ({percentage}%){' '}
                <span style={{ float: 'right' }}>Amount {allocatedAmount} Tk</span>
            </h4>
            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.cell}>Criteria</th>
                        <th style={{ ...styles.cell, textAlign: 'center' }}>Achievement</th>
                        <th style={{ ...styles.cell, textAlign: 'center' }}>Earned Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map(({ earned, achievement, title: criteria }, index) => {
                        const lowerCaseCriteria = criteria?.toLowerCase();

                        if (lowerCaseCriteria === 'trade relationship') {
                            return (
                                <KpiRow
                                    key={`tr-${index}`}
                                    criteria={criteria}
                                    achievement={achievement}
                                    earned={trAmount}
                                    // amount={trAmount}
                                    field={KPI_FIELDS.TRADE_RELATIONSHIP}
                                />
                            );
                        }

                        if (lowerCaseCriteria === 'team management') {
                            return (
                                <KpiRow
                                    key={`tm-${index}`}
                                    criteria={criteria}
                                    achievement={achievement}
                                    earned={tmAmount}
                                    // amount={tmAmount}
                                    field={KPI_FIELDS.TEAM_MANAGEMENT}
                                />
                            );
                        }

                        return (
                            <KpiRow
                                key={`default-${index}`}
                                criteria={criteria}
                                achievement={achievement}
                                earned={earned}
                            />
                        );
                    })}
                    <tr style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '-6px' }}>
                        <td style={{ ...styles.cell, fontWeight: 'bold' }}>Total</td>
                        <td style={styles.cell} />
                        <td style={{ ...styles.cell, textAlign: 'center', fontWeight: 'bold' }}>
                            {data?.reduce(
                                (total, { title: criteria }) =>
                                    criteria?.toLowerCase() === 'trade relationship' ||
                                    criteria?.toLowerCase() === 'team management'
                                        ? totalPayableAmount + trAmount + tmAmount
                                        : totalPayableAmount,
                                0
                            )}{' '}
                            Tk
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

// Constants
const RATING_OPTIONS = [
    { label: 'Poor', value: 0 },
    { label: 'Average', value: 33.33 },
    { label: 'Good', value: 66.67 },
    { label: 'Excellent', value: 100 },
];

const BASE_KPI_AMOUNT = 470;

// Helper function to calculate KPI amount
const calculateKpiAmount = (value) => Math.round((value / 100) * BASE_KPI_AMOUNT);

// Reusable Select Component
function RatingSelect({ onSelect, onClear, defaultValue }) {
    return (
        <Select
            // allowClear
            style={{ width: 200 }}
            placeholder="Select Rating"
            options={RATING_OPTIONS}
            onSelect={onSelect}
            onClear={onClear}
            defaultValue={defaultValue}
        />
    );
}

// Reusable Table Row Component
function KpiRow({ criteria, achievement, earned, amount, field }) {
    const dispatch = useDispatch();
    const { evaLuationData, teamManagement, tradeRelationship } = useSelector(
        (state) => state?.currentEvaluation
    );

    const handleSelect = (value, obj) => {
        const kpiAmount = calculateKpiAmount(value);

        const payload = {
            achievement: value,
            earned: kpiAmount,
        };

        const selectedKpi = {
            label: obj.label,
            value: obj.value,
            earned: kpiAmount,
        };

        dispatch(
            field === KPI_FIELDS.TEAM_MANAGEMENT
                ? setTeamManagement(selectedKpi)
                : setTradeRelationship(selectedKpi)
        );

        dispatch(
            field === KPI_FIELDS.TRADE_RELATIONSHIP ? setTrAmount(payload) : setTmAmount(payload)
        );
    };

    const handleClear = () => {
        dispatch(field === KPI_FIELDS.TRADE_RELATIONSHIP ? setTrAmount(0) : setTmAmount(0));
    };

    return (
        <tr>
            <td style={styles.cell}>{criteria}</td>
            <td
                style={{
                    ...styles.cell,
                    color: achievement < 100 ? '#ff4d4f' : 'inherit',
                    textAlign: 'center',
                }}
            >
                {field ? (
                    <RatingSelect
                        onSelect={handleSelect}
                        defaultValue={
                            criteria === 'Team Management' ? teamManagement : tradeRelationship
                        }
                    />
                ) : (
                    `${achievement}%`
                )}
            </td>
            <td style={{ ...styles.cell, textAlign: 'center' }}>{amount || earned}</td>
        </tr>
    );
}
