/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Checkbox, Form, Input, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import challengeRemarks from '../../../constents/challangeRemarks';
import { updateResponse } from '../../../redux/features/execution/complianceSlice';

function ComplianceSection({ section }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const responses = useSelector((state) => state.compliance.responses);

    const handleStatusChange = (item, value) => {
        dispatch(
            updateResponse({
                category: section.kind,
                item: {
                    name: item,
                    status: value ? 'Passed' : 'Failed',
                    remarks: responses[section.kind]?.[item]?.remarks || '',
                },
            })
        );
    };

    const handleRemarksChange = (item, value) => {
        dispatch(
            updateResponse({
                category: section.kind,
                item: {
                    name: item,
                    status: responses[section.kind]?.[item]?.status || '',
                    remarks: value,
                },
            })
        );
    };

    return (
        <div style={{ marginBottom: '1.5rem' }}>
            {/* Section Header */}
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 2fr',
                    justifyContent: 'center',
                    gap: '1rem',
                    marginBottom: '0.5rem',
                }}
            >
                <div style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{section.kind}</div>
                <div style={{ textAlign: 'center', fontSize: '1.1rem', fontWeight: 'bold' }}>
                    Passed
                </div>
                <div style={{ textAlign: 'center', fontSize: '1.125rem', fontWeight: 'bold' }}>
                    Failed
                </div>
                <div style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Remarks</div>
            </div>
            <Form form={form}>
                {/* Items */}
                {section?.challenge?.length > 0 &&
                    section?.challenge?.map((item) => (
                        <div
                            key={item}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr 2fr',
                                gap: '1rem',
                                alignItems: 'center',
                                padding: '0.2rem 0',
                            }}
                        >
                            <div>{item}</div>

                            {/* Passed Checkbox */}
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Checkbox
                                    size="large"
                                    checked={responses[section.kind]?.[item]?.status === 'Passed'}
                                    onChange={(e) => handleStatusChange(item, e.target.checked)}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Checkbox
                                    checked={responses[section.kind]?.[item]?.status === 'Failed'}
                                    onChange={(e) => handleStatusChange(item, !e.target.checked)}
                                />
                            </div>

                            {/* Remarks Column */}
                            <div style={{ display: 'grid', gap: '0.5rem' }}>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please Select"
                                    value={responses[section.kind]?.[item]?.remarks}
                                    onChange={(value) => handleRemarksChange(item, value)}
                                    options={challengeRemarks}
                                />
                                {responses[section.kind]?.[item]?.remarks === 'Others' && (
                                    <Input
                                        placeholder="Write Remarks"
                                        // onChange={(e) => handleRemarksChange(item, e.target.value)}
                                        onBlur={(e) => handleRemarksChange(item, e.target.value)}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
            </Form>

            <div style={{ borderBottom: '1px solid #ddd', marginTop: '1rem' }} />
        </div>
    );
}

export default ComplianceSection;
