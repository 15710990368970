import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import outletTypes from '../../constents/outletTypes';
import {
    setChannel,
    setMonth,
    setOutletCode,
    setOutletType,
    setRoute,
} from '../../redux/features/pjpManagement/pjpStatusFilterSlice';

function PjpStatusFilter({ queryFunc, loading, downloadButton }) {
    const { month, route, outletCode, channel, outletType } = useSelector(
        (state) => state.pjpStatusFilter
    );

    const dispatch = useDispatch();

    const monthChange = (_, dateString) => {
        dispatch(setMonth(dateString));
    };

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <DatePicker
                    size="large"
                    style={{ width: '100%' }}
                    onChange={monthChange}
                    picker="month"
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={route}
                    placeholder="Route"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setRoute(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={outletCode}
                    placeholder="Outlet Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setOutletCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Channel"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setChannel(e))}
                    options={[
                        {
                            value: 'UNG',
                            label: 'UNG',
                        },
                        {
                            value: 'UWMG',
                            label: 'UWMG',
                        },
                        {
                            value: 'UHPCT',
                            label: 'UHPCT',
                        },
                        {
                            value: 'UCS',
                            label: 'UCS',
                        },
                        {
                            value: 'UGS',
                            label: 'UGS',
                        },
                        {
                            value: 'DS',
                            label: 'DS',
                        },
                        {
                            value: 'WS',
                            label: 'WS',
                        },
                        {
                            value: 'OH',
                            label: 'OH',
                        },
                        {
                            value: 'HOTEL',
                            label: 'HOTEL',
                        },
                        {
                            value: 'E-COMM',
                            label: 'E-COMM',
                        },
                        {
                            value: 'CDO',
                            label: 'CDO',
                        },
                        {
                            value: 'FOOD',
                            label: 'FOOD',
                        },
                        {
                            value: 'RWMG',
                            label: 'RWMG',
                        },
                        {
                            value: 'RNG',
                            label: 'RNG',
                        },
                        {
                            value: 'RCS',
                            label: 'RCS',
                        },
                        {
                            value: 'PGS',
                            label: 'PGS',
                        },
                        {
                            value: 'POLLYDUT',
                            label: 'POLLYDUT',
                        },
                        {
                            value: 'CDOW',
                            label: 'CDOW',
                        },
                        {
                            value: 'RMG STORES',
                            label: 'RMG STORES',
                        },
                        {
                            value: 'RMGGROCER',
                            label: 'RMGGROCER',
                        },
                        {
                            value: 'SSS',
                            label: 'SSS',
                        },
                        {
                            value: 'CDOM',
                            label: 'CDOM',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    mode="multiple"
                    allowClear
                    placeholder="Outlet Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOutletType(e))}
                    options={outletTypes}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={() => queryFunc(1, 100, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<DownloadOutlined />}
                    danger
                    type="primary"
                    onClick={() => {
                        downloadButton({
                            url: '/v1/pjp-management/download-active-file',
                            fileName: 'PjpData.xlsx',
                        });
                    }}
                >
                    Download
                </Button>
            </Col>
        </>
    );
}

export default PjpStatusFilter;
