import { Button, Col, Form, Input, Modal, Radio, Row, Select, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import {
    useGetAreaListQuery,
    useGetCircleListQuery,
    useGetDhListQuery,
    useGetRegionListQuery,
    useGetTerritoryListQuery,
} from '../../redux/features/dataManagement/dataManagementApi';
import {
    useLandingPageListQuery,
    useUpdatePortalUserMutation,
    useViewGroupIdsQuery,
} from '../../redux/features/superuser/superUserApi';
import { useSearchEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import firebaseLog from '../../util/firebaseLog';

function UserEditModal({ isModalOpen, setIsModalOpen, userData, fetchSearchData, page, limit }) {
    const { Option } = Select;
    const [form] = Form.useForm();

    const [load, setLoad] = useState(true);

    const { data: groupData, isLoading: groupLoading } = useViewGroupIdsQuery();
    const { data: landingPageData, isLoading: landingPageLoading } = useLandingPageListQuery();

    // get dropdown data
    const { data: circleList, isLoading: cLoading } = useGetCircleListQuery();
    const { data: regionList, isLoading: rLoading } = useGetRegionListQuery();
    const { data: areaList, isLoading: aLoading } = useGetAreaListQuery();
    const { data: territoryList, isLoading: tLoading } = useGetTerritoryListQuery();
    const { data: dhList, isLoading: dLoading } = useGetDhListQuery();

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading: searchLoading }] =
        useSearchEmployeeMutation();

    const [accessLevel, setAccessLevel] = useState('');
    useEffect(() => {
        setAccessLevel(userData.userType);
    }, [userData.userType]);
    const [level, setLevel] = useState('');

    const [updatePortalUser, { isLoading }] = useUpdatePortalUserMutation();

    const onFinish = async ({
        name,
        username,
        email,
        rolesId,
        landingPage,
        accessDetails,
        ids,
    }) => {
        try {
            const bodyData = {};
            if (name) {
                bodyData.name = name;
            }
            if (username) {
                bodyData.username = username;
            }
            if (rolesId) {
                bodyData.rolesId = rolesId;
            }
            if (email) {
                bodyData.email = email;
            }
            if (landingPage) {
                bodyData.landingPage = landingPage;
            }
            if (accessDetails) {
                if (accessDetails === 'Circle Level') {
                    bodyData.circleId = ids;
                }
                if (accessDetails === 'Region Level') {
                    bodyData.regionId = ids;
                }
                if (accessDetails === 'Area Level') {
                    bodyData.areaId = ids;
                }
                if (accessDetails === 'Territory Level') {
                    bodyData.territoryId = ids;
                }
                if (accessDetails === 'Distribution Level') {
                    bodyData.dhId = ids;
                }
                if (accessDetails === 'TMS Level') {
                    bodyData.tmsId = ids;
                }
            }
            const result = await updatePortalUser({
                data: { ...bodyData },
                id: userData.userId,
            });
            if (result.error?.data?.message) {
                message.error(result.error?.data?.message);
            }
            if (result?.data?.message) {
                message.success(result?.data?.message);
            }
            form.resetFields();
            setIsModalOpen(false);
        } catch (error) {
            message.error('Something went wrong');
        }
    };

    const onChange = ({ accessDetails }) => {
        if (accessDetails) {
            setAccessLevel(accessDetails);
            setLevel(accessDetails.split(/(?=[A-Z])/)[0]);
        }
    };

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    function renderAccessData(lev) {
        if (lev === 'Circle Level') {
            return circleList?.data;
        }
        if (lev === 'Region Level') {
            return regionList?.data;
        }
        if (lev === 'Area Level') {
            return areaList?.data;
        }
        if (lev === 'Territory Level') {
            return territoryList?.data;
        }
        if (lev === 'Distribution Level') {
            return dhList?.data;
        }
        if (lev === 'TMS Level') {
            return (
                employeeData?.data?.map((user) => ({
                    label: user.name,
                    // eslint-disable-next-line no-underscore-dangle
                    value: user._id,
                })) || []
            );
        }
        return [];
    }

    useEffect(() => {
        if (accessLevel === 'TMS Level') {
            searchEmployee({
                type: 'tms',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessLevel]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Edit Superuser', user: user.name });
    }, [user.name]);

    const [search2, setSearch2] = useState('');
    const onSearch2 = (e) => {
        setSearch2(e);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal
            title="Edit Employee Information"
            open={isModalOpen}
            onCancel={handleCancel}
            okText="Update"
            footer={null}
            confirmLoading={isLoading}
        >
            {/* page title and description */}
            <HelmetHeader title="Edit Superuser" />

            {groupLoading ||
            landingPageLoading ||
            cLoading ||
            aLoading ||
            rLoading ||
            dLoading ||
            tLoading ? (
                <Spin />
            ) : (
                <div
                    style={{
                        marginTop: '10px',
                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                        padding: '10px',
                        borderRadius: '10px',
                    }}
                >
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        onValuesChange={onChange}
                    >
                        <Row gutter={[10, 10]}>
                            <Col lg={8} md={12} sm={24}>
                                <Form.Item label="Name" name="name">
                                    <Input defaultValue={userData.name} />
                                </Form.Item>

                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Please enter a valid email address',
                                        },
                                    ]}
                                >
                                    <Input defaultValue={userData.email} />
                                </Form.Item>
                            </Col>
                            <Col lg={8} md={12} sm={24}>
                                <Form.Item label="Username" name="username">
                                    <Input defaultValue={userData.username} />
                                </Form.Item>
                            </Col>
                            <Col lg={8} md={12} sm={24}>
                                <Form.Item label="Group Name" name="rolesId">
                                    <Select
                                        loading={groupLoading}
                                        options={groupData?.data?.filter(
                                            (x) => x.label !== 'Superadmin'
                                        )}
                                        defaultValue={
                                            groupData?.data?.find(
                                                (x) => x.label === userData.groupName
                                            ).value
                                        }
                                    />
                                </Form.Item>

                                <Form.Item label="Landing Page" name="landingPage">
                                    <Select
                                        showSearch
                                        options={landingPageData?.data}
                                        loading={landingPageLoading}
                                        searchValue={search2}
                                        onSearch={onSearch2}
                                        defaultValue={
                                            landingPageData?.data?.find(
                                                (x) => x.label === userData.landingPage
                                            ).value
                                        }
                                        filterOption={(input, option) =>
                                            option.props.label
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={8} md={12} sm={24}>
                                <Form.Item label="Access Details" name="accessDetails">
                                    <Radio.Group defaultValue={userData.userType}>
                                        <Radio value="Admin Level">Admin Level</Radio>
                                        <Radio value="Circle Level">Circle Level</Radio>
                                        <Radio value="Region Level">Cluster Level</Radio>
                                        <Radio value="Area Level">Area Level</Radio>
                                        <Radio value="Territory Level">Territory Level</Radio>
                                        <Radio value="Distribution Level">DH Level</Radio>
                                        <Radio value="TMS Level">TMS Level</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {userData.userType !== 'Admin Level' || level !== 'Admin' ? (
                                <Col lg={8} md={12} sm={24}>
                                    <Form.Item
                                        label={level === 'T' ? 'TMS' : level || userData.userType}
                                        name="ids"
                                    >
                                        {accessLevel && renderAccessData(accessLevel)?.length ? (
                                            <Select
                                                showSearch
                                                mode="multiple"
                                                defaultValue={
                                                    userData.userType === accessLevel
                                                        ? userData.accessOf
                                                              .split(', ')
                                                              .map(
                                                                  (x) =>
                                                                      renderAccessData(
                                                                          accessLevel
                                                                      )?.find((y) => y.label === x)
                                                                          ?.value || null
                                                              )
                                                        : null
                                                }
                                                options={
                                                    accessLevel ? renderAccessData(accessLevel) : []
                                                }
                                                searchValue={search}
                                                onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    option.props.label
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        ) : null}
                                    </Form.Item>
                                </Col>
                            ) : null}
                        </Row>

                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button style={{ width: '120px' }} type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            )}
        </Modal>
    );
}

export default UserEditModal;
