import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CountUp from 'react-countup';

function VisitCall({ visitCallData, totalVisitCall }) {
    const styles = {
        FAT: {
            name: 'Fixed Asset Tracking',
            color1: '#5DBD9B',
            color2: '#5DBD9B',
        },
        POSM: {
            name: 'Visibility Material Execution',
            color1: '#FB992E',
            color2: '#F33170',
        },
        SOS: {
            name: 'Share Of Shelf',
            color1: '#134D82',
            color2: '#134D82',
        },
        DA: {
            name: 'PS & NS Display Audit',
            color1: '#493F97',
            color2: '#616ADA',
        },
        QPDS: {
            name: 'QPDS',
            color1: '#7D9EF3',
            color2: '#1D6DEF',
        },
    };

    // graph data
    const data = visitCallData?.map((x) => ({
        id: crypto.randomUUID(),
        data: x.quantity,
        mapped: x.mapped,
        ...styles[x.name],
    }));

    const stylesClasses = {
        visitCallItem: {
            fontSize: window.innerWidth <= 750 ? '10px' : '16px',
            margin: 0,
        },
        linearText: {
            fontSize: window.innerWidth <= 750 ? '20px' : '50px',
        },
    };

    return (
        <div className="box-container" style={{ height: '260px' }}>
            <div className="box-heading box-heading-dash">Visibility Tracker</div>
            <div
                className="box-body"
                style={{
                    alignItems: 'center',
                    height: '85%',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '0 30px',
                    flexWrap: 'wrap',
                }}
            >
                {data.map((x, i) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={x.id}
                        style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                        }}
                    >
                        <div>
                            <h1
                                className="linearText"
                                style={{
                                    ...stylesClasses.linearText,
                                    fontWeight: 900,
                                    background: `linear-gradient(to right, ${x.color1}, ${x.color2})`,
                                    backgroundClip: 'text',
                                    margin: '0 0 10px 0',
                                }}
                            >
                                <CountUp end={x.data} />
                            </h1>
                            <p style={stylesClasses.visitCallItem}>{x.name}</p>
                            <p style={stylesClasses.visitCallItem}>
                                (<CountUp end={x.mapped} />)
                            </p>
                        </div>
                        {i !== (data.length - 1 || 0) && window.innerWidth > 750 && (
                            <div
                                style={{
                                    height: '120px',
                                    width: '1px',
                                    background: '#cdcdcd',
                                    // marginTop: '20px',
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className="chart-button-container">
                <div style={{ textAlign: 'right', padding: '0px 10px 10px 10px' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>Total Visit Call:</p>
                    <p style={{ fontSize: '15px', fontWeight: 700 }}>
                        <CountUp end={totalVisitCall} />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default VisitCall;
