/* eslint-disable consistent-return */
import { Button, message, Modal, Spin } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    formatResponsesForSubmission,
    resetResponses,
    setAuditModalOpen,
} from '../../../redux/features/execution/complianceSlice';
import {
    useAuditChallengeMutation,
    useGetAiResultQuery,
} from '../../../redux/features/report/visitCallApi';
import ComplianceSection from './ComplianceSection';

function ComplianceModal({ showAuditModal, setShowAuditModal, executionId }) {
    const dispatch = useDispatch();
    const { isModalOpen, data, responses } = useSelector((state) => state.compliance);

    const [postAudit, options] = useAuditChallengeMutation();

    // ai result get api hook
    const {
        data: aiData,
        refetch,
        isLoading,
    } = useGetAiResultQuery(
        { id: executionId },
        {
            skip: !executionId || !showAuditModal,
        }
    );

    const handleCancel = () => {
        setShowAuditModal(false);
        dispatch(resetResponses());
    };

    const onClose = () => {
        dispatch(resetResponses());
        setShowAuditModal(false);
    };

    const handleSubmit = async () => {
        let isError = false;
        aiData?.data?.challenged?.forEach(({ kind, challenge }) => {
            const isExits = responses[kind];
            if (!isExits) {
                isError = true;
                return;
            }
            const haveAllChallenge = challenge.every((it) => {
                const obj = isExits[it];
                if (!obj) {
                    isError = true;
                    return false;
                }
                return true;
            });

            if (!haveAllChallenge) {
                isError = true;
            }
        });

        if (isError) {
            return message.error('All fields are required');
        }
        const formattedData = formatResponsesForSubmission(responses);

        const payload = { ...formattedData, executionId };
        const dataToSubmit = { ...formattedData, executionId };
        const res = await postAudit(dataToSubmit);
        console.log(res);

        if (res?.data?.message) {
            message.success('Salary details submitted successfully');
            dispatch(setAuditModalOpen(false));
            onClose();
        } else {
            message.error('Something went wrong');
            // dispatch(setAuditModalOpen(false));
        }
    };

    return (
        <Modal
            open={showAuditModal}
            onCancel={onClose}
            onClose={onClose}
            width={1000}
            footer={[
                <Button key="cancel" danger onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Submit
                </Button>,
            ]}
        >
            <div>
                <h2
                    style={{
                        marginBottom: '20px',
                        fontSize: '30px',
                        fontWeight: 'bold',
                    }}
                >
                    Audit Challenges
                </h2>
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px',
                        }}
                    >
                        <Spin spinning={isLoading} tip="Loading..." size="large" />
                    </div>
                ) : (
                    <div>
                        {aiData?.data?.challenged?.map(
                            (section) =>
                                section?.challenge?.length > 0 && (
                                    <ComplianceSection key={section?.kind} section={section} />
                                )
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default ComplianceModal;
