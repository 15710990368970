/* eslint-disable no-underscore-dangle */
import { Pagination, Table, message } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { setMonth } from '../../redux/features/SharedSalaryModule/salaryEvaluationFilterSlice';
import { useGetAllSalaryStatusMutation } from '../../redux/features/SharedSalaryModule/salaryEvaluationsApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import generateMonthColumns from '../../util/generateMonthColumns';

function SalaryStatus() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);
    const dispatch = useDispatch();

    const getTownPosmHistory = () => {};
    const data = {
        data: [],
        meta: {
            total: 0,
        },
    };

    const [triggerSalaryStatus, salaryStatusOptions] = useGetAllSalaryStatusMutation();

    const handleTriggerMerchandiser = useCallback(
        async (payload) => {
            try {
                const res = await triggerSalaryStatus(payload);
            } catch (error) {
                message.error('Something went wrong');
            }
        },
        [triggerSalaryStatus]
    );

    useEffect(() => {
        dispatch(setMonth(''));
        handleTriggerMerchandiser({
            month: dayjs().format('MMMM'),
            year: dayjs().format('YYYY'),
            // disbursed: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleTriggerMerchandiser]);

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);
    const { status, month, year, ffLevel, received } = useSelector(
        (state) => state?.salaryEvaluationFilter
    );

    const getFilterData = (statu, mont, yer, ffLeve, receive) => {
        const bodyData = {};

        if (status) {
            bodyData.status = statu;
        }
        if (month) {
            bodyData.month = mont;
        }
        if (yer) {
            bodyData.year = yer;
        }
        if (ffLevel) {
            bodyData.ffLevel = ffLeve;
        }
        if (received) {
            bodyData.received = receive;
        }
        bodyData.disbursed = true;

        return bodyData;
    };

    const fetchDhHistoryData = async (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }

        const res = await triggerSalaryStatus({
            page,
            limit: totalShow,
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            ...getFilterData(status, month, year, ffLevel, received),
        });
    };

    useEffect(() => {
        getTownPosmHistory({ page: currentPage, limit: totalShowPage });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchDhHistoryData(pageNumber, totalPageChange);
    };

    const { travelPenaltyReason, basicPenaltyReason } = salaryStatusOptions?.data?.data || {};

    // Generate columns dynamically based on the first row
    const monthColumns = generateMonthColumns(salaryStatusOptions?.data?.data || []);
    const columns = [
        {
            title: 'Merchandiser Name',
            dataIndex: 'user',
            key: 'user',
            render: (user) => user?.name,
        },
        { title: 'FF Code', dataIndex: 'user', key: 'user', render: (user) => user?.usercode },
        { title: 'FF Level', dataIndex: 'user', key: 'user', render: (user) => user?.userType },
        {
            title: 'Cluster',
            dataIndex: 'town',
            key: 'town',
            render: (regions) => regions?.map((t) => t.region)?.join(', '),
        },
        {
            title: 'Area',
            dataIndex: 'town',
            key: 'town',
            render: (areas) => areas?.map((t) => t.area).join(', '),
        },
        {
            title: 'Territory',
            dataIndex: 'town',
            key: 'town',
            render: (territorys) => territorys?.map((t) => t.territory).join(', '),
        },
        {
            title: 'Town',
            dataIndex: 'town',
            key: 'town',
            render: (towns) => towns?.map((t) => t.name).join(', '),
        },
        ...monthColumns,
        {
            title: 'Eligibility',
            dataIndex: 'variablePayEligibility',
            key: 'variablePayEligibility',
            render: (variablePayEligibility) =>
                variablePayEligibility ? 'Eligible' : 'Not Eligible',
        },
        {
            title: 'Basic',
            dataIndex: ['payableBaseSalary', 'totalBaseSalary'],
            key: 'payableBaseSalary',
            align: 'center',
        },
        {
            title: 'Travel Allowance',
            dataIndex: ['payableTravelAllowance', 'totalTravelAllowance'],
            key: 'payableTravelAllowance',
            align: 'center',
        },
        {
            title: 'Variable Pay',
            dataIndex: 'totalPayableIncentive',
            key: 'totalPayableIncentive',
            align: 'center',
        },
        { title: 'Status', dataIndex: 'user', key: 'status', render: (user) => user?.status },
        { title: 'Date', dataIndex: 'actionDate', key: 'actionDate' },
        { title: 'Received', dataIndex: 'received', key: 'received' },
        {
            title: 'Time Stamp',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => dayjs(createdAt).format('DD/MM/YYYY hh:mm A'),
        },
    ];

    return (
        <>
            <HelmetHeader title="Salary Status" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    // downloadButton={download}
                    loading={salaryStatusOptions?.isLoading}
                    queryFunc={fetchDhHistoryData}
                    pathname="/salary-status"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Salary Status</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {' '}
                    <Table
                        loading={salaryStatusOptions?.isLoading}
                        scroll={{
                            x: 'auto',
                        }}
                        columns={columns}
                        dataSource={salaryStatusOptions?.data?.data || []}
                        size="small"
                        rowKey={(record) => record?._id}
                        pagination={false}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Pagination
                    size="large"
                    pageSize={totalShowPage}
                    showSizeChanger
                    showQuickJumper
                    current={currentPage}
                    defaultCurrent={1}
                    total={data?.meta.total || 0}
                    onChange={onChange}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div>
        </>
    );
}

export default SalaryStatus;
