/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { EnvironmentOutlined } from '@ant-design/icons';
import { Button, Col, Image, Modal, Radio, Row, Space, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapModal from '../../components/MapModal';
import { setComplianceData } from '../../redux/features/execution/complianceSlice';
import { useGetAiResultQuery } from '../../redux/features/report/visitCallApi';
import formatAccuracy from '../../util/formatAccuracy';
import labelChange from '../../util/labelChange';
import { capitalizeFirstLetter } from '../../util/miniFunction';
import ComplianceModal from './Audit/ComplianceModal';
import CollapsiblePanel from './CollapsiblePanel';

const { Column, ColumnGroup } = Table;

const challenged2 = [
    {
        kind: 'Overall Complience',
        applicable: ['PONDS', 'GAL', 'HAIR CARE', 'NUTRITION', 'VIM DISPLAY'],
        challenge: [],
        passed: [],
    },
    {
        kind: 'Varient wise Complience',
        applicable: ['PONDS', 'VIM DISPLAY', 'SURF EXCEL DISPLAY'],
        challenge: [],
        passed: [],
    },
    {
        kind: 'Shelf Talker',
        applicable: ['PONDS', 'VIM DISPLAY', 'SURF EXCEL DISPLAY'],
        challenge: [],
        passed: [],
    },
    {
        kind: 'Exclusivity',
        applicable: ['PONDS', 'VIM DISPLAY', 'SURF EXCEL DISPLAY'],
        challenge: [],
        passed: [],
    },
    {
        kind: 'Planogram Adherence',
        applicable: ['NUTRITION', 'VIM DISPLAY', 'SURF EXCEL DISPLAY'],
        challenge: [],
        passed: [],
    },
];

function ExecutionItem({ data }) {
    const {
        _id,
        user: { name: ffName, userType: ffLevel, usercode: ffCode },
        town: { name: townName },
        outlet: { name: outletName, outletcode: outletCode, channel, contactNo, route },
        callType,
        passed,
        passedByMs,
        accuracy,
        compliance,
        variantWiseCompliance,
        reasonForNoExecution,
        withinRadius,
        executionStartAt,
        executionEndAt,
        scheduleDate,
        lat,
        lon,
        isAiReady,
        remarks,
        image,
        job,
        challenged,
        isAudited,
        challengedBy,
        jobStatus = {},
        confidenceScore,
    } = data;

    // modal state and function
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // const [isModalOpen2, setIsModalOpen2] = useState(false);
    // const showModal2 = () => {
    //     setIsModalOpen2(true);
    // };
    // const handleOk2 = () => {
    //     setIsModalOpen2(false);
    // };
    // const handleCancel2 = () => {
    //     setIsModalOpen2(false);
    // };

    const [skip, setSkip] = useState(false);
    // execution id
    const [executionId, setExecutionId] = useState('');

    const [showAuditModal, setShowAuditModal] = useState(false);

    // ai result get api hook
    const {
        data: aiData,
        refetch,
        isLoading,
    } = useGetAiResultQuery({ id: executionId }, { skip: !executionId });

    // post challenge data
    // const [
    //     challengeDataSubmit,
    //     { data: challengeFeedbackData, isLoading: challengeLoading, isError, isSuccess, error },
    // ] = useChallengeDataSubmitMutation();

    const collapseChange = (e) => {
        if (e.length) {
            setExecutionId(_id);
        }
    };

    useEffect(() => {
        if (executionId) {
            refetch();
        }
    }, [executionId, refetch]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setComplianceData(challenged2));
    }, [dispatch]);

    const displayChallengeData = challenged?.length
        ? challenged
              .find((x) => !x.shelfTalker)
              ?.challenge?.map((x, i) => ({ key: i + 1, name: x }))
        : [];

    const shelfTalkerChallengeData = challenged?.length
        ? challenged.find((x) => x.shelfTalker)?.challenge?.map((x, i) => ({ key: i + 1, name: x }))
        : [];

    const [displayHeData, setDisplayHeData] = useState({});

    const [shelfTalkerHeData, setShelfTalkerHeData] = useState({});

    const handleChange = (category, value) => {
        setDisplayHeData((prevData) => ({
            ...prevData,
            [category]: value,
        }));
    };
    const handleChangeShelfTalker = (category, value) => {
        setShelfTalkerHeData((prevData) => ({
            ...prevData,
            [category]: value,
        }));
    };

    // const handleSubmit = async () => {
    //     try {
    //         const displayAuditLength = Object.keys(displayHeData);
    //         const shelfTalkerAuditLength = Object.keys(shelfTalkerHeData);
    //         const totalLength =
    //             (displayAuditLength?.length || 0) + (shelfTalkerAuditLength?.length || 0) || 0;
    //         if (
    //             totalLength ===
    //             (displayChallengeData?.length || 0) + (shelfTalkerChallengeData?.length || 0)
    //         ) {
    //             // Handle form submission logic here
    //             const display = Object.entries(displayHeData)?.map(([name, status]) => ({
    //                 name,
    //                 status: status.charAt(0).toUpperCase() + status.slice(1),
    //             }));
    //             const shelfTalker = Object.entries(shelfTalkerHeData)?.map(([name, status]) => ({
    //                 name,
    //                 status: status.charAt(0).toUpperCase() + status.slice(1),
    //             }));
    //             const res = await challengeDataSubmit({
    //                 executionId: _id,
    //                 display,
    //                 shelfTalker,
    //             }).unwrap();
    //             message.success('Task complete');
    //             handleCancel2();
    //         } else {
    //             message.error('All Fields are required');
    //         }
    //     } catch (err) {
    //         message.error(err.message);
    //     }
    // };

    const columns = [
        {
            title: 'Display Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Passed',
            dataIndex: 'passed',
            key: 'passed',
            render: (text, record) => (
                <Radio.Group
                    size="large"
                    value={displayHeData[record.name]}
                    onChange={(e) => handleChange(record.name, e.target.value)}
                >
                    <Radio size="large" value="passed" />
                </Radio.Group>
            ),
        },
        {
            title: 'Failed',
            dataIndex: 'failed',
            key: 'failed',
            render: (text, record) => (
                <Radio.Group
                    size="large"
                    value={displayHeData[record.name]}
                    onChange={(e) => handleChange(record.name, e.target.value)}
                >
                    <Radio size="large" value="failed" />
                </Radio.Group>
            ),
        },
    ];
    const columns2 = [
        {
            title: 'Shelf Talker',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Passed',
            dataIndex: 'passed',
            key: 'passed',
            render: (text, record) => (
                <Radio.Group
                    size="large"
                    value={shelfTalkerHeData[record.name]}
                    onChange={(e) => handleChangeShelfTalker(record.name, e.target.value)}
                >
                    <Radio size="large" value="passed" />
                </Radio.Group>
            ),
        },
        {
            title: 'Failed',
            dataIndex: 'failed',
            key: 'failed',
            render: (text, record) => (
                <Radio.Group
                    size="large"
                    value={shelfTalkerHeData[record.name]}
                    onChange={(e) => handleChangeShelfTalker(record.name, e.target.value)}
                >
                    <Radio size="large" value="failed" />
                </Radio.Group>
            ),
        },
    ];

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    const editableUser = !!userInfo?.permission?.find((x) => x.label === 'Audit Editable')?.label;

    return (
        <>
            <Modal
                footer={false}
                title="Location in google Map"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width="850px"
                bodyStyle={{ padding: 0 }}
            >
                <MapModal lat={lat || 0} lon={lon || 0} />
            </Modal>

            <ComplianceModal
                setShowAuditModal={setShowAuditModal}
                showAuditModal={showAuditModal}
                executionId={_id}
            />

            <div className="execution-item">
                <Row
                    gap={[5, 5]}
                    justify="space-between"
                    style={!withinRadius ? { background: '#C50B00' } : {}}
                    className={
                        !withinRadius
                            ? 'execution-item-error execution-item-card'
                            : 'execution-item-card'
                    }
                >
                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span style={{ fontWeight: '500' }}>FF Name: </span>
                            {ffName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">FF Code: </span>
                            {ffCode}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">FF Level: </span>
                            {labelChange(ffLevel)}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Town: </span>
                            {townName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Code: </span>
                            {outletCode}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Name: </span>
                            {outletName}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Display Challenge Appl: </span>
                            {challenged?.length
                                ? challenged.find((x) => !x.shelfTalker)?.applicable.join(', ')
                                : ''}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Shelf Talker Challenge Appl: </span>
                            {challenged?.length
                                ? challenged.find((x) => x.shelfTalker)?.applicable?.join(', ')
                                : ''}
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Channel: </span>
                            {channel}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Mobile: </span>
                            {contactNo}
                        </p>
                        <p
                            className="ex-item-point"
                            style={{ overflowX: 'auto', overflowY: 'hidden' }}
                        >
                            <span className="ex-item-point-b">Route: </span>
                            {route}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Execution Done: </span>
                            {capitalizeFirstLetter(callType)}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Reason for No execution: </span>
                            {reasonForNoExecution}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Remarks: </span>
                            {remarks}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Reason for Error: </span>
                            {withinRadius ? '' : 'GEO Tag Error'}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Display Challenge Status: </span>
                            {challenged?.length
                                ? challenged.find((x) => !x.shelfTalker && x.challenge.length)
                                    ? `Yes (${challenged.find((x) => !x.shelfTalker)?.challenge?.join(', ')})`
                                    : 'No'
                                : ''}
                        </p>
                    </Col>

                    <Col lg={6} md={12} sm={24}>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Shelf Talker Challenge Status: </span>
                            {challenged?.length
                                ? challenged.find((x) => x.shelfTalker && x.challenge.length)
                                    ? `Yes (${challenged.find((x) => x.shelfTalker)?.challenge?.join(', ')})`
                                    : 'No'
                                : ''}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Confidence Score: </span>
                            {/* {job.includes('POSM')
                                ? `${confidenceScore >= 0 ? `${confidenceScore?.toFixed(2)}%` : ''}`
                                : ''} */}
                            {formatAccuracy(confidenceScore)}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">
                                Visibility Material AI Accuracy:{' '}
                            </span>
                            {/* {job.includes('POSM')
                                ? `${accuracy >= 0 ? `${accuracy?.toFixed(2)} %` : ''}`
                                : ''} */}
                            {formatAccuracy(accuracy)}
                        </p>
                        {/* <p className="ex-item-point">
                            <span className="ex-item-point-b">
                                Overall Audit Compliance by AI:{' '}
                            </span>
                            {compliance?.toFixed(2) || ''}
                            {compliance >= 0 ? '%' : ''}
                        </p> */}
                        {/* <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Status By AI: </span>
                            {typeof passed === 'boolean' ? (passed ? 'Passed' : 'Failed') : ''}
                        </p> */}
                        {/* <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Status By MS: </span>
                            {typeof passedByMs === 'boolean'
                                ? passedByMs
                                    ? 'Passed'
                                    : 'Failed'
                                : ''}
                        </p> */}

                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Status for Display: </span>
                            {typeof jobStatus.DA === 'boolean'
                                ? jobStatus.DA
                                    ? 'Passed'
                                    : 'Failed'
                                : ''}
                        </p>
                        {/* <p className="ex-item-point">
                            <span className="ex-item-point-b">Outlet Status for QPDS: </span>
                            {typeof jobStatus.QPDS === 'boolean'
                                ? jobStatus.QPDS
                                    ? 'Passed'
                                    : 'Failed'
                                : ''}
                        </p> */}
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Scheduled Date: </span>
                            {scheduleDate ? dayjs(scheduleDate).format('DD/MM/YYYY') : ''}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Visit Start Timestamp: </span>
                            {dayjs(executionStartAt).format('DD/MM/YYYY hh:mm:ss a')}
                        </p>
                        <p className="ex-item-point">
                            <span className="ex-item-point-b">Visit End Timestamp: </span>
                            {dayjs(executionEndAt).format('DD/MM/YYYY hh:mm:ss a')}
                        </p>
                    </Col>

                    <Col
                        lg={6}
                        md={12}
                        sm={24}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <div className="survey-last-section">
                            <Image.PreviewGroup
                                preview={{
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    countRender: (current) =>
                                        (
                                            <span
                                                style={{ background: 'black', padding: '2px 5px' }}
                                            >
                                                {image[current - 1]?.name}
                                            </span>
                                        ) || 'null',
                                }}
                            >
                                <Row gutter={[2, 2]}>
                                    {image?.map(({ original, thumb }, i) => (
                                        <Col key={original + i}>
                                            <Image
                                                width={45}
                                                preview={{ src: original }}
                                                src={thumb || ''}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Image.PreviewGroup>
                        </div>
                        <Space
                            direction="vertical"
                            style={{ marginLeft: '20px', alignItems: 'center' }}
                        >
                            <Button onClick={() => setIsModalOpen((prev) => !prev)}>
                                <EnvironmentOutlined />
                            </Button>
                            {/* {!isAudited && challengedBy && !isSuccess && editableUser ? ( */}
                            {challengedBy && editableUser ? (
                                <Button
                                    type="primary"
                                    // onClick={() => setIsModalOpen2((prev) => !prev)}
                                    onClick={() => setShowAuditModal(true)}
                                >
                                    {isAudited ? 'Re-Audit' : 'Audit'}
                                </Button>
                            ) : null}
                        </Space>
                    </Col>
                </Row>

                {callType === 'yes' ? (
                    <CollapsiblePanel executionItems={job} executionId={_id} />
                ) : null}
            </div>
        </>
    );
}

export default ExecutionItem;
