/* eslint-disable import/no-extraneous-dependencies */
import { Space } from 'antd';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import CountUp from 'react-countup';

function Sos({ sosData, totalSosVisit }) {
    const lablesData = {
        'Reckitt Benckser': 'RKB',
        'Abul Khair': 'ABK',
        'ARLA FOODs': 'ARF',
        'Advanced Chemical Industries Limited': 'ACI',
        'Anfords BD Ltd': 'ANFB',
        'Unilever Bangladesh Limited': 'UBL',
        'Keya Cosmetic Ltd': 'KEYA',
        'Kohinoor Chemical Ltd': 'KHNC',
        'Square Toiletries Ltd': 'SQRT',
        'Sun Basic Chemicals Ltd': 'SBCL',
        'Rohit Surfactants Pvt Ltd': 'RSPL',
        'Marico Bangladesh Ltd	': 'MBL',
        'Emami Ltd': 'EML',
        'Himalaya Global Ltd': 'HGL',
        'Transcom Distribution': 'TRD',
        Himalaya: 'HGL',
        Unilever: 'UBL',
        ACI: 'ACI',
    };

    // pie chart data
    const pieOptions = {
        chart: {
            width: 380,
            type: 'donut',
            background: 'transparent',
        },
        stroke: {
            width: 0,
            colors: ['#F44336', '#1D49EA', '#E91E63', '#9C27B0', '#9C27FE'],
        },
        legend: {
            show: false,
            position: 'left',
        },
        dataLabels: {
            enabled: true,
            formatter(val, r) {
                return `${lablesData[r.w.config.labels[r.seriesIndex]]} ${Math.round(val)}%`;
            },
        },
    };

    // pie options
    const pieOptionsData = sosData?.map((x) => ({ labels: [...x.labels], ...pieOptions }));

    const widthCalculation = (len) => {
        const height = len * 235;
        if (window.innerWidth <= 750) {
            return `${height}px`;
        }
        if (len <= 3) return '280px';
        if (len <= 6) return '520px';
        if (len <= 9) return '760px';
        if (len <= 12) return '980px';
        return '1300px';
    };

    return (
        <div className="box-container" style={{ height: widthCalculation(sosData?.length) }}>
            <div className="box-heading box-heading-dash">Share Of Shelf</div>
            <div className="box-body">
                {/* data render */}
                <Space direction="vertical">
                    <Space wrap direction="">
                        {sosData?.map((item, i) => (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <React.Fragment key={item.name}>
                                {i <= 2 ? (
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <p
                                                style={{
                                                    margin: '0 0 -15px 0',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                    color: '#0050a4',
                                                }}
                                            >
                                                {item.name}
                                            </p>
                                            <ReactApexChart
                                                options={pieOptionsData[i] || {}}
                                                series={item.data}
                                                type="pie"
                                            />
                                        </div>
                                        {i !== 2 && window.innerWidth > 750 && (
                                            <div
                                                style={{
                                                    height: '170px',
                                                    width: '1px',
                                                    background: '#cdcdcd',
                                                    marginTop: '20px',
                                                }}
                                            />
                                        )}
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ))}
                    </Space>
                    {sosData?.length >= 3 ? (
                        <Space wrap>
                            {sosData?.map((item, i) => (
                                // eslint-disable-next-line react/jsx-no-useless-fragment
                                <React.Fragment key={item.name}>
                                    {i >= 3 ? (
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <p
                                                    style={{
                                                        margin: '0 0 -15px 0',
                                                        fontSize: '16px',
                                                        fontWeight: 600,
                                                        color: '#0050a4',
                                                    }}
                                                >
                                                    {item.name}
                                                </p>
                                                <ReactApexChart
                                                    colors
                                                    options={pieOptionsData[i] || {}}
                                                    series={item.data}
                                                    type="pie"
                                                />
                                            </div>
                                            {i !== 5 && window.innerWidth > 750 && (
                                                <div
                                                    style={{
                                                        height: '170px',
                                                        width: '1px',
                                                        background: '#9D9D9D',
                                                        marginTop: '20px',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ) : null}
                                </React.Fragment>
                            ))}
                        </Space>
                    ) : null}
                </Space>
            </div>
            <div className="chart-button-container">
                <div style={{ textAlign: 'right', padding: '0px 10px 10px 10px' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>Total SOS Visit:</p>
                    <p style={{ fontSize: '15px', fontWeight: 700 }}>
                        <CountUp end={totalSosVisit} />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Sos;
