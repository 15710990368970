/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import {
    AndroidOutlined,
    EditOutlined,
    KeyOutlined,
    StopOutlined,
    UnlockOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Image, Pagination, Popconfirm, Space, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setGlobalLoading, setReFetchFilter } from '../../redux/features/loaderSlice';
import {
    useSearchEmployeeMutation,
    useSuspendUserApiMutation,
    useUnLockUserMutation,
    useUnregisterUserDeviceMutation,
} from '../../redux/features/teamManagement/teamManagementApi';
import { resetTeamManagementFilter } from '../../redux/features/teamManagement/teamManagementFilterSlice';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import labelChange from '../../util/labelChange';
import { capitalizeFirstLetter } from '../../util/miniFunction';
import EditModal from './EditModal';
import PasswordChangeModal from './PasswordChange';

const { Column, ColumnGroup } = Table;

function TeamManagement() {
    const { globalLoading } = useSelector((state) => state.globalLoading);
    const { accessToken } = useSelector((state) => state.auth);

    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // search employee api hook
    const [searchEmployee, { data, isLoading }] = useSearchEmployeeMutation();
    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);
    const { empId, empCode, empName, empUsername, empLevel, supervisor, idLock, status } =
        useSelector((state) => state.teamManagementFilter);

    const getFilterData = (eId, eCode, eName, eUsername, lev, sCode, idlock, sts) => {
        const bodyData = {};
        if (eId) {
            bodyData.employeeNo = Number(eId);
        }
        if (eCode) {
            bodyData.employeeCode = eCode;
        }
        if (eName) {
            bodyData.employeeName = eName;
        }
        if (eUsername) {
            bodyData.employeeUsername = eUsername;
        }
        if (lev) {
            bodyData.type = lev;
        }
        if (sCode) {
            bodyData.supervisorId = [sCode];
        }
        if (idlock) {
            bodyData.idLock = idlock;
        }
        if (sts) {
            bodyData.status = sts;
        }
        return bodyData;
    };

    useEffect(() => {
        searchEmployee({ limit: 10, page: 1 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSearchData = (page, total, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }
        searchEmployee({
            page,
            limit: total,
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            ...getFilterData(
                empId,
                empCode,
                empName,
                empUsername,
                empLevel,
                supervisor,
                idLock,
                status
            ),
        });
    };
    const dispatch = useDispatch();
    const { reFetchFilter } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetTeamManagementFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Add Employee', user: userInfo.name });
    }, [userInfo.name]);

    // unlock user api hook
    const [unLockUser, { isLoading: unlockLoading }] = useUnLockUserMutation();

    // unlock user
    const unlockUser = async (id) => {
        try {
            const res = await unLockUser({
                id,
            }).unwrap();
            message.success('Task Complete');
            fetchSearchData(currentPage, totalShowPage);
        } catch (error) {
            message.error(error.message);
        }
    };

    // suspend user api hook
    const [suspendUserApi, { isLoading: suspendLoading }] = useSuspendUserApiMutation();

    // suspend user
    const suspendUser = async (id) => {
        try {
            const res = await suspendUserApi({
                userId: id,
            }).unwrap();
            message.success('Task Complete');
            fetchSearchData(currentPage, totalShowPage);
        } catch (error) {
            message.error(error.message);
        }
    };

    // forceLogout user api hook
    const [unregisterUserDevice, { isLoading: unregisterLoading }] =
        useUnregisterUserDeviceMutation();

    // logout user
    const unregisterUserDeviceFunc = async (id) => {
        try {
            const res = await unregisterUserDevice({
                id,
            }).unwrap();
            message.success('Task Complete');
            fetchSearchData(currentPage, totalShowPage);
        } catch (error) {
            message.error(error.message);
        }
    };

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchSearchData(pageNumber, totalPageChange);
    };
    const isEditableUser = !!userInfo?.permission?.find((x) => x.label === 'User Edit')?.label;

    // edit employee modal state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editUserData, setEditUserData] = useState({});

    // update password modal
    const [passModalOpen, setPassModalOpen] = useState(false);
    const [passCngId, setPassCngId] = useState('');

    // download report function start
    // check json
    function checkJSON(doc) {
        return doc?.headers.get('Content-Type').includes('json');
    }

    async function downLoadingFile(doc) {
        const excName = doc?.headers.get('Content-Disposition').split('"')[1] || 'report.xlsx';
        const fResult = await doc.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    // download link function
    const download = async ({ url, fileName }) => {
        try {
            dispatch(setGlobalLoading(true));
            for (let index = -1; ; ) {
                // eslint-disable-next-line no-await-in-loop
                const [result1, result2, result3] = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: ` Bearer ${accessToken}`,
                        },
                        // eslint-disable-next-line no-plusplus
                        body: JSON.stringify({
                            ...getFilterData(
                                empId,
                                empCode,
                                empName,
                                empUsername,
                                empLevel,
                                supervisor,
                                idLock,
                                status
                            ),
                            index: ++index,
                        }),
                        mode: 'cors',
                    }),
                    fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: ` Bearer ${accessToken}`,
                        },
                        // eslint-disable-next-line no-plusplus
                        body: JSON.stringify({
                            ...getFilterData(
                                empId,
                                empCode,
                                empName,
                                empUsername,
                                empLevel,
                                supervisor,
                                idLock,
                                status
                            ),
                            index: ++index,
                        }),
                        mode: 'cors',
                    }),
                    fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: ` Bearer ${accessToken}`,
                        },
                        // eslint-disable-next-line no-plusplus
                        body: JSON.stringify({
                            ...getFilterData(
                                empId,
                                empCode,
                                empName,
                                empUsername,
                                empLevel,
                                supervisor,
                                idLock,
                                status
                            ),
                            index: ++index,
                        }),
                        mode: 'cors',
                    }),
                ]);
                const typeJSON1 = checkJSON(result1);
                const typeJSON2 = checkJSON(result2);
                const typeJSON3 = checkJSON(result3);

                const arr = [];

                if (!typeJSON1) arr.push(downLoadingFile(result1));
                if (!typeJSON2) arr.push(downLoadingFile(result2));
                if (!typeJSON3) arr.push(downLoadingFile(result3));

                // eslint-disable-next-line no-await-in-loop
                await Promise.all(arr);

                if (typeJSON1 || typeJSON2 || typeJSON3) break;
            }
        } catch (error) {
            message.error('Something went wrong');
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
    // download report function end

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="View Employee" />

            {/* edit modal */}
            {isModalOpen ? (
                <EditModal
                    fetchSearchData={fetchSearchData}
                    page={currentPage}
                    limit={totalShowPage}
                    userData={editUserData}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            ) : null}
            {passModalOpen ? (
                <PasswordChangeModal
                    passCngModal={passModalOpen}
                    setPassCngModal={setPassModalOpen}
                    id={passCngId}
                />
            ) : null}
            <div style={{ margin: '16px 0' }}>
                <Filter
                    downloadButton={download}
                    loading={isLoading}
                    queryFunc={fetchSearchData}
                    pathname="/teamManagement"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Employee Details</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    <Table
                        pagination={false}
                        rowKey="username"
                        size="small"
                        scroll={{
                            x: 800,
                        }}
                        loading={isLoading}
                        dataSource={data?.data?.map(
                            ({
                                name,
                                usercode,
                                kind,
                                phone,
                                towns,
                                username,
                                serialNo,
                                supervisor: { name: supervisorName } = {},
                                _id,
                                locked,
                                isSuspended,
                                device,
                                status: userStatus,
                                image,
                            }) => ({
                                name,
                                usercode,
                                kind: labelChange(kind),
                                phone,
                                serialNo,
                                supervisorName,
                                region: [...new Set(towns.map(({ region: rName }) => rName))].join(
                                    ', '
                                ),
                                area: [...new Set(towns.map(({ area: aName }) => aName))].join(
                                    ', '
                                ),
                                territory: [
                                    ...new Set(towns.map(({ territory: tName }) => tName)),
                                ].join(', '),
                                town: towns.map(({ name: townName }) => townName).join(', '),
                                townCode: towns.map(({ towncode }) => towncode).join(', '),
                                username,
                                id: _id,
                                locked,
                                isSuspended,
                                userStatus,
                                deviceName: device?.name || '',
                                uuid: device?.id || '',
                                image,
                            })
                        )}
                    >
                        <Column
                            title=""
                            dataIndex="image"
                            key="image"
                            render={(image) =>
                                image ? (
                                    <Image
                                        width={50}
                                        height={50}
                                        src={image?.thumb}
                                        preview={{
                                            src: image?.original,
                                        }}
                                    />
                                ) : (
                                    <Avatar shape="square" size={50} icon={<UserOutlined />} />
                                )
                            }
                        />
                        <Column
                            title="Name"
                            dataIndex="name"
                            key="name"
                            render={(v) => <span style={{ fontSize: '12px' }}>{v}</span>}
                        />
                        <Column
                            style={{ zIndex: -1 }}
                            title="Level"
                            dataIndex="kind"
                            key="kind"
                            render={(v) => (
                                <span style={{ fontSize: '12px' }}>{v === 'MS' ? 'MS' : v}</span>
                            )}
                        />
                        <Column
                            title="Code"
                            dataIndex="usercode"
                            key="usercode"
                            render={(v) => <span style={{ fontSize: '12px' }}>{v}</span>}
                        />
                        <Column
                            title="Phone"
                            dataIndex="phone"
                            key="phone"
                            render={(v) => <span style={{ fontSize: '12px' }}>{v}</span>}
                        />
                        <Column
                            title="Supervisor"
                            dataIndex="supervisorName"
                            key="supervisorName"
                            render={(v) => <span style={{ fontSize: '12px' }}>{v}</span>}
                        />
                        <Column
                            title="Cluster"
                            dataIndex="region"
                            key="region"
                            render={(v) => <span style={{ fontSize: '12px' }}>{v}</span>}
                        />
                        <Column
                            title="Area"
                            dataIndex="area"
                            key="area"
                            render={(v) => <span style={{ fontSize: '12px' }}>{v}</span>}
                        />
                        <Column
                            title="Territory"
                            dataIndex="territory"
                            key="territory"
                            render={(v) => <span style={{ fontSize: '12px' }}>{v}</span>}
                        />
                        <Column
                            title="Town"
                            dataIndex="town"
                            key="town"
                            render={(_, record) => (
                                <>
                                    <span
                                        style={{
                                            fontSize: '10px',
                                            display: 'block',
                                            marginBottom: '-8px',
                                        }}
                                    >
                                        {record.townCode}
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        {record.town}
                                    </span>
                                </>
                            )}
                        />
                        <Column
                            title="Status"
                            dataIndex="userStatus"
                            key="userStatus"
                            render={(v) => (
                                <span style={{ fontSize: '12px' }}>{capitalizeFirstLetter(v)}</span>
                            )}
                        />
                        <Column
                            title="Username"
                            dataIndex="username"
                            key="username"
                            render={(v) => <span style={{ fontSize: '12px' }}>{v}</span>}
                        />
                        {/* <Column
                            title="Password"
                            dataIndex="ffPassword"
                            key="ffPassword"
                            render={(v) => <span style={{ fontSize: '12px' }}>{v}</span>}
                        /> */}
                        <Column
                            title="Device"
                            dataIndex="device"
                            key="device"
                            render={(_, record) => (
                                <>
                                    <span
                                        style={{
                                            fontSize: '10px',
                                            display: 'block',
                                            marginBottom: '-8px',
                                        }}
                                    >
                                        {record.uuid}
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        {record.deviceName}
                                    </span>
                                </>
                            )}
                        />
                        <Column
                            title="Action"
                            key="action"
                            align="right"
                            render={(_, record) => (
                                <Space size={2}>
                                    {record.kind === 'MS' || record.kind === 'CM' ? (
                                        <Button
                                            title="Edit"
                                            size="small"
                                            icon={<EditOutlined />}
                                            type="primary"
                                            danger
                                            shape="circle"
                                            onClick={() => {
                                                setEditUserData(record);
                                                setIsModalOpen(true);
                                            }}
                                        />
                                    ) : null}

                                    <Popconfirm
                                        title="Device unregister Warning!"
                                        description="Are you sure to do it?"
                                        // eslint-disable-next-line no-underscore-dangle
                                        onConfirm={() => unregisterUserDeviceFunc(record.id)}
                                        okText="Yes"
                                        cancelText="No"
                                        okType="danger"
                                        okButtonProps={{
                                            loading: unregisterLoading,
                                        }}
                                    >
                                        <Button
                                            title="Device unregister"
                                            size="small"
                                            icon={<AndroidOutlined />}
                                            type="primary"
                                            danger
                                            shape="circle"
                                        />
                                    </Popconfirm>

                                    {record?.locked ? (
                                        <Popconfirm
                                            title="Unlock User"
                                            description="Are you sure to unlock this user?"
                                            // eslint-disable-next-line no-underscore-dangle
                                            onConfirm={() => unlockUser(record.id)}
                                            okText="Yes"
                                            cancelText="No"
                                            okButtonProps={{
                                                loading: unlockLoading,
                                            }}
                                        >
                                            <Button
                                                title="Unlock User"
                                                size="small"
                                                icon={<UnlockOutlined />}
                                                type="primary"
                                                shape="circle"
                                            />
                                        </Popconfirm>
                                    ) : null}

                                    <Popconfirm
                                        title="User Suspend Warning!"
                                        description="Are you sure to suspend this user?"
                                        // eslint-disable-next-line no-underscore-dangle
                                        onConfirm={() => suspendUser(record.id)}
                                        okText="Yes"
                                        cancelText="No"
                                        okType="danger"
                                        okButtonProps={{
                                            loading: suspendLoading,
                                        }}
                                    >
                                        <Button
                                            title="Suspend"
                                            size="small"
                                            icon={<StopOutlined />}
                                            type="primary"
                                            danger
                                            shape="circle"
                                        />
                                    </Popconfirm>
                                    <Button
                                        title="Password Change"
                                        size="small"
                                        icon={<KeyOutlined />}
                                        type="primary"
                                        danger
                                        shape="circle"
                                        onClick={() => {
                                            setPassCngId(record.id);
                                            setPassModalOpen(true);
                                        }}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: '10px',
                    }}
                >
                    <Pagination
                        size="large"
                        pageSize={totalShowPage}
                        showSizeChanger
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={data?.meta?.total}
                        onChange={onChange}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    />
                </div>
            </div>
        </>
    );
}

export default TeamManagement;
