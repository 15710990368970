import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select, Space } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectStatusActiveInactive } from '../../constents/commonSelect';
import {
    setFFCode,
    setFFLevel,
    setNidNo,
    setStatus,
} from '../../redux/features/hrManagement/employeeManagementFilterSlice';
import { useGetPosmListQuery } from '../../redux/features/inventory/inventoryApiSlice';
import labelChange from '../../util/labelChange';

function HrManagementFilter({ queryFunc, loading, downloadButton }) {
    const { dateRange, ffLevel, ffCode, nidNo, status } = useSelector(
        (state) => state.userSummaryFilter
    );

    // user information
    const { user } = useSelector((state) => state.auth);
    const projectAccessData = user?.projectAccess
        ?.map((x) => ({ label: labelChange(x), value: x }))
        ?.filter((x) => x.value !== 'MS' && x.value !== 'MTCM');

    const { data, isLoading } = useGetPosmListQuery();

    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    mode="multiple"
                    defaultValue={ffLevel}
                    loading={isLoading}
                    allowClear
                    placeholder="FF Level"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setFFLevel(e))}
                    options={[
                        {
                            label: 'Merchandiser',
                            value: 'CM',
                        },
                        {
                            label: 'MS',
                            value: 'MS',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    defaultValue={ffCode}
                    placeholder="FF Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setFFCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={nidNo}
                    placeholder="NID No."
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setNidNo(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    mode="single"
                    allowClear
                    placeholder="Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setStatus(e))}
                    options={selectStatusActiveInactive?.map((x) => ({
                        label: x.label,
                        value: x.value.toLocaleLowerCase(),
                    }))}
                    defaultValue={status}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Space size={2}>
                    <Button
                        loading={loading}
                        disabled={loading}
                        size="large"
                        className="filter-btn"
                        icon={<SearchOutlined />}
                        type="primary"
                        onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                    >
                        Search
                    </Button>
                    <Button
                        loading={loading}
                        disabled={loading}
                        onClick={() => {
                            downloadButton({
                                url: '/v1',
                                fileName: 'Town Visibility Material History.xlsx',
                            });
                        }}
                        size="large"
                        type="primary"
                        danger
                        icon={<DownloadOutlined />}
                    >
                        Download
                    </Button>
                </Space>
            </Col>
        </>
    );
}

export default HrManagementFilter;
