const challengeRemarks = [
    {
        label: 'Others',
        value: 'Others',
    },
    {
        label: 'Not Attended',
        value: 'Not Attended',
    },
    {
        label: 'Display/SKU was not properly visible.',
        value: 'Display/SKU was not properly visible.',
    },
    {
        label: 'Shelf Talker was missing or not clearly visible.',
        value: 'Shelf Talker was missing or not clearly visible.',
    },
    {
        label: 'SKU quantity was low.',
        value: 'SKU quantity was low.',
    },
    {
        label: 'AI error.',
        value: 'AI error.',
    },
    {
        label: 'Picture-taking guidelines were not followed.',
        value: 'Picture-taking guidelines were not followed.',
    },
];

export default challengeRemarks;
