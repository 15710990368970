import { Button, Image, Table, message } from 'antd';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import thumb from '../../assets/thumb.png';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { setGlobalLoading } from '../../redux/features/loaderSlice';
import { useGetMaterialQuery } from '../../redux/features/materialManagement/materialManagementApi';
import { resetPosmViewListFilter } from '../../redux/features/posmManagement/posmViewFilterSlice';
import firebaseLog from '../../util/firebaseLog';

function ViewPosmList() {
    const dispatch = useDispatch();
    const { posmName, posmType, posmBrand, posmChannel, posmCode, owner } = useSelector(
        (state) => state.posmViewFilter
    );

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [filteredData, setFilteredData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    // Get data from API
    const { data, isLoading, refetch } = useGetMaterialQuery({
        page: 1,
        limit: 10000,
    });

    // Set original data when API responds
    useEffect(() => {
        if (data?.data?.data) {
            setOriginalData(data.data.data);
            setFilteredData(data.data.data);
        }
    }, [data]);

    // search posm data
    const searchData = async () => {
        try {
            setIsSearching(true);
            await refetch();

            let result = [...originalData];

            // Filter data based on search criteria
            result = result.filter((item) => {
                // Owner search (exact match)
                if (owner && item.owner !== owner) {
                    return false;
                }

                // Name search (partial match, case insensitive)
                if (posmName && !item.name?.toLowerCase().includes(posmName.toLowerCase())) {
                    return false;
                }

                // Type/Category search (exact match)
                if (posmType && item.category !== posmType) {
                    return false;
                }

                // Brand/Company search (exact match)
                if (posmBrand && !item.name?.toLowerCase().includes(posmBrand.toLowerCase())) {
                    return false;
                }

                // Channel search (exact match)
                if (posmChannel && item.channel !== posmChannel) {
                    return false;
                }

                // Code search (partial match, case insensitive)
                if (
                    posmCode &&
                    !item.materialCode?.toLowerCase().includes(posmCode.toLowerCase())
                ) {
                    return false;
                }

                return true;
            });

            setFilteredData(result);
            setPage(1);
        } catch (error) {
            message.error('Error while filtering data');
        } finally {
            setIsSearching(false);
        }
    };

    const headers = [
        { label: 'Owner Name', key: 'company' },
        { label: 'Visibility Material', key: 'name' },
        { label: 'Visibility Material Category', key: 'category' },
        { label: 'AI Report Mode', key: 'isCount' },
        { label: 'SOV Mode', key: 'sovMode' },
    ];
    const doc = data?.data?.data?.map(({ company, name, category, isCount, sovMode }) => ({
        company,
        name,
        category,
        isCount: isCount ? 'Count' : 'Yes/No',
        sovMode: sovMode ? 'Yes' : 'No',
    }));

    // reset existing filter
    useEffect(() => {
        dispatch(resetPosmViewListFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'View Visibility Material List', user: user.name });
    }, [user.name]);

    // download button
    async function downLoadingFile(docs) {
        const excName = docs?.headers.get('Content-Disposition').split('"')[1] || 'report.xlsx';
        const fResult = await docs.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const { accessToken } = useSelector((state) => state.auth);
    // download link function
    const [loading, setLoading] = useState(false);

    // function getDownloadBodyData({ dArr, ffLev }) {
    //     const bodyData = {
    //         index: 0,
    //     };
    //     if (dArr) {
    //         bodyData.startDate = dayjs(dArr).startOf('day');
    //         bodyData.endDate = dayjs(dArr).endOf('day');
    //     }
    //     return bodyData;
    // }

    const download = async ({ url, fileName }) => {
        try {
            dispatch(setGlobalLoading(true));
            setLoading(true);
            const result = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: ` Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    index: 0,
                }),
                mode: 'cors',
            });

            await downLoadingFile(result);
        } catch (error) {
            message.error('Something went wrong');
        } finally {
            dispatch(setGlobalLoading(false));
            setLoading(false);
        }
    };

    return (
        <>
            <HelmetHeader title="View Visibility Material List" />
            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={isLoading || isSearching}
                    queryFunc={searchData}
                    pathname="/view-sovm-list"
                />
            </div>
            <div style={{ textAlign: 'right', padding: '5px' }}>
                <Button
                    onClick={() => {
                        download({
                            url: '/v1/report/posm-list',
                            fileName: 'Visibility Material List.xlsx',
                        });
                    }}
                    loading={loading}
                    danger
                    type="primary"
                >
                    Download
                </Button>
            </div>
            <Table
                size="small"
                rowKey="_id"
                loading={isLoading || isSearching}
                dataSource={filteredData}
                scroll={{ x: 800 }}
                pagination={{
                    current: page,
                    pageSize,
                    total: filteredData.length,
                    showTotal: (total) => `Total ${total} items`,
                    showSizeChanger: true,
                    onChange: (newPage, newPageSize) => {
                        if (pageSize !== newPageSize) {
                            setPage(1);
                        } else {
                            setPage(newPage);
                        }
                        setPageSize(newPageSize);
                    },
                }}
            >
                <Column title="Owner Name" dataIndex="owner" key="owner" />
                <Column title="Name" dataIndex="name" key="name" />
                <Column title="Type" dataIndex="category" key="category" />
                <Column title="Placement" dataIndex="placementType" key="placementType" />
                <Column title="Brand Name" dataIndex="company" key="company" />
                <Column title="Channel" dataIndex="channel" key="channel" />
                <Column title="Code" dataIndex="materialCode" key="materialCode" />
                <Column title="Size (In Sq. Inch)" dataIndex="size" key="size" />
                <Column
                    title="Start Date"
                    dataIndex="startDay"
                    key="startDay"
                    render={(date) => dayjs(date).format('YYYY-MM-DD')}
                />
                <Column
                    title="End Date"
                    dataIndex="endDay"
                    key="endDay"
                    render={(date) => dayjs(date).format('YYYY-MM-DD')}
                />
                <Column
                    align="center"
                    title="Sample Picture"
                    dataIndex="samplePicture"
                    key="samplePicture"
                    render={(_, record) => (
                        <Image
                            width={30}
                            src={thumb}
                            preview={{
                                src: record?.image?.original,
                            }}
                        />
                    )}
                />
            </Table>
        </>
    );
}

export default ViewPosmList;
