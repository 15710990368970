/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAdditionalWorkdays,
    setInitialBaseSalary,
    setInitialTravelAllowance,
    setPenaltyDays
} from '../../redux/features/SharedSalaryModule/evaluationDataSlice';
import calculateTravellAllowance from '../../util/calculateTravellAllowance';
import EmployeeHeader from './EmployeeHeader';
import NavigationButtons from './NavigationButtons';
import PayableBaseSalaryCalculation from './PayableBasicSalary';
import PayableTravelAllowanceCalculation from './PayableTravelAllowance';
import { styles } from './styles';

function BasicSalary({
    data,
    onNext,
    onClose,
    isFirstPage,
    basicPenaltyReasonList,
    travelPenaltyReasonList,
}) {
    const dispatch = useDispatch();
    const {
        payableBaseSalary,
        payableTravelAllowance,
        payrollStructure,
        totalAbsenceDays,
        totalLeaveDays,
    } = useSelector((state) => state?.currentEvaluation?.evaLuationData);
    const { monthYearSummary, penaltyReason } = useSelector((state) => state?.currentEvaluation);

    const [additionalDays, setAdditionalDays] = useState({
        awd: 0,
        pd: 0,
    });

    useEffect(() => {
        dispatch(setInitialBaseSalary(payableBaseSalary?.totalBaseSalary));
        dispatch(setInitialTravelAllowance(payableTravelAllowance?.totalTravelAllowance));
    }, []);


    useEffect(() => {

        const tta = payrollStructure?.travelAllowance;
        const twd = payableTravelAllowance?.totalWorkDays;
        const ld = totalLeaveDays;
        const ab = totalAbsenceDays;
        const { awd, pd } = additionalDays;
        const result = calculateTravellAllowance(tta, twd, ld, ab, awd, pd);
        dispatch(setPenaltyDays({ penaltyDays: pd, total: Math.round(result) }));
        dispatch(setAdditionalWorkdays({ additionalWorkdays: awd, total: Math.round(result) }));
    }, [
        additionalDays,

        // dispatch,
        // payrollStructure?.travelAllowance,
        // payableTravelAllowance?.totalWorkDays,
        // totalAbsenceDays,
        // totalLeaveDays,
    ]);


    return (
        <div style={styles.container}>
            <Card style={styles.card}>
                <EmployeeHeader data={data} />

                <div style={styles.section}>
                    <h3
                        style={{
                            textAlign: 'center',
                            fontSize: '25px',
                            fontWeight: 'bold',
                        }}
                    >
                        Basic & Travel Allowance Salary
                    </h3>

                    <PayableBaseSalaryCalculation />

                    <PayableTravelAllowanceCalculation additionalDays={additionalDays} setAdditionalDays={setAdditionalDays} />
                </div>

                <NavigationButtons onBack={onClose} onNext={onNext} isFirstPage={isFirstPage} />
            </Card>
        </div>
    );
}

export default BasicSalary;
