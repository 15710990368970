/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import { message, Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { useGetAllSalaryStatusMutation } from '../../redux/features/SharedSalaryModule/salaryEvaluationsApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import generateMonthColumns from '../../util/generateMonthColumns';

function SalaryStatusEvaluation() {
    const dispatch = useDispatch();
    // pagination
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    });

    const data = {
        data: [],
        meta: {
            total: 0,
        },
    };

    const [triggerSalaryStatus, salaryStatusOptions] = useGetAllSalaryStatusMutation();
    const { region, area, territory, town } = useSelector((state) => state?.dataManagement);
    const { ffLevel, status, month, year, received } = useSelector(
        (state) => state?.salaryEvaluationFilter
    );

    const getFilterData = (ffL, sta, mon, yer, rec) => {
        const bodyData = {};

        if (ffL) {
            bodyData.userType = ffL;
        }
        if (sta) {
            bodyData.eligibility = sta;
        } else if (sta === false) {
            bodyData.eligibility = sta;
        }
        if (mon) {
            bodyData.month = mon;
        }
        if (yer) {
            bodyData.year = yer;
        }
        if (rec) {
            bodyData.received = rec;
        } else if (rec === false) {
            bodyData.received = rec;
        }

        return bodyData;
    };

    const handleTriggerSalaryStatus = useCallback(
        async (payload) => {
            try {
                const res = await triggerSalaryStatus({
                    ...payload,
                });
            } catch (error) {
                message.error('Something went wrong');
            }
        },
        [triggerSalaryStatus, pagination]
    );

    useEffect(() => {
        handleTriggerSalaryStatus({
            ...pagination,
            ...getFilterData(ffLevel, status, month, year, received),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    const fetchSalaryStatus = (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setPagination({
                page: 1,
                limit: 10,
            });
        }

        handleTriggerSalaryStatus({
            page,
            limit: totalShow,
            ...getDataManagementFilterData({ region, area, territory, town }),
            ...getFilterData(ffLevel, status, month, year, received),
        });
    };

    const onChange = (pageNumber, totalPageChange) => {
        setPagination({
            page: pageNumber,
            limit: totalPageChange,
        });
        fetchSalaryStatus(pageNumber, totalPageChange);
    };

    const { travelPenaltyReason, basicPenaltyReason } = salaryStatusOptions?.data?.data || {};

    // Generate columns dynamically based on the first row
    const monthColumns = generateMonthColumns(salaryStatusOptions?.data?.data);

    const columns = [
        {
            title: 'FF Name',
            dataIndex: 'user',
            key: 'user',
            render: (user) => user?.name,
        },
        { title: 'FF Code', dataIndex: 'user', key: 'user', render: (user) => user?.usercode },
        {
            title: 'FF Level',
            dataIndex: 'user',
            key: 'user',
            render: (user) => (user?.userType === 'CM' ? 'Merchandiser' : user?.userType),
        },
        {
            title: 'Cluster',
            dataIndex: 'town',
            key: 'region',
            render: (t) => [...new Set(t?.map((r) => r?.region))].join(', '),
        },
        {
            title: 'Area',
            dataIndex: 'town',
            key: 'area',
            render: (t) => [...new Set(t?.map((r) => r?.area))].join(', '),
        },
        {
            title: 'Territory',
            dataIndex: 'town',
            key: 'territory',
            render: (t) => [...new Set(t?.map((r) => r?.territory))].join(', '),
        },
        {
            title: 'Town',
            dataIndex: 'town',
            key: 'name',
            render: (t) => [
                ...new Set(
                    t?.map((r, index, arr) => (
                        <span key={r?.towncode}>
                            {r?.name} <small>({r?.towncode})</small>
                            {index < arr.length - 1 ? ', ' : ''}
                        </span>
                    ))
                ),
            ],
        },
        ...monthColumns,
        { title: 'Total', dataIndex: 'totalPayableSalary', key: 'totalPayableSalary' },
        {
            title: 'Basic',
            dataIndex: ['payableBaseSalary', 'totalBaseSalary'],
            key: 'payableBaseSalary',
            align: 'center',
        },
        {
            title: 'Travel Allowance',
            dataIndex: ['payableTravelAllowance', 'totalTravelAllowance'],
            key: 'payableTravelAllowance',
            align: 'center',
        },
        {
            title: 'Variable Pay',
            dataIndex: 'totalPayableIncentive',
            key: 'totalPayableIncentive',
            align: 'center',
        },
        { title: 'Status', dataIndex: 'user', key: 'status', render: (user) => user?.status },
        {
            title: 'Date',
            dataIndex: 'actionDate',
            key: 'actionDate',
            render: (actionDate) => (
                <div style={{ minWidth: '90px' }}>
                    {dayjs(actionDate).format('DD-MM-YYYY, hh:mm A')}
                </div>
            ),
        },
        { title: 'Received', dataIndex: 'received', key: 'received' },
        {
            title: 'Time Stamp',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => (
                <div style={{ minWidth: '90px' }}>
                    {dayjs(createdAt).format('DD-MM-YYYY, hh:mm A')}
                </div>
            ),
        },
    ];

    return (
        <>
            <HelmetHeader title="Salary Status" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    // downloadButton={download}
                    loading={salaryStatusOptions?.isLoading}
                    queryFunc={fetchSalaryStatus}
                    pathname="/salary-status"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Salary Status</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {' '}
                    <Table
                        loading={salaryStatusOptions?.isLoading}
                        scroll={{
                            x: 'auto',
                        }}
                        columns={columns}
                        dataSource={salaryStatusOptions?.data?.data || []}
                        size="small"
                        rowKey={(record) => record?._id}
                        pagination={false}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Pagination
                    size="large"
                    pageSize={pagination?.limit}
                    showSizeChanger
                    showQuickJumper
                    current={pagination?.page}
                    defaultCurrent={1}
                    total={salaryStatusOptions?.data?.meta?.total}
                    onChange={onChange}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div>
        </>
    );
}

export default SalaryStatusEvaluation;
