/* eslint-disable no-underscore-dangle */
import {
    DownloadOutlined,
    EditOutlined,
    EyeOutlined,
    TeamOutlined,
    UserDeleteOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { useViewEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

const columns = [
    { title: 'Unique ID', dataIndex: 'uniqueId', key: 'uniqueId' },
    { title: 'FF Name', dataIndex: 'ffName', key: 'ffName' },
    { title: 'Date of Birth', dataIndex: 'dateOfBirth', key: 'dateOfBirth' },
    { title: 'Email Address', dataIndex: 'email', key: 'email' },
    { title: 'NID No', dataIndex: 'nid', key: 'nid' },
    { title: 'FF Code', dataIndex: 'ffCode', key: 'ffCode' },
    {
        title: 'FF Level',
        dataIndex: 'ffLevel',
        key: 'ffLevel',
        render: (level) => (level === 'CM' ? 'Merchandiser' : level),
    },
    { title: 'Contact No.', dataIndex: 'contact', key: 'contact' },
    { title: 'Emergency Contact No.', dataIndex: 'emergencyContact', key: 'emergencyContact' },
    { title: 'Joining Date', dataIndex: 'joiningDate', key: 'joiningDate' },
    { title: 'Marchendising Exp', dataIndex: 'marchendisingExp', key: 'marchendisingExp' },
    { title: 'Sales Exp', dataIndex: 'salesExp', key: 'salesExp' },
    { title: 'Cluster', dataIndex: 'region', key: 'region' },
    { title: 'Area', dataIndex: 'area', key: 'area' },
    { title: 'Territory', dataIndex: 'territory', key: 'territory' },
    {
        title: 'Town',
        dataIndex: 'town',
        key: 'town',
        // render: (town) => (
        //     <div
        //         style={{
        //             width: '200px',
        //             wordWrap: 'break-word',
        //             maxWidth: '250px',
        //         }}
        //     >
        //         {town}
        //     </div>
        // ),
    },
    { title: 'Basic', dataIndex: 'basic', key: 'basic' },
    { title: 'Travel Allowance', dataIndex: 'travelAllowance', key: 'travelAllowance' },
    { title: 'Variable Pay', dataIndex: 'variablePay', key: 'variablePay' },
    { title: 'Acc. No', dataIndex: 'accNo', key: 'accNo' },
    { title: 'Routing No', dataIndex: 'routingNo', key: 'routingNo' },
    { title: 'Branch Name', dataIndex: 'branchName', key: 'branchName' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Action Date', dataIndex: 'actionDate', key: 'actionDate' },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: () => (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                    fontSize: '16px',
                }}
            >
                <EditOutlined style={{ color: 'green', cursor: 'pointer' }} />
                <UserDeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                <WarningOutlined style={{ color: 'orange', cursor: 'pointer' }} />
                <TeamOutlined style={{ color: 'blue', cursor: 'pointer' }} />
            </div>
        ),
    },
];

const expandColumns = [
    { title: 'Time Stamp', dataIndex: 'timeStamp', key: 'timeStamp' },
    { title: 'Action', dataIndex: 'action', key: 'action' },
    { title: 'Details', dataIndex: 'details', key: 'details' },
    { title: 'Amount', dataIndex: 'amount', key: 'amount' },
    {
        title: '',
        dataIndex: '',
        key: '',
        render: () => (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                    fontSize: '16px',
                }}
            >
                <EyeOutlined style={{ color: 'blue', cursor: 'pointer' }} />
                <DownloadOutlined style={{ color: 'green', cursor: 'pointer' }} />
            </div>
        ),
    },
];

const expandedRowRender = () => (
    <Table columns={expandColumns} dataSource={[]} pagination={false} />
);

function EmployeeDatabase() {
    const [getEmployeeDB, { data: fdata, isLoading }] = useViewEmployeeMutation();

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);
    const { status, nidNo, ffCode, ffLevel } = useSelector((state) => state?.hrEmployeeDatabase);

    // Pagination state
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // Fetch employee data
    useEffect(() => {
        getEmployeeDB({
            page: currentPage,
            limit: totalShowPage,
            type: ffLevel,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getEmployeeDB, currentPage, totalShowPage]);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(totalPageChange);
        setCurrentPageShow(pageNumber);
    };

    function getUniqueKeyValues(key, dataArray) {
        if (!Array.isArray(dataArray)) {
            throw new Error('The second argument must be an array');
        }

        const uniqueValues = [...new Set(dataArray.map((item) => item[key]))];
        return uniqueValues?.toString() ?? '';
    }

    // Ensure `fdata` is properly formatted for the table
    const dataSource = fdata?.data?.map((item, index) => ({
        key: index + 1,
        _id: item?._id,
        uniqueId: index + 1,
        ffName: item?.name ?? '',
        dateOfBirth: dayjs(item?.dateOfBirth).format('YYYY-MM-DD') ?? '',
        email: item?.email ?? '',
        nid: item?.nidNumber ?? '',
        ffCode: item?.usercode ?? '',
        ffLevel: item?.kind ?? '',
        contact: item?.phone ?? '',
        emergencyContact: item?.emergencyPhone ?? '',
        joiningDate: dayjs(item?.joiningDate).format('YYYY-MM-DD') ?? '',
        marchendisingExp: item?.merchandisingExperience
            ? `${item?.merchandisingExperience?.years} ${item?.merchandisingExperience?.years > 1 ? 'Years' : 'Year'}
    ${item?.merchandisingExperience?.months} ${item?.merchandisingExperience?.months > 1 ? 'Months' : 'Month'}`
            : '',
        salesExp: item?.salesExperience
            ? `${item?.salesExperience?.years} ${item?.salesExperience?.years > 1 ? 'Years' : 'Year'}
    ${item?.salesExperience?.months} ${item?.salesExperience?.months > 1 ? 'Months' : 'Month'}`
            : '',
        region: getUniqueKeyValues('region', item?.towns) ?? '',
        area: getUniqueKeyValues('area', item?.towns) ?? '',
        territory: getUniqueKeyValues('territory', item?.towns) ?? '',
        town: getUniqueKeyValues('name', item?.towns) ?? '',
        basic: item?.basicSalary ?? '',
        travelAllowance: item?.travelAllowance ?? '',
        variablePay: item?.variablePay ?? '',
        accNo: item?.accountNo ?? '',
        routingNo: item?.routingNo ?? '',
        branchName: item?.branchName ?? '',
        status: item?.status?.toUpperCase() ?? '',
        actionDate: dayjs(item?.createdAt),
    }));

    // generated request body data
    // eslint-disable-next-line no-shadow
    const getEmpFilterData = ({ status, nidNo, ffCode, ffLevel }) => {
        const bodyData = {};
        if (status) {
            bodyData.status = status;
        }
        if (nidNo) {
            bodyData.nidNo = nidNo;
        }
        if (ffCode) {
            bodyData.employeeCode = ffCode;
        }
        if (ffLevel) {
            bodyData.type = ffLevel;
        }

        return bodyData;
    };

    const onSearch = async (e) => {
        // if (clean === 'cleanShowResultOnPage') {
        //     setCurrentPageShow(1);
        //     setTotalPageShow(10);
        // }

        const res = await getEmployeeDB({
            page: currentPage,
            limit: totalShowPage,
            ...getEmpFilterData({ status, nidNo, ffCode, ffLevel }),
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
        });
    };

    return (
        <>
            <HelmetHeader title="Employee Database" />

            <div style={{ margin: '16px 0' }}>
                <Filter queryFunc={onSearch} pathname="/employee-database" />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Employee Database</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <Table
                            scroll={{ x: 'auto' }}
                            columns={columns}
                            expandable={{
                                expandedRowRender,
                                defaultExpandedRowKeys: ['0'],
                            }}
                            dataSource={dataSource}
                            size="small"
                            rowKey={(record) => record?._id}
                            pagination={false}
                        />
                    )}
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Pagination
                    size="large"
                    pageSize={totalShowPage}
                    current={currentPage}
                    total={fdata?.meta?.total || 0}
                    onChange={onChange}
                />
            </div>
        </>
    );
}

export default EmployeeDatabase;
