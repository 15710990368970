const selectPassedFailed = [
    {
        value: 'Passed',
        label: 'Passed',
    },
    {
        value: 'Failed',
        label: 'Failed',
    },
];

const selectYesNo = [
    {
        value: 'yes',
        label: 'Yes',
    },
    {
        value: 'no',
        label: 'No',
    },
];

const selectTrueFalse = [
    {
        label: 'True',
        value: true,
    },
    {
        label: 'False',
        value: false,
    },
];

const selectStatusActiveInactive = [
    {
        label: 'Active',
        value: 'Active',
    },
    {
        label: 'Inactive',
        value: 'Inactive',
    },
];

export { selectPassedFailed, selectStatusActiveInactive, selectTrueFalse, selectYesNo };
