import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setDateRange,
    setEmpLevel,
    setFFid,
} from '../../redux/features/leaveManagement/leaveManagementFilterSlice';
import { useSearchEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

function LeaveManagementFilter({ queryFunc, loading }) {
    const dispatch = useDispatch();

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    const { dateRange, empLevel } = useSelector((state) => state.leaveManagementFilter);

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading }] = useSearchEmployeeMutation();

    const getFilterData = (lev) => {
        const bodyData = {};
        if (lev) {
            bodyData.type = lev;
        }
        return bodyData;
    };

    useEffect(() => {
        searchEmployee({
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            ...getFilterData(empLevel),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchEmployee, circle, region, area, territory, town, empLevel]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="FF Level"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setEmpLevel(e))}
                    options={[
                        {
                            value: 'CM',
                            label: 'Merchandiser',
                        },
                        {
                            value: 'MS',
                            label: 'MS',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    loading={isLoading}
                    showSearch
                    allowClear
                    placeholder="FF Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setFFid(e))}
                    options={employeeData?.data?.map((emp) => ({
                        label: emp.name,
                        // eslint-disable-next-line no-underscore-dangle
                        value: emp._id,
                    }))}
                    filterOption={(input, option) =>
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    searchValue={search}
                    onSearch={onSearch}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={() => queryFunc()}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default LeaveManagementFilter;
