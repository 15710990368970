/* eslint-disable no-template-curly-in-string */
import { UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, InputNumber, message, Select, Upload } from 'antd';
import React from 'react';

const { Option } = Select;

function AddEmployeeByHr() {
    const [form] = Form.useForm();

    const handleFinish = (values) => {
        console.log('Submitted Values:', values);
        message.success('Form submitted successfully!');
    };

    const handleFinishFailed = (errorInfo) => {
        console.error('Form Submission Failed:', errorInfo);
        message.error('Please check the form and try again.');
    };

    const customStyles = {
        formItem: { marginBottom: '16px' },
        label: { fontWeight: 500, marginBottom: 8 },
        input: { width: '100%' },
        select: { width: '100%' },
        button: { marginTop: 16, width: '100%' },
        inlineFlex: { display: 'flex', gap: '16px' },
        halfWidth: { flex: 1 },
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: 'Please enter a valid email!',
            number: 'Please enter a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    return (
        <div
            className="component-box-container"
            style={{ position: 'sticky', top: 0, marginBottom: 10 }}
        >
            <div className="box-heading">Add Employee</div>

            <div style={{ padding: '10px', width: '100%' }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFinish}
                    onFinishFailed={handleFinishFailed}
                    validateMessages={validateMessages}
                    style={{ maxWidth: 900, margin: 'auto' }}
                >
                    {/* Row 1 */}
                    <div style={customStyles.inlineFlex}>
                        <Form.Item
                            label="FF Name"
                            name="ffName"
                            rules={[{ required: true }]}
                            style={customStyles.halfWidth}
                        >
                            <Input placeholder="Employee Full Name" style={customStyles.input} />
                        </Form.Item>
                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[{ required: true }]}
                            style={customStyles.halfWidth}
                        >
                            <Select placeholder="Select Gender" style={customStyles.select}>
                                <Option value="male">Male</Option>
                                <Option value="female">Female</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    {/* Row 2 */}
                    <div style={customStyles.inlineFlex}>
                        <Form.Item
                            label="Date of Birth"
                            name="dob"
                            rules={[{ required: true }]}
                            style={customStyles.halfWidth}
                        >
                            <DatePicker style={customStyles.input} />
                        </Form.Item>
                        <Form.Item
                            label="NID Number"
                            name="nidNumber"
                            rules={[{ required: true }]}
                            style={customStyles.halfWidth}
                        >
                            <Input addonAfter={<Button>Check</Button>} placeholder="NID Number" />
                        </Form.Item>
                    </div>

                    {/* Row 3 */}
                    <div style={customStyles.inlineFlex}>
                        <Form.Item
                            label="NID Picture"
                            name="nidPicture"
                            rules={[{ required: true }]}
                            style={customStyles.halfWidth}
                        >
                            <Upload
                                accept=".png,.jpg,.jpeg"
                                beforeUpload={() => false}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>
                                    Upload NID Front and Back Picture
                                </Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            label="FF Selfie"
                            name="ffSelfie"
                            rules={[{ required: true }]}
                            style={customStyles.halfWidth}
                        >
                            <Upload
                                accept=".png,.jpg,.jpeg"
                                beforeUpload={() => false}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Upload FF Selfie</Button>
                            </Upload>
                        </Form.Item>
                    </div>

                    {/* Row 4 */}
                    <div style={customStyles.inlineFlex}>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[{ required: true }, { type: 'email' }]}
                            style={customStyles.halfWidth}
                        >
                            <Input
                                placeholder="Employee Email Address"
                                style={customStyles.input}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Contact Number"
                            name="contactNumber"
                            rules={[
                                { required: true },
                                { pattern: /^\d+$/, message: 'Enter a valid number!' },
                            ]}
                            style={customStyles.halfWidth}
                        >
                            <Input
                                placeholder="Employee Contact Number"
                                style={customStyles.input}
                            />
                        </Form.Item>
                    </div>

                    {/* Row 5 */}
                    <div style={customStyles.inlineFlex}>
                        <Form.Item
                            label="Emergency Contact Number"
                            name="emergencyContact"
                            rules={[
                                { required: true },
                                { pattern: /^\d+$/, message: 'Enter a valid number!' },
                            ]}
                            style={customStyles.halfWidth}
                        >
                            <Input
                                placeholder="Emergency Contact Number"
                                style={customStyles.input}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: true }]}
                            style={customStyles.halfWidth}
                        >
                            <Input placeholder="Employee Address" style={customStyles.input} />
                        </Form.Item>
                    </div>

                    {/* Row 6 */}
                    <div style={customStyles.inlineFlex}>
                        <Form.Item
                            label="FF Level"
                            name="ffLevel"
                            rules={[{ required: true }]}
                            style={customStyles.halfWidth}
                        >
                            <Select placeholder="Select FF Level" style={customStyles.select}>
                                <Option value="level1">Level 1</Option>
                                <Option value="level2">Level 2</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Town"
                            name="town"
                            rules={[{ required: true }]}
                            style={customStyles.halfWidth}
                        >
                            <Select placeholder="Select Town" style={customStyles.select}>
                                <Option value="town1">Town 1</Option>
                                <Option value="town2">Town 2</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    {/* Row 7 */}
                    <div style={customStyles.inlineFlex}>
                        <Form.Item
                            label="Basic Salary"
                            name="basicSalary"
                            rules={[{ required: true }, { type: 'number', min: 0 }]}
                            style={customStyles.halfWidth}
                        >
                            <InputNumber
                                placeholder="Employee Basic Salary"
                                style={customStyles.input}
                                min={0}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Variable Pay"
                            name="variablePay"
                            rules={[{ type: 'number', min: 0 }]}
                            style={customStyles.halfWidth}
                        >
                            <InputNumber
                                placeholder="Employee Variable Pay"
                                style={customStyles.input}
                                min={0}
                            />
                        </Form.Item>
                    </div>

                    {/* Submit */}
                    <Form.Item style={customStyles.button}>
                        <Button type="primary" htmlType="submit" style={customStyles.input}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default AddEmployeeByHr;
