import { Button, Col, Form, Input, Radio, Row, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HelmetHeader from '../../components/HelmetHeader';
import {
    useGetAreaListQuery,
    useGetCircleListQuery,
    useGetDhListQuery,
    useGetRegionListQuery,
    useGetTerritoryListQuery,
} from '../../redux/features/dataManagement/dataManagementApi';
import {
    useCreateAdminUserMutation,
    useLandingPageListQuery,
    useViewGroupIdsQuery,
} from '../../redux/features/superuser/superUserApi';
import { useSearchEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import firebaseLog from '../../util/firebaseLog';

function AddUser() {
    const { Option } = Select;
    const [form] = Form.useForm();

    const { data: groupData, isLoading: groupLoading } = useViewGroupIdsQuery();
    const { data: landingPageData, isLoading: landingPageLoading } = useLandingPageListQuery();

    // get dropdown data
    const { data: circleList, isLoading } = useGetCircleListQuery();
    const { data: regionList } = useGetRegionListQuery();
    const { data: areaList } = useGetAreaListQuery();
    const { data: territoryList } = useGetTerritoryListQuery();
    const { data: dhList } = useGetDhListQuery();

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading: searchLoading }] =
        useSearchEmployeeMutation();

    const [accessLevel, setAccessLevel] = useState('AdminLevel');
    const [level, setLevel] = useState('');

    const [createAdminUser] = useCreateAdminUserMutation();

    const onFinish = async ({
        name,
        username,
        email,
        password,
        rolesId,
        landingPage,
        accessDetails,
        ids,
    }) => {
        try {
            const bodyData = {
                name,
                username,
                password,
                rolesId,
                landingPage,
            };
            if (email) {
                bodyData.email = email;
            }
            if (accessDetails) {
                if (accessDetails === 'CircleLevel') {
                    bodyData.circleId = ids;
                }
                if (accessDetails === 'RegionLevel') {
                    bodyData.regionId = ids;
                }
                if (accessDetails === 'AreaLevel') {
                    bodyData.areaId = ids;
                }
                if (accessDetails === 'TerritoryLevel') {
                    bodyData.territoryId = ids;
                }
                if (accessDetails === 'DistributionHouse') {
                    bodyData.dhId = ids;
                }
                if (accessDetails === 'TMSLevel') {
                    bodyData.tmsId = ids;
                }
            }
            const result = await createAdminUser({
                ...bodyData,
            });
            if (result.error?.data?.message) {
                message.error(result.error?.data?.message);
            }
            if (result?.data?.message) {
                message.success(result?.data?.message);
            }
            form.resetFields();
        } catch (error) {
            message.error('Something went wrong');
        }
    };

    const onChange = ({ accessDetails }) => {
        if (accessDetails) {
            setAccessLevel(accessDetails);
            setLevel(accessDetails.split(/(?=[A-Z])/)[0]);
        }
    };

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    function renderAccessData(lev) {
        if (lev === 'CircleLevel') {
            return circleList?.data;
        }
        if (lev === 'RegionLevel') {
            return regionList?.data;
        }
        if (lev === 'AreaLevel') {
            return areaList?.data;
        }
        if (lev === 'TerritoryLevel') {
            return territoryList?.data;
        }
        if (lev === 'DistributionHouse') {
            return dhList?.data;
        }
        if (lev === 'TMSLevel') {
            return (
                employeeData?.data?.map((user) => ({
                    label: user.name,
                    // eslint-disable-next-line no-underscore-dangle
                    value: user._id,
                })) || []
            );
        }
        return [];
    }

    useEffect(() => {
        if (accessLevel === 'TMSLevel') {
            searchEmployee({
                type: 'tms',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessLevel]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'Add User', user: user.name });
    }, [user.name]);

    const [search2, setSearch2] = useState('');
    const onSearch2 = (e) => {
        setSearch2(e);
    };

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="Add User" />

            <div
                style={{
                    marginTop: '10px',
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                    padding: '10px',
                    borderRadius: '10px',
                }}
            >
                <Form form={form} onFinish={onFinish} layout="vertical" onValuesChange={onChange}>
                    <h2>User Registration Form</h2>
                    <Row gutter={[10, 10]}>
                        <Col lg={8} md={12} sm={24}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please enter your name' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={12} sm={24}>
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[{ required: true, message: 'Please enter your username' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    { required: true, message: 'Please enter your password' },
                                    {
                                        min: 6,
                                        message: 'Password must be at least 6 characters long',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={12} sm={24}>
                            <Form.Item
                                label="Group Name"
                                name="rolesId"
                                rules={[{ required: true, message: 'Please select a group name' }]}
                            >
                                <Select
                                    loading={groupLoading}
                                    options={groupData?.data?.filter(
                                        (x) => x.label !== 'Superadmin'
                                    )}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Landing Page"
                                name="landingPage"
                                rules={[
                                    { required: true, message: 'Please select a landing page' },
                                ]}
                            >
                                <Select
                                    showSearch
                                    options={landingPageData?.data}
                                    loading={landingPageLoading}
                                    searchValue={search2}
                                    onSearch={onSearch2}
                                    filterOption={(input, option) =>
                                        option.props.label
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} md={12} sm={24}>
                            <Form.Item label="Access Details" name="accessDetails">
                                <Radio.Group defaultValue="AdminLevel">
                                    <Radio value="AdminLevel" defaultChecked>
                                        Admin Level
                                    </Radio>
                                    <Radio value="CircleLevel">Circle Level</Radio>
                                    <Radio value="RegionLevel">Cluster Level</Radio>
                                    <Radio value="AreaLevel">Area Level</Radio>
                                    <Radio value="TerritoryLevel">Territory Level</Radio>
                                    <Radio value="DistributionHouse">DH Level</Radio>
                                    <Radio value="TMSLevel">TMS Level</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {accessLevel !== 'AdminLevel' ? (
                            <Col lg={8} md={12} sm={24}>
                                <Form.Item
                                    label={level === 'T' ? 'TMS' : level}
                                    name="ids"
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        options={renderAccessData(accessLevel)}
                                        searchValue={search}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.props.label
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        ) : null}
                    </Row>

                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button style={{ width: '120px' }} type="primary" htmlType="submit">
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
}

export default AddUser;
