const channelListSelect = [
    {
        value: 'CDOWS',
        label: 'CDOWS',
    },
    {
        value: 'CG',
        label: 'CG',
    },
    {
        value: 'CSG',
        label: 'CSG',
    },
    {
        value: 'DRUG',
        label: 'DRUG',
    },
    {
        value: 'DS',
        label: 'DS',
    },
    {
        value: 'ECOMM',
        label: 'ECOMM',
    },
    {
        value: 'FOOD',
        label: 'FOOD',
    },
    {
        value: 'FS',
        label: 'FS',
    },
    {
        value: 'GBP',
        label: 'GBP',
    },
    {
        value: 'GBS',
        label: 'GBS',
    },
    {
        value: 'HLS',
        label: 'HLS',
    },
    {
        value: 'HOTEL',
        label: 'HOTEL',
    },
    {
        value: 'HS',
        label: 'HS',
    },
    {
        value: 'INS',
        label: 'INS',
    },
    {
        value: 'INSTN',
        label: 'INSTN',
    },
    {
        value: 'MT',
        label: 'MT',
    },
    {
        value: 'NBG',
        label: 'NBG',
    },
    {
        value: 'NG',
        label: 'NG',
    },
    {
        value: 'PBS',
        label: 'PBS',
    },
    {
        value: 'PD',
        label: 'PD',
    },
    {
        value: 'PGS',
        label: 'PGS',
    },
    {
        value: 'RCS',
        label: 'RCS',
    },
    {
        value: 'RMG',
        label: 'RMG',
    },
    {
        value: 'RMG STORES',
        label: 'RMG STORES',
    },
    {
        value: 'RMGGROCER',
        label: 'RMGGROCER',
    },
    {
        value: 'RNG',
        label: 'RNG',
    },
    {
        value: 'RWMG',
        label: 'RWMG',
    },
    {
        value: 'SSG',
        label: 'SSG',
    },
    {
        value: 'TNP',
        label: 'TNP',
    },
    {
        value: 'TONG',
        label: 'TONG',
    },
    {
        value: 'TP',
        label: 'TP',
    },
    {
        value: 'UCS',
        label: 'UCS',
    },
    {
        value: 'UCS+',
        label: 'UCS+',
    },
    {
        value: 'UGS',
        label: 'UGS',
    },
    {
        value: 'UHPCT',
        label: 'UHPCT',
    },
    {
        value: 'UNG',
        label: 'UNG',
    },
    {
        value: 'UWMG',
        label: 'UWMG',
    },
    {
        value: 'WS',
        label: 'WS',
    },
];

export default channelListSelect;
