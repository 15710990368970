import { Button, Col, Input, Select } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import channelListSelect from '../../constents/channelList';
import sovmMaterialTypes from '../../constents/sovmMaterialTypes';
import {
    setOwner,
    setPosmBrand,
    setPosmChannel,
    setPosmCode,
    setPosmName,
    setPosmType,
} from '../../redux/features/posmManagement/posmViewFilterSlice';

function ViewPosmListFilter({ queryFunc, loading }) {
    const dispatch = useDispatch();

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Owner Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOwner(e))}
                    options={[
                        {
                            value: 'Unilever Bangladesh Limited',
                            label: 'Unilever Bangladesh Limited',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    allowClear
                    placeholder="Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmName(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmType(e))}
                    options={sovmMaterialTypes}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    allowClear
                    placeholder="Brand"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmBrand(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Channel"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmChannel(e))}
                    options={channelListSelect}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    allowClear
                    placeholder="Code"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    disabled={loading}
                    loading={loading}
                    size="large"
                    className="filter-btn"
                    type="primary"
                    onClick={() => queryFunc()}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default ViewPosmListFilter;
