/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isModalOpen: false,
    data: [],
    responses: {},
};

const complianceSlice = createSlice({
    name: 'compliance',
    initialState,
    reducers: {
        setAuditModalOpen: (state, action) => {
            state.isModalOpen = action.payload;
        },
        setComplianceData: (state, action) => {
            state.data = action.payload;
        },
        updateResponse: (state, action) => {
            const { category, item } = action.payload;
            if (!state.responses[category]) {
                state.responses[category] = {};
            }
            state.responses[category][item.name] = {
                status: item.status,
                remarks: item.remarks,
            };
        },
        resetResponses: (state) => {
            state.responses = {};
        },
    },
});

export const { setAuditModalOpen, setComplianceData, updateResponse, resetResponses } =
    complianceSlice.actions;

// Helper function to convert a string to camelCase
const toCamelCase = (str) =>
    str
        .toLowerCase() // Convert the entire string to lowercase
        .split(' ') // Split the string into an array of words based on spaces
        .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))) // Capitalize the first letter of each word except the first word
        .join(''); // Join the array back into a single string
// Main function
export const formatResponsesForSubmission = (responses) =>
    Object.entries(responses)
        .map(([category, items]) => ({
            [toCamelCase(category)]: Object.entries(items).map(([name, data]) => ({
                name,
                status: data.status,
                remarks: data.remarks,
            })),
        }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});

export default complianceSlice.reducer;
