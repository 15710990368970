/* eslint-disable react/no-array-index-key */
import { Col, Row, Table } from 'antd';
import formatAccuracy from '../util/formatAccuracy';

function CategoryShelfAIResult({ data }) {
    const columns = [
        {
            title: 'SKUs Name',
            dataIndex: 'name',
            key: 'name',
            render: (arr, val, idx) => ({
                props: {
                    style:
                        idx !== val.arrLen - 2
                            ? {
                                  border: 'none',
                                  padding: '2px 8px',
                                  fontSize: '12px',
                              }
                            : { padding: '2px 8px', fontSize: '12px' },
                },
                children: (
                    <span
                        style={
                            val.name === 'Total'
                                ? { fontWeight: 600, border: 'none', fontSize: '12px' }
                                : { fontSize: '12px' }
                        }
                    >
                        {val.name}
                    </span>
                ),
            }),
        },
        {
            title: 'Planned Qty',
            dataIndex: 'plannedQty',
            key: 'plannedQty',
            align: 'center',
            render: (_, val, idx) => ({
                props: {
                    style:
                        idx !== val.arrLen - 2
                            ? {
                                  border: 'none',
                                  padding: '2px 8px',
                                  fontSize: '12px',
                              }
                            : { padding: '2px 8px', fontSize: '12px' },
                },
                children: (
                    <span
                        style={
                            val.name === 'Total'
                                ? { fontWeight: 600, border: 'none', fontSize: '12px' }
                                : { fontSize: '12px' }
                        }
                    >
                        {val.plannedQty}
                    </span>
                ),
            }),
        },
        {
            title: 'Visible Qty.',
            dataIndex: 'detectedQty',
            key: 'detectedQty',
            align: 'center',
            render: (_, val, idx) => ({
                props: {
                    style:
                        idx !== val.arrLen - 2
                            ? {
                                  border: 'none',
                                  padding: '2px 8px',
                                  fontSize: '12px',
                              }
                            : { padding: '2px 8px', fontSize: '12px' },
                },
                children: (
                    <span
                        style={
                            val.name === 'Total'
                                ? { fontWeight: 600, border: 'none', fontSize: '12px' }
                                : { fontSize: '12px' }
                        }
                    >
                        {val.detectedQty}
                    </span>
                ),
            }),
        },
        {
            title: 'Accuracy',
            dataIndex: 'accuracy',
            key: 'accuracy',
            align: 'center',
            render: (_, val, idx) => ({
                props: {
                    style:
                        idx !== val.arrLen - 2
                            ? {
                                  border: 'none',
                                  padding: '2px 8px',
                                  fontSize: '12px',
                              }
                            : { padding: '2px 8px', fontSize: '12px' },
                },
                children: (
                    <span
                        style={
                            val.name === 'Total'
                                ? { fontWeight: 600, border: 'none', fontSize: '12px' }
                                : { fontSize: '12px' }
                        }
                    >
                        {formatAccuracy(val?.accuracy, null, 2)}
                    </span>
                ),
            }),
        },
    ];

    return (
        <div className="ai-table-containe">
            {data?.map(
                ({ planogram }, i) => (
                    <Row gutter={[5, 5]} key={Math.random() * 10 + 1 + i}>
                        {planogram?.map(
                            (
                                {
                                    slab,
                                    name,
                                    category,
                                    sku,
                                    totalPlannedQty,
                                    totalDetectedQty,
                                    exclusivity,
                                    planogramAdherence,
                                    hotspot,
                                    remarks,
                                    passed,
                                    variantWiseCompliance,
                                    compliance,
                                },
                                j
                            ) => {
                                const aiData = sku
                                    ?.map((skus) => ({
                                        ...skus,
                                        arrLen: Number(sku.length + 1 || 0),
                                    }))
                                    .concat({
                                        name: 'Total',
                                        plannedQty: totalPlannedQty,
                                        detectedQty: totalDetectedQty,
                                    });

                                return (
                                    <Col key={sku.name}>
                                        <div
                                            style={{
                                                background: '#F6F3F8',
                                                border: '1px solid grey',
                                                padding: '10px',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            <h3>Display Name: {name}</h3>
                                            <h3
                                                style={{
                                                    color: 'red',
                                                }}
                                            >
                                                {' '}
                                                Remarks: {remarks}
                                            </h3>
                                            <Row gutter={[5, 5]}>
                                                <Col lg={12} md={24} sm={24}>
                                                    <h4> Maintain HotSpot: {hotspot}</h4>
                                                    <h4> Exclusivity: {exclusivity}</h4>

                                                    <h4>
                                                        Overall Compliance:
                                                        {` ${compliance}`}%
                                                    </h4>
                                                </Col>
                                                <Col lg={12} md={24} sm={24}>
                                                    <h4>Status: {passed ? 'Passed' : 'Failed'} </h4>
                                                    <h4>Planogram Adhere: {planogramAdherence}</h4>
                                                    <h4>
                                                        Variant Wise Compliance:
                                                        {` ${variantWiseCompliance}`}%
                                                    </h4>
                                                </Col>
                                            </Row>
                                            <div>
                                                {/* <p
                                                        style={{
                                                            margin: '10px 0 0 0',
                                                            fontWeight: 500,
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {sku.name} Overall compliance:{' '}
                                                        {sku?.compliance?.toFixed(2) || 0}%
                                                    </p>
                                                    <p
                                                        style={{
                                                            margin: '10px 0 0 0',
                                                            fontWeight: 500,
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {sku.name} Variant Wise Compliance:{' '}
                                                        {sku?.variantWiseCompliance?.toFixed(2) ||
                                                            0}
                                                        %
                                                    </p> */}
                                                <Table
                                                    size="small"
                                                    columns={columns}
                                                    dataSource={aiData || []}
                                                    pagination={false}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                );
                            }
                        )}
                    </Row>
                )

                // return (
                //     <div style={{ marginTop: '10px' }} key={item.name}>
                //         <p style={{ margin: 0, fontWeight: 600, fontSize: '16px' }}>
                //             {item.name === 'DA' ? 'Display Audit' : item.name}
                //         </p>
                //         <div style={{ display: 'flex', gap: 15 }}>
                //             {/* <p style={{ margin: 0, fontWeight: 600, fontSize: '16px' }}>
                //                 Overall Compliance: {Math.round(item.overallCompliance || 0)}%
                //             </p>
                //             <p style={{ margin: 0, fontWeight: 600, fontSize: '16px' }}>
                //                 Variant Wise Compliance:{' '}
                //                 {Math.round(item.variantWiseCompliance || 0)}%
                //             </p> */}
                //         </div>
                //         <Row gutter={[10, 10]}>
                //             {item?.planogram?.map((sku) => {
                //                 console.log('sku', sku);

                //                 const aiData = sku?.sku
                //                     ?.map((skus) => ({
                //                         ...skus,
                //                         arrLen: Number(sku.sku.length + 1 || 0),
                //                     }))
                //                     .concat({
                //                         name: 'Total',
                //                         plannedQty: sku.totalPlannedQty,
                //                         detectedQty: sku.totalDetectedQty,
                //                     });
                //                 return (
                //                     <Col sm={24} md={12} lg={8} key={sku.name}>
                //                         <p
                //                             style={{
                //                                 margin: '10px 0 0 0',
                //                                 fontWeight: 500,
                //                                 fontSize: '14px',
                //                             }}
                //                         >
                //                             {sku.name} Overall compliance:{' '}
                //                             {sku?.compliance?.toFixed(2) || 0}%
                //                         </p>
                //                         <p
                //                             style={{
                //                                 margin: '10px 0 0 0',
                //                                 fontWeight: 500,
                //                                 fontSize: '14px',
                //                             }}
                //                         >
                //                             {sku.name} Variant Wise Compliance:{' '}
                //                             {sku?.variantWiseCompliance?.toFixed(2) || 0}%
                //                         </p>
                //                         <Table
                //                             bordered
                //                             size="small"
                //                             columns={columns}
                //                             dataSource={aiData || []}
                //                             pagination={false}
                //                         />
                //                     </Col>
                //                 );
                //             })}
                //         </Row>
                //     </div>
                // );
            )}
        </div>
    );
}

export default CategoryShelfAIResult;
