import AiPosmList from '../features/AiPosmList/AiPosmList';
import Attendance from '../features/Attendance/Attendance';
import BacklitAudit from '../features/BacklitAudit/BacklitAudit';
import DailyActivityReport from '../features/DailyActivityReport/DailyActivityReport';
import Dashboard from '../features/Dashboard/Dashboard';
import AddArea from '../features/DataManagement/AddArea';
import AddRegion from '../features/DataManagement/AddRegion';
import AddTerritory from '../features/DataManagement/AddTerritory';
import AddTown from '../features/DataManagement/AddTown';
import DataManagement from '../features/DataManagement/DataManagement';
import ViewArea from '../features/DataManagement/ViewArea';
import ViewRegion from '../features/DataManagement/ViewRegion';
import ViewTerritory from '../features/DataManagement/ViewTerritory';
import DffWholesaleDashboard from '../features/DffWholesale/DffWholesaleDashboard';
import NationalLevel from '../features/DffWholesale/NationalLevel';
import TerritoryLevel from '../features/DffWholesale/TerritoryLevel';
import WholesaleDownloadReport from '../features/DffWholesale/WholesaleDownloadReport';
import DiffChecker from '../features/DiffChecker/DiffChecker';
import AddEmployeeByHr from '../features/HrManagement/AddEmployee';
import EmployeeDatabase from '../features/HrManagement/EmployeeDatabase';
import Interim from '../features/Interim/Interim';
import InterimQpds from '../features/Interim/InterimQpds';
import DamageAndLost from '../features/Inventory/DamageAndLost';
import TownPosmHistory from '../features/Inventory/TownPosmHistory';
import TownSummary from '../features/Inventory/TownSummary';
import UserHistory from '../features/Inventory/UserHistory';
import UserSummary from '../features/Inventory/UserSummary';
import LeaveManagement from '../features/LeaveManagement/LeaveManagement';
import LoginDetails from '../features/LoginDetails/LoginDatails';
import AllocateMaterial from '../features/MaterialManagement/AllocateMaterial';
import MaterialMovement from '../features/MaterialManagement/MaterialMovement';
import MaterialViewAndAdd from '../features/MaterialManagement/MaterialViewAndAdd';
import ReceivedMaterial from '../features/MaterialManagement/ReceivedMaterial';
import Notice from '../features/Notice/Notice';
import PjpAllocation from '../features/PjpAllocation/PjpAllocation';
import ApprovePjp from '../features/PjpManagement/ApprovePjp';
import DraftDownload from '../features/PjpManagement/DraftDownload';
import PjpDraftUpload from '../features/PjpManagement/PjpDraftUpload';
import PjpMapping from '../features/PjpManagement/PjpMapping';
import PjpStatus from '../features/PjpManagement/PjpStatus';
import AddPosm from '../features/PosmManagement/AddPosm';
import ApproveRejectTransfer from '../features/PosmManagement/ApproveRejectTransfer';
import PosmAllocation from '../features/PosmManagement/PosmAllocation';
import TownPosmMovement from '../features/PosmManagement/TownPosmMovement';
import ViewPosmList from '../features/PosmManagement/ViewPosmList';
import Profile from '../features/Profile/Profile';
import MTCMVisitCall from '../features/Report/MTCMVisitCall';
import MsCallReport from '../features/Report/MsCallReport';
import VisitCallReport from '../features/Report/VisitCallReport';
import RoutePlanTracker from '../features/RoutePlanTracker/RoutePlanTracker';
import MSSalary from '../features/SalaryDisbursement/MSSalary';
import MerchandiserSalary from '../features/SalaryDisbursement/MerchandiserSalary';
import SalaryStatus from '../features/SalaryDisbursement/SalaryStatus';
import MSSalaryEvaluation from '../features/SalaryEvaluation/MSEvaluation';
import MerchandiserSalaryEvaluation from '../features/SalaryEvaluation/MerchandiserEvaluation';
import SalaryStatusEvaluation from '../features/SalaryEvaluation/SalaryStatus';
import AddGroup from '../features/SuperUser/AddGroup';
import AddUser from '../features/SuperUser/AddUser';
import ViewGroup from '../features/SuperUser/ViewGroup';
import ViewUser from '../features/SuperUser/ViewUser';
import AddEmployee from '../features/TeamManagement/AddEmployee';
import TeamManagement from '../features/TeamManagement/TeamManagement';
import Ticket from '../features/Ticket/Ticket';
import TrainingModule from '../features/TrainingModule/TrainingModule';
import DownloadReport from '../features/downloadReport/DownloadReport';

const routeData = [
    {
        name: 'Profile',
        path: '/profile',
        element: <Profile />,
    },
    {
        name: 'Dashboard',
        path: '/',
        element: <Dashboard />,
    },
    {
        name: 'Attendance',
        path: '/attendance',
        element: <Attendance />,
    },
    {
        name: 'Notice',
        path: '/notice',
        element: <Notice />,
    },
    {
        name: 'Tickets',
        path: '/tickets',
        element: <Ticket />,
    },
    {
        name: 'Login Details',
        path: '/login-details',
        element: <LoginDetails />,
    },
    {
        name: 'Add Employee',
        path: '/hr-add-employee',
        element: <AddEmployeeByHr />,
    },
    {
        name: 'Employee Database',
        path: '/employee-database',
        element: <EmployeeDatabase />,
    },
    {
        name: 'Merchandiser Salary',
        path: '/merchandiser-salary',
        element: <MerchandiserSalary />,
    },
    {
        name: 'MS Salary',
        path: '/ms-salary',
        element: <MSSalary />,
    },
    {
        name: 'Salary Status',
        path: '/salary-status-disbursement',
        element: <SalaryStatus />,
    },
    // Salary Evaluation
    {
        name: 'Merchandiser Evaluation',
        path: '/merchandiser-evaluation',
        element: <MerchandiserSalaryEvaluation />,
    },
    {
        name: 'MS Evaluation',
        path: '/ms-evaluation',
        element: <MSSalaryEvaluation />,
    },
    {
        name: 'Salary Status',
        path: '/salary-status-evaluation',
        element: <SalaryStatusEvaluation />,
    },
    {
        name: 'Training Module',
        path: '/training-module',
        element: <TrainingModule />,
    },
    {
        name: 'Leave Management',
        path: '/leave-management',
        element: <LeaveManagement />,
    },
    {
        name: 'View Employee',
        path: '/view-employee',
        element: <TeamManagement />,
    },
    {
        name: 'Add Employee',
        path: '/add-employee',
        element: <AddEmployee />,
    },
    {
        name: 'Add Region',
        path: '/add-region',
        element: <AddRegion />,
    },
    {
        name: 'View Region',
        path: '/view-region',
        element: <ViewRegion />,
    },
    {
        name: 'Add Area',
        path: '/add-area',
        element: <AddArea />,
    },
    {
        name: 'View Area',
        path: '/view-area',
        element: <ViewArea />,
    },
    {
        name: 'Add Territory',
        path: '/add-territory',
        element: <AddTerritory />,
    },
    {
        name: 'View Territory',
        path: '/view-territory',
        element: <ViewTerritory />,
    },
    {
        name: 'Add Town',
        path: '/add-town',
        element: <AddTown />,
    },
    {
        name: 'View Town',
        path: '/view-town',
        element: <DataManagement />,
    },
    // SOVM Management Routes
    {
        name: 'Add POSM',
        path: '/add-sovm',
        element: <AddPosm />,
    },
    {
        name: 'View POSM List',
        path: '/view-sovm-list',
        element: <ViewPosmList />,
    },
    {
        name: 'POSM Allocation',
        path: '/sovm-allocation',
        element: <PosmAllocation />,
    },
    {
        name: 'Town POSM Movement',
        path: '/town-sovm-movement',
        element: <TownPosmMovement />,
    },
    {
        name: 'POSM Approve/Reject Transfer',
        path: '/sovm-approve-reject-transfer',
        element: <ApproveRejectTransfer />,
    },
    {
        name: 'PJP Status',
        path: '/pjp-status',
        element: <PjpStatus />,
    },
    {
        name: 'PJP Draft Upload',
        path: '/pjp-draft-upload',
        element: <PjpDraftUpload />,
    },
    {
        name: 'Download Draft',
        path: '/download-draft',
        element: <DraftDownload />,
    },
    {
        name: 'PJP Mapping',
        path: '/pjp-mapping',
        element: <PjpMapping />,
    },
    {
        name: 'Approve PJP',
        path: '/approve-pjp',
        element: <ApprovePjp />,
    },
    {
        name: 'Route Plan Tracker',
        path: '/route-plan-tracker',
        element: <RoutePlanTracker />,
    },
    {
        name: 'PJP Allocation',
        path: '/pjp-allocation',
        element: <PjpAllocation />,
    },
    {
        name: 'Add Group',
        path: '/add-group',
        element: <AddGroup />,
    },
    {
        name: 'View Group',
        path: '/view-group',
        element: <ViewGroup />,
    },
    {
        name: 'View User',
        path: '/view-user',
        element: <ViewUser />,
    },
    {
        name: 'Add User',
        path: '/add-user',
        element: <AddUser />,
    },
    {
        name: 'Diff Checker',
        path: '/diff-checker',
        element: <DiffChecker />,
    },
    {
        name: 'Visit Call Report',
        path: '/visit-call-report',
        element: <VisitCallReport />,
    },
    {
        name: 'CM SUP Call Report',
        path: '/cm-sup-call-report',
        element: <MsCallReport />,
    },
    {
        name: 'MTM Visit Call',
        path: '/mtm-visit-call',
        element: <MTCMVisitCall />,
    },
    {
        name: 'View Material',
        path: '/material/view',
        element: <MaterialViewAndAdd />,
    },
    {
        name: 'Allocate Material',
        path: '/material/allocate-material',
        element: <AllocateMaterial />,
    },
    {
        name: 'Receive Material in DH',
        path: '/material/receive-material-in-dh',
        element: <ReceivedMaterial />,
    },
    {
        name: 'Material Movement',
        path: '/material/material-movement',
        element: <MaterialMovement />,
    },
    {
        name: 'Approve/Reject Transfer',
        path: '/material/approve-reject-transfer',
        element: <ApproveRejectTransfer />,
    },
    // SOVM ROutes
    {
        name: 'Town POSM History',
        path: '/inventory/sovm-history',
        element: <TownPosmHistory />,
    },
    {
        name: 'Town POSM Summary',
        path: '/inventory/town-sovm-summary',
        element: <TownSummary />,
    },
    {
        name: 'POSM Damage & Lost',
        path: '/inventory/sovm-damage-lost',
        element: <DamageAndLost />,
    },
    {
        name: 'FF POSM History',
        path: '/inventory/ff-sovm-history',
        element: <UserHistory />,
    },
    {
        name: 'FF POSM Summary',
        path: '/inventory/ff-sovm-summary',
        element: <UserSummary />,
    },
    {
        name: 'Daily Activity Report',
        path: '/daily-activity-report',
        element: <DailyActivityReport />,
    },
    {
        name: 'Fixed Display Audit',
        path: '/interim-fixed-display-audit',
        element: <Interim />,
    },
    {
        name: 'QPDS',
        path: '/interim-report-qpds',
        element: <InterimQpds />,
    },
    {
        name: 'Backlit Audit',
        path: '/backlit-audit',
        element: <BacklitAudit />,
    },
    {
        name: 'Wholesale Dashboard',
        path: '/wholesale-dashboard',
        element: <DffWholesaleDashboard />,
    },
    {
        name: 'National Level',
        path: '/national-level',
        element: <NationalLevel />,
    },
    {
        name: 'Territory Level',
        path: '/territory-level',
        element: <TerritoryLevel />,
    },
    {
        name: 'Wholesale Download Report',
        path: '/wholesale-download-report',
        element: <WholesaleDownloadReport />,
    },
    {
        name: 'Download Report',
        path: '/download-report',
        element: <DownloadReport />,
    },
    {
        name: 'AI POSM List',
        path: '//ai-posm-list',
        element: <AiPosmList />,
    },
];

export default routeData;
