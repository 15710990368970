/* eslint-disable import/prefer-default-export */
/* eslint-disable prettier/prettier */
import {
    AimOutlined,
    ApartmentOutlined,
    AuditOutlined,
    BellOutlined,
    BgColorsOutlined,
    BlockOutlined,
    BranchesOutlined,
    BulbOutlined,
    CloudDownloadOutlined,
    CodeOutlined,
    ContainerOutlined,
    CrownOutlined,
    DeploymentUnitOutlined,
    FolderOpenOutlined,
    FundViewOutlined,
    NotificationOutlined,
    PicLeftOutlined,
    PieChartOutlined,
    SplitCellsOutlined,
    TagsOutlined,
    TeamOutlined,
    UserOutlined,
} from "@ant-design/icons";

export const items = [
    {
        label: "Profile",
        key: "/profile",
        icon: <UserOutlined />,
        serial: 1,
    },
    {
        label: "Dashboard",
        key: "/",
        icon: <PieChartOutlined />,
        serial: 2,
    },
    {
        label: "Attendance",
        key: "/attendance",
        icon: <AimOutlined />,
        serial: 3,
    },
    {
        label: "Tickets",
        key: "/tickets",
        icon: <TagsOutlined />,
        serial: 4,
    },
    {
        label: "Daily Activity Report",
        key: "/daily-activity-report",
        icon: <FundViewOutlined />,
        serial: 5,
    },
    {
        label: "Route Plan Tracker",
        key: "/route-plan-tracker",
        icon: <BranchesOutlined />,
        serial: 6,
    },
    {
        label: "Login Details",
        key: "/login-details",
        icon: <CodeOutlined />,
        serial: 7,
    },
    {
        label: "HR Management",
        key: "/hr-management",
        icon: <TeamOutlined />,
        children: [
            {
                label: "Add Employee",
                key: "/hr-add-employee",
            },
            {
                label: "Employee Database",
                key: "/employee-database",
            },
        ],
        serial: 8,
    },
    {
        label: "Salary Disbursement",
        key: "/salary-disbursement",
        icon: <TeamOutlined />,
        children: [
            {
                label: "Merchandiser Salary",
                key: "/merchandiser-salary",
            },
            {
                label: "MS Salary",
                key: "/ms-salary",
            },
            {
                label: "Salary Status",
                key: "/salary-status-disbursement",
            },
        ],
        serial: 9,
    },
    {
        label: "Salary Evaluation",
        key: "/salary-evaluation",
        icon: <TeamOutlined />,
        children: [
            {
                label: "Merchandiser Evaluation",
                key: "/merchandiser-evaluation",
            },
            {
                label: "MS Evaluation",
                key: "/ms-evaluation",
            },
            {
                label: "Salary Status",
                key: "/salary-status-evaluation",
            },
        ],
        serial: 10,
    },
    {
        label: "Team Management",
        key: "/team-management",
        icon: <TeamOutlined />,
        children: [
            {
                label: "Add Employee",
                key: "/add-employee",
            },
            {
                label: "View Employee",
                key: "/view-employee",
            },
        ],
        serial: 11,
    },
    {
        label: "PJP Management",
        key: "/pjp-management",
        icon: <ApartmentOutlined />,
        children: [
            {
                label: "PJP Status",
                key: "/pjp-status",
            },
            {
                label: "PJP Draft Upload",
                key: "/pjp-draft-upload",
            },
            {
                label: "PJP Mapping",
                key: "/pjp-mapping",
            },
            {
                label: "Approve PJP",
                key: "/approve-pjp",
            },
            {
                label: "Download Draft",
                key: "/download-draft",
            },
        ],
        serial: 12,
    },
    // Inventory Management Routes
    {
        label: "Inventory",
        key: "/inventory",
        icon: <FolderOpenOutlined />,
        children: [
            {
                label: "Town POSM History",
                key: "/inventory/sovm-history",
            },
            {
                label: "Town POSM Summary",
                key: "/inventory/town-sovm-summary",
            },
            {
                label: "POSM Damage & Lost",
                key: "/inventory/sovm-damage-lost",
            },
            {
                label: "FF POSM History",
                key: "/inventory/ff-sovm-history",
            },
            {
                label: "FF POSM Summary",
                key: "/inventory/ff-sovm-summary",
            },
        ],
        serial: 13,
    },
    // SOVM Management Routes
    {
        label: "POSM Management",
        key: "/posm-management",
        icon: <BlockOutlined />,
        children: [
            {
                label: "Add POSM",
                key: "/add-sovm",
            },
            {
                label: "View POSM List",
                key: "/view-sovm-list",
            },
            {
                label: "POSM Allocation",
                key: "/sovm-allocation",
            },
            {
                label: "Town POSM Movement",
                key: "/town-sovm-movement",
            },
            {
                label: "POSM Approve/Reject Transfer",
                key: "/sovm-approve-reject-transfer",
            },
        ],
        serial: 14,
    },
    {
        label: "Interim Report",
        key: "/interim-report",
        icon: <BgColorsOutlined />,
        children: [
            {
                label: "Fixed Display Audit",
                key: "/interim-fixed-display-audit",
            },
            {
                label: "QPDS",
                key: "/interim-report-qpds",
            },
        ],
        serial: 15,
    },
    {
        label: "Notice",
        key: "/notice",
        icon: <BellOutlined />,
        serial: 16,
    },
    {
        label: "Training Module",
        key: "/training-module",
        icon: <ContainerOutlined />,
        serial: 17,
    },
    {
        label: "Leave Approval",
        key: "/leave-management",
        icon: <SplitCellsOutlined />,
        serial: 18,
    },
    {
        label: "Super User",
        key: "/superuser",
        icon: <CrownOutlined />,
        children: [
            {
                label: "Add Group",
                key: "/add-group",
            },
            {
                label: "View Group",
                key: "/view-group",
            },
            {
                label: "Add User",
                key: "/add-user",
            },
            {
                label: "View User",
                key: "/view-user",
            },
            {
                label: "Diff Checker",
                key: "/diff-checker",
            },
        ],
        serial: 19,
    },
    {
        label: "Wholesale Report",
        key: "/wholesale-report",
        icon: <DeploymentUnitOutlined />,
        children: [
            {
                label: "Wholesale Dashboard",
                key: "/wholesale-dashboard",
            },
            {
                label: "National Level",
                key: "/national-level",
            },
            {
                label: "Territory Level",
                key: "/territory-level",
            },
            {
                label: "Wholesale Download Report",
                key: "/wholesale-download-report",
            },
        ],
        serial: 20,
    },
    {
        label: "Visit Call Report",
        key: "/visit-call-report",
        icon: <NotificationOutlined />,
        serial: 21,
    },
    {
        label: "CM SUP Call Report",
        key: "/cm-sup-call-report",
        icon: <AuditOutlined />,
        serial: 22,
    },
    {
        label: "MTM Visit Call",
        key: "/mtm-visit-call",
        icon: <PicLeftOutlined />,
        serial: 23,
    },
    {
        label: "Backlit Audit",
        key: "/backlit-audit",
        icon: <BulbOutlined />,
        serial: 24,
    },

    {
        label: "Download Report",
        key: "/download-report",
        icon: <CloudDownloadOutlined />,
        serial: 25,
    },
];
