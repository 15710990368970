import dayjs from 'dayjs';
import React from 'react';
import { styles } from './styles';

function EmployeeHeader({ data }) {
    if (!data) return null;

    const { user, town, payrollStructure, evaluationMonth } = data || {};

    const townNames = town?.map(({ name }) => name);

    return (
        <div style={styles.header}>
            <div>
                <h2 style={{ margin: 0, fontSize: '30px', fontWeight: 'bolder' }}>{user?.name}</h2>
                <p style={{ margin: '2px 0' }}>
                    {' '}
                    <strong>MS Code:</strong> {user?.usercode}
                </p>
                <p style={{ margin: '2px 0' }}>
                    <strong>Town:</strong> {townNames?.join(',')}
                </p>
                <p style={{ margin: '2px 0' }}>
                    <strong>Joining Date:</strong> {dayjs(user?.joiningDate).format('DD MMMM YYYY')}
                </p>
                <p style={{ margin: '2px 0' }}>
                    <strong>Evaluation Month:</strong> {evaluationMonth}
                </p>
                <p style={{ margin: '2px 0' }}>
                    <strong>Status:</strong> {user?.status}
                </p>
            </div>
            <div style={{ textAlign: 'right' }}>
                <h3 style={{ fontSize: '25px', fontWeight: 'bolder' }}>Salary Structure</h3>
                <p style={{ margin: '2px 0' }}>
                    <strong>Basic:</strong> BDT {payrollStructure?.primarySalary}
                </p>
                <p style={{ margin: '2px 0' }}>
                    <strong>Travel Allowance:</strong> BDT {payrollStructure?.travelAllowance}
                </p>
                <p style={{ margin: '2px 0' }}>
                    <strong>Variable Pay:</strong> BDT {payrollStructure?.variableSalary}
                </p>
                <p style={{ margin: '2px 0' }}>
                    <strong>Festive Bonus:</strong> BDT {payrollStructure?.specialBonus}
                </p>
            </div>
        </div>
    );
}

export default EmployeeHeader;
