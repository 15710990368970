/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Col } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetDataManagementMutation } from '../redux/features/dataManagement/dataManagementApi';
import { setArea, setCircle, setRegion, setTerritory, setTown } from '../redux/features/filter/dataManagementFilterSlice';
import FilterSkeleton from '../ui/FilterSkeleton';

function DataManagementFilter({selectAllDisable}) {
    const dispatch = useDispatch();
    const {circle, region, area, territory, town} = useSelector(
        (state) => state.dataManagement
    );
    const {reFetchFilter} = useSelector(
        (state) => state.globalLoading
    );

    const location = useLocation();

    // get data
    const [getDataManagement, {data: fData, }] = useGetDataManagementMutation();

    // filter disable
    const [disableFilter, setDisableFilter] = useState(false);

    // filter main data
    const [filterData, setFilterData] = useState([]);

    const [action, setAction] = useState(false);

    // change filter value
    const handleSelect = (selectedList, eventName) => {
        if (eventName === 'circleList') {
            setAction((prev) => !prev);
            dispatch(setCircle(selectedList));
            dispatch(setRegion([]));
            dispatch(setArea([]));
            dispatch(setTerritory([]));
            dispatch(setTown([]));
        }
        if (eventName === 'regionList') {
            setAction((prev) => !prev);
            dispatch(setRegion(selectedList));
            dispatch(setArea([]));
            dispatch(setTerritory([]));
            dispatch(setTown([]));
        }
        if (eventName === 'areaList') {
            setAction((prev) => !prev);
            dispatch(setArea(selectedList));
            dispatch(setTerritory([]));
            dispatch(setTown([]));
        }
        if (eventName === 'territoryList') {
            setAction((prev) => !prev);
            dispatch(setTerritory(selectedList));
            dispatch(setTown([]));
        }
        if (eventName === 'townList') dispatch(setTown(selectedList));
    };

    // filter data normalize and performance boost
    const data = useMemo(() => {
        const object = [
            circle,
            region,
            area,
            territory,
            town,
        ].reduce((prev, current, index) => {
            if (index === 0) {
                return current.length > 0 ? { ...prev, circleId: current.map((o) => o.value) } : prev;
            }
            if (index === 1) {
                return current.length > 0 ? { ...prev, regionId: current.map((o) => o.value) } : prev;
            }
            if (index === 2) {
                return current.length > 0 ? { ...prev, areaId: current.map((o) => o.value) } : prev;
            }
            if (index === 3) {
                return current.length > 0
                    ? { ...prev, territoryId: current.map((o) => o.value) }
                    : prev;
            }
            if (index === 4) {
                return current.length > 0 ? { ...prev, townId: current.map((o) => o.value) } : prev;
            }
        }, {});
        return object;
    }, [circle, region, area, territory, town]);

    // load filter data
    useEffect(() => {
        setDisableFilter(true);
        getDataManagement(data);
    }, [action, reFetchFilter]);

    useEffect(() => {
        if (fData?.data?.townList?.length > 0) {
            setFilterData({ ...filterData, ...fData.data });
            setDisableFilter(false);
        }
    }, [fData])

    if (filterData?.length <= 0) {
        return <FilterSkeleton />
    };

    // render multiselect component
    const renderMultiselectComponent = (label, filterName, value) => (
        <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <MultiSelect
            labelledBy={label}
            hasSelectAll={!selectAllDisable || filterName !== 'town'}
            options={filterData[`${filterName}List`] || []}
            value={value}
            onChange={(selectedList) => handleSelect(selectedList, `${filterName}List`)}
            className="multiselect-input"
            disabled={disableFilter}
            overrideStrings={{
                allItemsAreSelected: 'All',
                selectSomeItems: label,
            }}

        />
        </Col>
    )

    return (
        <>
            {/* {renderMultiselectComponent('Market Operation', 'circle', circle)} */}
            {renderMultiselectComponent('Cluster', 'region', region)}
            {renderMultiselectComponent('Area', 'area', area)}
            {renderMultiselectComponent('Territory', 'territory', territory)}
            {(location.pathname !== '/national-level' && location.pathname !== '/wholesale-dashboard' && location.pathname !== '/wholesale-download-report') ? renderMultiselectComponent('Town', 'town', town) : null}
        </>
    );
}

export default DataManagementFilter;
