import { useSelector } from 'react-redux';

const useUserType = () => {
    const { designation } = useSelector((state) => state?.auth?.user) ?? {};

    // if (/^TM/gi.test(designation)) return 'territoryManager';
    // if (/^AM/gi.test(designation)) return 'areaManager';

    if (designation === 'TM') return 'territoryManager';
    if (designation === 'AM') return 'areaManager';

    return null;
};

export default useUserType;
