/* eslint-disable no-underscore-dangle */
import { Button, message, Pagination, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import {
    setEvaluationData,
    setPenaltyReason,
} from '../../redux/features/SharedSalaryModule/evaluationDataSlice';
import { resetSalaryDisbursementFilter } from '../../redux/features/SharedSalaryModule/salaryEvaluationFilterSlice';
import {
    useApproveSalaryEvaluationMutation,
    useGetAllMSEvaluationMutation,
} from '../../redux/features/SharedSalaryModule/salaryEvaluationsApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import generateMonthColumns from '../../util/generateMonthColumns';
import useUserType from '../../util/useUserType';
import SalaryModal from '../SharedSalaryModule/SalaryModal';

function MSEvaluation() {
    const userType = useUserType();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    const getTownPosmHistory = () => {};
    const data = {
        data: [],
        meta: {
            total: 0,
        },
    };

    const [triggerMSEvaluation, mSEvaluationOptions] = useGetAllMSEvaluationMutation();
    const [approveSalaryEvaluation, salaryEvaluationOptions] = useApproveSalaryEvaluationMutation();

    const { region, area, territory, town } = useSelector((state) => state?.dataManagement);
    const { month, year, eligibility, ffCode } = useSelector(
        (state) => state?.salaryEvaluationFilter
    );

    const handleTriggerMSEvaluation = useCallback(
        async (payload) => {
            try {
                const res = await triggerMSEvaluation(payload);
            } catch (error) {
                message.error('Something went wrong');
            }
        },
        [triggerMSEvaluation]
    );

    useEffect(() => {
        dispatch(resetSalaryDisbursementFilter());
        handleTriggerMSEvaluation({
            month: dayjs().format('MMMM'),
            year: dayjs().format('YYYY'),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleTriggerMSEvaluation]);

    const { evaluations, travelPenaltyReason, basicPenaltyReason, monthSummary, yearSummary } =
        mSEvaluationOptions?.data?.data || {};

    const getTownPosmHistoryData = (m, yer, e, ff) => {
        const bodyData = {};

        if (m) {
            bodyData.month = m;
        }
        if (yer) {
            bodyData.year = yer;
        }
        if (e) {
            bodyData.eligibility = e;
        } else if (e === false) {
            bodyData.eligibility = e;
        }
        if (ff) {
            bodyData.usercode = ff;
        }
        return bodyData;
    };

    const fetchDhHistoryData = (page, totalShow, clean) => {
        if (!month) {
            message.warning('Month is required');
            return {};
        }
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }

        triggerMSEvaluation({
            page,
            limit: totalShow,
            ...getDataManagementFilterData({ region, area, territory, town }),
            ...getTownPosmHistoryData(month, year, eligibility, ffCode),
        });
        return { page, totalShow };
    };

    useEffect(() => {
        getTownPosmHistory({ page: currentPage, limit: totalShowPage });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEvaluationClick = (evaluationType, record) => {
        dispatch(
            setPenaltyReason({
                travelPenaltyReason,
                basicPenaltyReason,
            })
        );
        dispatch(setEvaluationData({ ...record, evaluationType }));
        setIsModalOpen(true);
    };

    // Generate columns dynamically based on the first row
    const monthColumns = generateMonthColumns(evaluations || []);
    const columns = [
        {
            title: ' ',
            children: [
                { title: 'MS Name', dataIndex: 'user', key: 'name', render: (user) => user?.name },
                {
                    title: 'FF Code',
                    dataIndex: 'user',
                    key: 'usercode',
                    render: (user) => user?.usercode,
                },
                {
                    title: 'Cluster',
                    dataIndex: 'town',
                    key: 'region',
                    render: (t) => [...new Set(t?.map((r) => r?.region))].join(', '),
                },
                {
                    title: 'Area',
                    dataIndex: 'town',
                    key: 'area',
                    render: (t) => [...new Set(t?.map((r) => r?.area))].join(', '),
                },
                {
                    title: 'Territory',
                    dataIndex: 'town',
                    key: 'territory',
                    render: (t) => [...new Set(t?.map((r) => r?.territory))].join(', '),
                },
                {
                    title: 'Town',
                    dataIndex: 'town',
                    key: 'name',
                    render: (t) => [
                        ...new Set(
                            t?.map((r, index, arr) => (
                                <span key={r?.towncode}>
                                    {r?.name} <small>({r?.towncode})</small>
                                    {index < arr.length - 1 ? ', ' : ''}
                                </span>
                            ))
                        ),
                    ],
                },
                {
                    title: 'Eligibility',
                    dataIndex: 'variablePayEligibility',
                    key: 'variablePayEligibility',
                    render: (variablePayEligibility) => (variablePayEligibility ? 'Yes' : 'No'),
                    align: 'center',
                },
                ...monthColumns,
            ],
        },
        {
            title: `${month} Breakdown`,
            align: 'center',
            children: [
                {
                    title: 'Basic',
                    dataIndex: ['payableBaseSalary', 'totalBaseSalary'],
                    key: 'payableBaseSalary',
                    align: 'center',
                },
                {
                    title: 'Travel Allowance',
                    dataIndex: ['payableTravelAllowance', 'totalTravelAllowance'],
                    key: 'payableTravelAllowance',
                    align: 'center',
                },
                {
                    title: 'Variable Pay',
                    dataIndex: 'totalPayableIncentive',
                    key: 'totalPayableIncentive',
                    align: 'center',
                },
            ],
        },
        {
            title: ' ',
            children: [
                {
                    title: 'Action',
                    dataIndex: 'approvalWorkflow',
                    key: 'approvalWorkflow',
                    render: (approvalWorkflow, record) => {
                        let isEvaluate = false;
                        let isReEvaluate = false;
                        let isApproved = false;
                        let btnDisabled = false;

                        let evaluate = false;
                        const reEvaluate = false;

                        if (
                            userType === 'territoryManager' ||
                            (!approvalWorkflow.territoryManager?.evaluatedAt &&
                                userType === 'areaManager')
                        ) {
                            evaluate = true;
                        }

                        if (approvalWorkflow[userType]?.evaluatedAt) {
                            isEvaluate = true;
                        }
                        if (
                            userType === 'areaManager' &&
                            approvalWorkflow[userType]?.reEvaluatedAt
                        ) {
                            isReEvaluate = true;
                        }
                        if (approvalWorkflow[userType]?.approvedAt) {
                            isApproved = true;
                        }

                        if (userType === 'areaManager' && approvalWorkflow[userType]?.approvedAt) {
                            isApproved = true;
                        }

                        if (isApproved || isEvaluate || isReEvaluate) {
                            btnDisabled = true;
                        }

                        return (
                            <Space size="middle">
                                {evaluate ? (
                                    <Button
                                        type="primary"
                                        size="small"
                                        disabled={btnDisabled}
                                        onClick={() => handleEvaluationClick('evaluate', record)}
                                    >
                                        Evaluate
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        size="small"
                                        disabled={btnDisabled}
                                        onClick={() => handleEvaluationClick('re-evaluate', record)}
                                    >
                                        Re-Evaluate
                                    </Button>
                                )}
                            </Space>
                        );
                    },
                },
            ],
        },
    ];

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchDhHistoryData(pageNumber, totalPageChange);
    };

    const handleApprovalClick = async () => {
        if (selectedRowKeys?.length <= 0) {
            message.error('Please select at least one record');
            return;
        }
        const res = await approveSalaryEvaluation(selectedRowKeys);

        if (res.data) {
            message.success('Salary evaluation approved successfully');
            fetchDhHistoryData(currentPage, totalShowPage);
        } else {
            message.error('Failed to approve salary evaluation');
        }
    };

    const styles = {
        backgroundColor: 'red',
    };

    return (
        <>
            <HelmetHeader title="MS Evaluation" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    // downloadButton={download}
                    loading={mSEvaluationOptions?.isLoading}
                    queryFunc={fetchDhHistoryData}
                    pathname="/salary-evolution"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">MS Evaluation</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {' '}
                    <Table
                        loading={mSEvaluationOptions?.isLoading}
                        rowSelection={{
                            type: 'checkbox',
                            onChange: (rowKeys, rows) => {
                                setSelectedRows(rows);
                                setSelectedRowKeys(rowKeys);
                            },
                            getCheckboxProps: (record) => ({
                                disabled:
                                    record.approvalWorkflow[userType]?.approvedAt ||
                                    record.approvalWorkflow[userType]?.approvedAt,
                            }),
                        }}
                        rowClassName={(record) =>
                            !record.variablePayEligibility ? 'not_eligible' : null
                        }
                        scroll={{
                            x: 'auto',
                        }}
                        columns={columns}
                        dataSource={evaluations || []}
                        size="small"
                        rowKey={(record) => record?._id}
                        pagination={false}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Pagination
                    size="large"
                    pageSize={totalShowPage}
                    showSizeChanger
                    showQuickJumper
                    current={currentPage}
                    defaultCurrent={1}
                    total={mSEvaluationOptions?.data?.meta?.total || 0}
                    onChange={onChange}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div>

            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                {userType === 'areaManager' ? (
                    <Button onClick={() => handleApprovalClick()} type="primary">
                        Approve
                    </Button>
                ) : null}
            </div>

            <SalaryModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                // triggerMerchandiser={triggerMSEvaluation}
                refetch={() => fetchDhHistoryData(currentPage, totalShowPage)}
            />
        </>
    );
}

export default MSEvaluation;
