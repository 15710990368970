import { CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Collapse, Spin } from 'antd';
import { useState } from 'react';
import CategoryShelfAIResult from '../../components/CategoryShelfAIResult';
import ConfidenceScoreResult from '../../components/ConfidenceScoreResult';
import POSMAiResult from '../../components/POSMAiResult';
import SachetAIResult from '../../components/SachetAIResult';
import SOSAIResult from '../../components/SOSAiResult';
import { useGetAiResultQuery } from '../../redux/features/report/visitCallApi';

const buttonLabelsVal = {
    DA: 'Category Shelf Display',
    SOS: 'Share of Shelf',
    SACHET: 'Share of Sachet',
    POSM: 'Share of Visibility Material',
    CONF: 'Confidence Score',
};

const buttonColors = {
    category: '#0080B6',
    shelf: '#7E52A6',
    sachet: '#007178',
    visibility: '#FF8100',
    confidence: '#007D2A',
};

function CollapsiblePanel({ executionItems, executionId }) {
    const [activeKey, setActiveKey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [panelData, setPanelData] = useState(null);
    const [currentType, setCurrentType] = useState(null);

    const [id, setId] = useState(null);

    // ai result get api hook
    const {
        data: aiData,
        refetch,
        isLoading,
    } = useGetAiResultQuery(
        { id },
        {
            skip: !id,
        }
    );

    const handleClick = async (type) => {
        if (currentType === type) {
            setActiveKey(null);
            setTimeout(() => {
                setCurrentType(null);
            }, 200);
            return;
        }
        setId(executionId);
        setLoading(true);
        setCurrentType(type);

        setActiveKey('1');
        setTimeout(() => {
            setLoading(false);
        }, 200);
    };

    // Mapping between short codes and full categories
    const categoryMapping = {
        DA: 'DA',
        SOS: 'SOS',
        POSM: 'POSM',
        SACHET: 'SACHET',
        CONF: 'CONF',
    };

    const categories = {
        DA: {
            component: CategoryShelfAIResult,
            label: buttonLabelsVal.DA,
            color: buttonColors.category,
            data: aiData?.data?.jobsInfo.filter((x) => x.name === 'DA'),
        },
        SOS: {
            component: SOSAIResult,
            label: buttonLabelsVal.SOS,
            color: buttonColors.shelf,
            data: aiData?.data?.jobsInfo.filter((x) => x.name === 'SOS'),
        },
        POSM: {
            component: POSMAiResult,
            label: buttonLabelsVal.POSM,
            color: buttonColors.visibility,
            data: aiData?.data?.jobsInfo.filter((x) => x.name === 'POSM'),
        },
        SACHET: {
            component: SachetAIResult,
            label: buttonLabelsVal.SACHET,
            color: buttonColors.sachet,
            data: aiData?.data?.jobsInfo.filter((x) => x.name === 'SACHET'),
        },
        CONF: {
            component: ConfidenceScoreResult,
            label: buttonLabelsVal.CONF,
            color: buttonColors.confidence,
            data: aiData?.data,
        },
    };

    // Filter categories based on executionItems
    const filteredCategories = Object.keys(categories)
        .filter((key) => key !== 'CONF')
        .filter((key) =>
            executionItems?.some((item) => categoryMapping[item] === key || item === key)
        )
        .reduce((acc, key) => {
            acc[key] = categories[key];
            return acc;
        }, {});
    filteredCategories.CONF = categories.CONF;

    return (
        <div className="button-panel-container">
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', margin: '10px 0' }}>
                {Object.keys(filteredCategories).map((key) => (
                    <Button
                        type="primary"
                        key={key}
                        onClick={() => handleClick(key)}
                        className={currentType === key ? 'active-btn' : ''}
                        style={{
                            backgroundColor: filteredCategories[key].color,
                        }}
                    >
                        {filteredCategories[key].label}
                        <CaretRightOutlined
                            rotate={currentType === key ? 90 : 0}
                            className="collapse__icon"
                        />
                    </Button>
                ))}
            </div>

            <Collapse activeKey={activeKey} ghost className="collapsible-panel">
                <Collapse.Panel key="1" className="ai__result__panel">
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 69,
                                    fontWeight: 'bolder',
                                }}
                            />
                        }
                        spinning={loading || isLoading}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '200px',
                        }}
                    >
                        {aiData &&
                            currentType &&
                            filteredCategories[currentType] &&
                            filteredCategories[currentType].component({
                                data: filteredCategories[currentType].data,
                            })}
                    </Spin>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
}

export default CollapsiblePanel;
