import { Button } from 'antd';
import React from 'react';
import { styles } from './styles';

function NavigationButtons({ onBack, onNext, onSubmit, isFirstPage, isLastPage }) {
    return (
        <div style={styles.buttonContainer}>
            <Button
                size="large"
                style={{ width: '200px' }}
                type={isFirstPage ? 'primary' : 'default'}
                danger={isFirstPage}
                onClick={isFirstPage ? () => onBack(false) : onBack}
            >
                {isFirstPage ? 'Close' : 'Back'}
            </Button>
            <Button
                type="primary"
                color="cyan"
                size="large"
                style={{ width: '200px', backgroundColor: `${isLastPage ? 'green' : 'primary'}` }}
                onClick={isLastPage ? onSubmit : onNext}
            >
                {isLastPage ? 'Submit' : 'Next'}
            </Button>
        </div>
    );
}

export default NavigationButtons;
