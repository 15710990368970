import { useSelector } from 'react-redux';

function SalarySummary({ monthSummary, yearSummary }) {
    const { month } = useSelector((state) => state?.salaryEvaluationFilter);

    return (
        <div
            style={{
                borderRadius: '10px',
                backgroundColor: '#fff',
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
                margin: '15px 0',
                padding: '0  0 5px 0',
            }}
        >
            <div className="box-heading">{month} Month Summary</div>

            <div
                style={{
                    padding: '10px',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                    gap: '20px',
                }}
            >
                <div>
                    <h2
                        style={{
                            textAlign: 'center',
                            fontSize: '4rem',
                            fontWeight: '900',
                            color: '#3788FD',
                            marginBottom: '10px',
                        }}
                    >
                        {monthSummary?.totalPayableSalary || 0} TK
                    </h2>
                    <p
                        style={{
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            fontWeight: '500',
                        }}
                    >
                        Total Salary
                    </p>
                </div>
                <div>
                    <h2
                        style={{
                            textAlign: 'center',
                            fontSize: '4rem',
                            fontWeight: '900',
                            color: '#3788FD',
                            marginBottom: '10px',
                        }}
                    >
                        {monthSummary?.totalBaseSalary || 0} TK
                    </h2>
                    <p
                        style={{
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            fontWeight: '500',
                        }}
                    >
                        Basic Salary
                    </p>
                </div>
                <div>
                    <h2
                        style={{
                            textAlign: 'center',
                            fontSize: '4rem',
                            fontWeight: '900',
                            color: '#3788FD',
                            marginBottom: '10px',
                        }}
                    >
                        {monthSummary?.totalTravelAllowance || 0} TK
                    </h2>
                    <p
                        style={{
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            fontWeight: '500',
                        }}
                    >
                        Travel Allowance
                    </p>
                </div>
                <div>
                    <h2
                        style={{
                            textAlign: 'center',
                            fontSize: '4rem',
                            fontWeight: '900',
                            color: '#3788FD',
                            marginBottom: '10px',
                        }}
                    >
                        {monthSummary?.totalPayableIncentive || 0} TK
                    </h2>
                    <p
                        style={{
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            fontWeight: '500',
                        }}
                    >
                        Variable Pay
                    </p>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    backgroundColor: '#DEEFFF',
                    margin: '10px',
                    padding: '10px 0 0 15px',
                    borderRadius: '10px',
                    gap: '20px',
                    fontSize: '1rem',
                }}
            >
                <p>
                    <strong>Moving Year Breakdown:- </strong>
                </p>
                <p>
                    <strong>Total Salary: </strong> {yearSummary?.totalPayableSalary || 0} TK
                </p>
                <p>
                    <strong>Basic Salary: </strong> {yearSummary?.totalBaseSalary || 0} TK
                </p>
                <p>
                    <strong>Travel Allowance: </strong> {yearSummary?.totalTravelAllowance || 0} TK
                </p>
                <p>
                    <strong>Payable Salary: </strong> {yearSummary?.totalPayableSalary || 0} TK
                </p>
            </div>
        </div>
    );
}

export default SalarySummary;
