/* eslint-disable no-underscore-dangle */
import { Button, Checkbox, Pagination, Popconfirm, Select, Space, Table, message } from 'antd';
import Column from 'antd/es/table/Column';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setGlobalLoading, setReFetchFilter } from '../../redux/features/loaderSlice';
import {
    useAssignPjpDataMutation,
    useGetPjpDraftDataMutation,
    useSubmitDraftMutation,
} from '../../redux/features/pjpManagement/pjpManagementApi';
import { resetPjpStatusFilter } from '../../redux/features/pjpManagement/pjpMappingFilter';
import TableSkeleton from '../../ui/TableSkeleton';
import { countSpecificDayInMonth, getNextMonth, getYearCount } from '../../util/dateCount';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

function PjpMapping() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    const { route, outletCode, channel, outletType } = useSelector(
        (state) => state.pjpMappingFilter
    );

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    // pjp mapped items
    const [pjpMappedItems, setPjpMappedItems] = useState([]);

    const [pjpData, setPjpData] = useState([]);
    const [getPjpDraftData, { data, isLoading }] = useGetPjpDraftDataMutation();

    const [selectedKeys, setSelectedKeys] = useState([]);

    function getBodyData(r, oCode, c, oType) {
        const bodyData = {};
        if (r) {
            bodyData.route = r;
        }
        if (oCode) {
            bodyData.outletcode = oCode;
        }
        if (c) {
            bodyData.channel = c;
        }
        if (oType.length) {
            bodyData.outletType = oType;
        }
        return bodyData;
    }

    const fetPjpDraftData = async (pageNumber, totalPageChange, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }
        try {
            const result = await getPjpDraftData({
                ...getBodyData(route, outletCode, channel, outletType),
                ...getDataManagementFilterData({ circle, region, area, territory, town }),
                page: pageNumber,
                limit: totalPageChange,
            }).unwrap();
            setPjpData(result?.data?.map((x) => ({ id: x._id, ...x })));
            setSelectedKeys(
                result?.data?.map((x) => (x.weekNo && x.serviceDay ? x._id : null)).filter((x) => x)
            );
        } catch (error) {
            message.error('Something went wrong');
        }
    };

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetPjpDraftData(pageNumber, totalPageChange);
    };

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'PJP Mapping', user: user.name });
    }, [user.name]);

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedKeys(selectedRowKeys);
            setPjpMappedItems(selectedRows);
            setPjpData((prev) =>
                prev.map((x) =>
                    selectedRows.find((y) => y._id === x._id)?._id
                        ? { ...x, checked: true }
                        : { ...x }
                )
            );
        },
        selectedRowKeys: selectedKeys,
        getCheckboxProps: (record) => ({
            disabled:
                !record.serviceDay ||
                (record?.weekNo
                    ? false
                    : !(
                          record.week1 ||
                          record.week2 ||
                          record.week3 ||
                          record.week4 ||
                          record.week5
                      )),
            // Column configuration not to be checked
            checked: true,
            name: record.name,
        }),
    };

    const dayPicker = {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    };

    const changeServiceDay = (serviceDay, idx) => {
        setPjpData((prev) =>
            prev.map((item) =>
                item.id === idx
                    ? {
                          ...item,
                          serviceDay: serviceDay + 1,
                          serviceDayStr: dayPicker[serviceDay],
                          weekCount: countSpecificDayInMonth(
                              getYearCount(),
                              String(getNextMonth()).padStart(2, '0'),
                              serviceDay
                          ),
                      }
                    : { ...item }
            )
        );
    };

    const weekChange = (weekCng, idx, week) => {
        setPjpData((prev) =>
            prev.map((item) =>
                item.id === idx
                    ? {
                          ...item,
                          week1: weekCng,
                          // eslint-disable-next-line no-nested-ternary
                          weekNo: weekCng
                              ? item?.weekNo?.length
                                  ? item.weekNo.concat(`, ${week}`)
                                  : `${week}`
                              : (item.weekNo || '')
                                    .split(', ')
                                    .filter((x) => x !== `${week}`)
                                    .join(', '),
                      }
                    : { ...item }
            )
        );
    };

    const dayPickerStr = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
    };

    // cm info change
    const cmChange = (cmId, idx, usersList) => {
        setPjpData((prev) =>
            prev.map((item) =>
                item.id === idx
                    ? {
                          ...item,
                          assignTo: usersList.find((x) => x.id === cmId),
                          assign: usersList.find((x) => x.id === cmId),
                      }
                    : { ...item }
            )
        );
    };

    async function fetchData() {
        try {
            const result = await getPjpDraftData({
                page: currentPage,
                limit: totalShowPage,
            }).unwrap();
            // eslint-disable-next-line no-underscore-dangle
            setPjpData(result?.data?.map((x) => ({ id: x._id, ...x })));
            setSelectedKeys(
                result?.data?.map((x) => (x.weekNo && x.serviceDay ? x._id : null)).filter((x) => x)
            );
        } catch (error) {
            message.error('Something went wrong');
        }
    }

    const [assignPjpData, { isLoading: assignLoading }] = useAssignPjpDataMutation();

    const savePjpDraft = async () => {
        confirmAlert({
            title: `Confirm to Save!`,
            message: 'You Must select the outlet from the left to save',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const result = await assignPjpData({
                                data: pjpData
                                    ?.filter((x) => x.checked)
                                    ?.map((x) => ({
                                        id: x.id,
                                        assignTo: x.assignTo?.name
                                            ? x.assignTo
                                            : x?.users?.find((y) => y.default === true),
                                        serviceDay:
                                            x.serviceDayStr || typeof x.serviceDay === 'number'
                                                ? dayPicker[x.serviceDay - 1]
                                                : x.serviceDay,
                                        weekNo: x.weekNo,
                                        assignmentType: 'Predraft',
                                        checked: true,
                                    })),
                            });
                            message.success('Task Complete');
                            fetchData();
                        } catch (error) {
                            message.error('Something went wrong');
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => message.warning('Cancelled!'),
                },
            ],
        });
    };

    const [submitDraft, { isLoading: draftSubmetting }] = useSubmitDraftMutation();

    const submitPjpMap = async () => {
        try {
            const result = await submitDraft();
            message.success('Task Complete');
            fetchData();
        } catch (error) {
            message.error('Something went wrong');
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPjpDraftData]);

    // render custom table tag
    function renderTableVal(val, subitem, code) {
        return (
            <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>
                {val}
                {subitem ? (
                    <span style={{ display: 'block', fontSize: '10px' }}>{code}</span>
                ) : null}
            </span>
        );
    }

    const dispatch = useDispatch();
    const { reFetchFilter, globalLoading } = useSelector((state) => state.globalLoading);
    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetPjpStatusFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // draft file download
    // download button
    async function downLoadingFile(doc) {
        const excName = doc?.headers.get('Content-Disposition').split('"')[1] || 'report.xlsx';
        const fResult = await doc.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const { accessToken } = useSelector((state) => state.auth);
    // download link function
    const [loading, setLoading] = useState(false);

    const download = async ({ url, fileName }) => {
        try {
            dispatch(setGlobalLoading(true));
            setLoading(true);
            // eslint-disable-next-line no-await-in-loop
            const result = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: ` Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    ...getBodyData(route, outletCode, channel, outletType),
                    ...getDataManagementFilterData({ circle, region, area, territory, town }),
                    // eslint-disable-next-line no-plusplus
                }),
                mode: 'cors',
            });

            const arr = [];

            arr.push(downLoadingFile(result));

            // eslint-disable-next-line no-await-in-loop
            await Promise.all(arr);
        } catch (error) {
            message.error('Something went wrong');
        } finally {
            dispatch(setGlobalLoading(false));
            setLoading(false);
        }
    };

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="PJP Mapping" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    downloadButton={download}
                    loading={isLoading || globalLoading}
                    queryFunc={fetPjpDraftData}
                    pathname="/pjp-mapping"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">PJP Mapping</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <Table
                            size="small"
                            rowKey="id"
                            loading={isLoading}
                            dataSource={pjpData?.map((x) => ({
                                ...x,
                                // eslint-disable-next-line no-underscore-dangle
                                id: x._id,
                                town: x?.town?.name,
                                townCode: x?.town?.towncode,
                                outletName: x?.outlet?.name,
                                outletType: x?.outlet?.outletType,
                                outletCode: x?.outlet?.outletcode,
                                slab: x?.outlet?.slab,
                                channel: x?.outlet?.channel,
                                cmName: x?.assignTo?.name,
                                users: x?.users,
                                assign: x?.assign,
                            }))}
                            scroll={{ x: 800, y: 500 }}
                            pagination={false}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                        >
                            <Column
                                title="Town"
                                dataIndex="town"
                                key="town"
                                render={(v, record) => renderTableVal(v, true, record.townCode)}
                                width="10%"
                            />
                            <Column
                                title="Outlet"
                                dataIndex="outletName"
                                key="outletName"
                                render={(v, record) => renderTableVal(v, true, record.outletCode)}
                                width="10%"
                            />
                            <Column
                                title="Channel"
                                dataIndex="channel"
                                key="channel"
                                render={(v) => renderTableVal(v)}
                                width="8%"
                            />
                            <Column
                                title="Outlet Type"
                                dataIndex="outletType"
                                key="outletType"
                                render={(e) => renderTableVal(e === 'BS' ? 'PS' : e)}
                                width="8%"
                            />
                            <Column
                                title="Slab"
                                dataIndex="slab"
                                key="slab"
                                render={(v) => renderTableVal(v)}
                                width="20%"
                            />
                            <Column
                                title="Merchandiser Name"
                                dataIndex="users"
                                width="15%"
                                render={(_, record) => (
                                    <Select
                                        allowClear
                                        placeholder="Select CM"
                                        size="small"
                                        style={{
                                            width: '100%',
                                        }}
                                        value={record?.assign?.id || null}
                                        onChange={(e) => cmChange(e, record.id, record.users)}
                                        options={record?.users?.map((x) => ({
                                            label: x.name,
                                            value: x.id,
                                        }))}
                                    />
                                )}
                            />
                            <Column
                                title="Service Day"
                                dataIndex="serviceDay"
                                key="serviceDay"
                                width="10%"
                                render={(val, record) => (
                                    <Select
                                        allowClear
                                        placeholder="Service Day"
                                        size="small"
                                        style={{
                                            width: '100%',
                                        }}
                                        defaultValue={
                                            typeof val === 'string'
                                                ? dayPickerStr[val]
                                                : dayPicker[val - 1]
                                        }
                                        onChange={(v) => changeServiceDay(v, record.id)}
                                        options={[
                                            {
                                                value: 6,
                                                label: 'Saturday',
                                            },
                                            {
                                                value: 0,
                                                label: 'Sunday',
                                            },
                                            {
                                                value: 1,
                                                label: 'Monday',
                                            },
                                            {
                                                value: 2,
                                                label: 'Tuesday',
                                            },
                                            {
                                                value: 3,
                                                label: 'Wednesday',
                                            },
                                            {
                                                value: 4,
                                                label: 'Thursday',
                                            },
                                            {
                                                value: 5,
                                                label: 'Friday',
                                            },
                                        ]}
                                    />
                                )}
                            />
                            <Column
                                title="W1"
                                dataIndex="week1"
                                key="week1"
                                render={(_, record) => (
                                    <Checkbox
                                        defaultChecked={
                                            record?.weekNo
                                                ? !!record.weekNo
                                                      .split(', ')
                                                      ?.find((x) => x === '1')
                                                : record.week1
                                        }
                                        onChange={(e) =>
                                            weekChange(e.target.checked, record.id, '1')
                                        }
                                    />
                                )}
                            />
                            <Column
                                title="W2"
                                dataIndex="week2"
                                key="week2"
                                render={(_, record) => (
                                    <Checkbox
                                        defaultChecked={
                                            record?.weekNo
                                                ? !!record.weekNo
                                                      .split(', ')
                                                      ?.find((x) => x === '2')
                                                : record.week2
                                        }
                                        onChange={(e) =>
                                            weekChange(e.target.checked, record.id, '2')
                                        }
                                    />
                                )}
                            />
                            <Column
                                title="W3"
                                dataIndex="week3"
                                key="week3"
                                render={(_, record) => (
                                    <Checkbox
                                        defaultChecked={
                                            record?.weekNo
                                                ? !!record.weekNo
                                                      .split(', ')
                                                      ?.find((x) => x === '3')
                                                : record.week3
                                        }
                                        onChange={(e) =>
                                            weekChange(e.target.checked, record.id, '3')
                                        }
                                    />
                                )}
                            />
                            <Column
                                title="W4"
                                dataIndex="week4"
                                key="week4"
                                render={(_, record) => (
                                    <Checkbox
                                        defaultChecked={
                                            record?.weekNo
                                                ? !!record.weekNo
                                                      .split(', ')
                                                      ?.find((x) => x === '4')
                                                : record.week4
                                        }
                                        onChange={(e) =>
                                            weekChange(e.target.checked, record.id, '4')
                                        }
                                    />
                                )}
                            />
                            <Column
                                title="W5"
                                dataIndex="week5"
                                key="week5"
                                render={(_, record) =>
                                    record.weekCount === 5 ||
                                    countSpecificDayInMonth(
                                        getYearCount(),
                                        String(getNextMonth()).padStart(2, '0'),
                                        dayPickerStr[
                                            typeof record.serviceDay === 'string'
                                                ? record.serviceDay
                                                : record.serviceDayStr
                                        ]
                                    ) === 5 ? (
                                        <Checkbox
                                            defaultChecked={
                                                record?.weekNo
                                                    ? !!record.weekNo
                                                          .split(', ')
                                                          ?.find((x) => x === '5')
                                                    : record.week5
                                            }
                                            onChange={(e) =>
                                                weekChange(e.target.checked, record.id, '5')
                                            }
                                        />
                                    ) : null
                                }
                            />
                        </Table>
                    )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px 0',
                    }}
                >
                    <Pagination
                        size="large"
                        pageSize={totalShowPage}
                        showSizeChanger
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={data?.meta.count}
                        onChange={onChange}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    />
                </div>

                <div style={{ textAlign: 'center', padding: '0 0 20px 0' }}>
                    <Space>
                        {/* <Popconfirm
                            title="PJP Save"
                            description="Are you sure to do it?"
                            // eslint-disable-next-line no-underscore-dangle
                            onConfirm={() => savePjpDraft()}
                            okText="Yes"
                            cancelText="No"
                            okType="danger"
                            okButtonProps={{
                                loading: assignLoading,
                            }}
                        >
                            <Button
                                loading={assignLoading}
                                disabled={assignLoading}
                                type="primary"
                                size="large"
                            >
                                Save
                            </Button>
                        </Popconfirm> */}
                        <Button
                            onClick={() => savePjpDraft()}
                            loading={assignLoading}
                            disabled={assignLoading}
                            type="primary"
                            size="large"
                        >
                            Save
                        </Button>
                        <Popconfirm
                            title="PJP Assign"
                            description="Are you sure to do it?"
                            // eslint-disable-next-line no-underscore-dangle
                            onConfirm={() => submitPjpMap()}
                            okText="Yes"
                            cancelText="No"
                            okType="danger"
                            okButtonProps={{
                                loading: draftSubmetting,
                            }}
                        >
                            <Button danger type="primary" size="large">
                                Submit
                            </Button>
                        </Popconfirm>
                    </Space>
                </div>
            </div>
        </>
    );
}

export default PjpMapping;
