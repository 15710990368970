/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Row } from 'antd';
import React from 'react';

import AiPosmListFilter from '../features/AiPosmList/AiPosmListFilter';
import AttendanceFilter from '../features/Attendance/AttendanceFilter';
// eslint-disable-next-line import/no-cycle
import BacklitFilter from '../features/BacklitAudit/BacklitFilter';
import DailyActivityReportFilter from '../features/DailyActivityReport/DailyActivityReportFilter';
import DashboardFilter from '../features/Dashboard/DashboardFilter';
import DffWholesaleDashboardFilter from '../features/DffWholesale/DffWholesaleDashboardFilter';
import NationalLevelFilter from '../features/DffWholesale/NationalLevelFilter';
import TerritoryLevelFilter from '../features/DffWholesale/TerritoryLevelFilter';
import WholesaleDownloadReportFilter from '../features/DffWholesale/WholesaleDownloadReportFilter';
import HrManagementFilters from '../features/HrManagement/HrManagementFilters';
import InterimFilter from '../features/Interim/InterimFilter';
import InterimQpdsFilter from '../features/Interim/InterimQpdsFilter';
import DamageAndLostFilter from '../features/Inventory/DamageAndLostFilter';
import TownPosmHistoryFilter from '../features/Inventory/TownPosmHistoryFilter';
import TownSummaryFilter from '../features/Inventory/TownSummaryFilter';
import UserHistoryFilter from '../features/Inventory/UserHistoryFilter';
import UserSummaryFilter from '../features/Inventory/UserSummaryFilter';
import LeaveManagementFilter from '../features/LeaveManagement/LeaveManagementFilter';
import LoginDetailsFilter from '../features/LoginDetails/LoginDetailsFilter';
import ReceiveMaterialFilter from '../features/MaterialManagement/ReceiveMaterialFilter';
import NoticeFilter from '../features/Notice/NoticeFilter';
import ApprovePjpFilter from '../features/PjpManagement/ApprovePjpFilter';
import PjpMappingFilter from '../features/PjpManagement/PjpMappingFilter';
import PjpStatusFilter from '../features/PjpManagement/PjpStatusFilter';
import ViewPosmListFilter from '../features/PosmManagement/ViewPosmListFilter';
import MTCMVisitCallFilter from '../features/Report/MTCMVisitCallFilter';
import MsCallFilter from '../features/Report/MsCallFilter';
import VisitCallFilter from '../features/Report/VisitCallFilter';
import RoutePlanTrackerFilter from '../features/RoutePlanTracker/RoutePlanTrackerFilter';
import SalaryEvaluationFilters from '../features/SharedSalaryModule/SalaryEvaluationFilters';
import TeamManagementFilter from '../features/TeamManagement/TeamManagementFilter';
import TrainingFilter from '../features/TrainingModule/TrainingFilter';
import DownloadFilter from '../features/downloadReport/DownloadFilter';
import DataManagementFilter from './DataManagementFilter';

// mapping of pathnames to components
const filterComponents = {
    '/': DashboardFilter,
    '/notice': NoticeFilter,
    '/training-module': TrainingFilter,
    '/leaveManagement': LeaveManagementFilter,
    '/attendance': AttendanceFilter,
    '/login': LoginDetailsFilter,
    '/routePlanTracker': RoutePlanTrackerFilter,
    '/teamManagement': TeamManagementFilter,
    '/ff-execution': VisitCallFilter,
    '/mtcm-execution': MTCMVisitCallFilter,
    '/ms-execution': MsCallFilter,
    '/town-posm-history': TownPosmHistoryFilter,
    '/inventory-damageAndLost': DamageAndLostFilter,
    '/inventory-townSummary': TownSummaryFilter,
    '/inventory-userHistory': UserHistoryFilter,
    '/inventory-userSummary': UserSummaryFilter,
    '/receive-material': ReceiveMaterialFilter,
    '/dailyActivityReport': DailyActivityReportFilter,
    '/aiPosmList': AiPosmListFilter,
    '/download-report': DownloadFilter,
    '/wholesale-download-report': WholesaleDownloadReportFilter,
    '/view-sovm-list': ViewPosmListFilter,
    '/pjp-status': PjpStatusFilter,
    '/pjp-mapping': PjpMappingFilter,
    '/pjp-approve': ApprovePjpFilter,
    '/interimReport': InterimFilter,
    '/interimQpdsReport': InterimQpdsFilter,
    '/backlitAudit': BacklitFilter,
    '/dffDashboardFilter': DffWholesaleDashboardFilter,
    '/national-level': NationalLevelFilter,
    '/territory-level': TerritoryLevelFilter,
    '/employee-database': HrManagementFilters,
    '/salary-status': SalaryEvaluationFilters,
    '/salary-disbursement': SalaryEvaluationFilters,
    '/salary-evolution': SalaryEvaluationFilters,
};

function Filter({ pathname, queryFunc, loading, selectAllDisable, downloadButton }) {
    // get the appropriate filter component based on the pathname
    const ActivePageFilter = filterComponents[pathname] || null;

    return (
        <Row gutter={[5, 5]}>
            {pathname !== '/aiPosmList' &&
                pathname !== '/view-sovm-list' &&
                pathname !== '/login' && (
                    <DataManagementFilter selectAllDisable={selectAllDisable} />
                )}
            {ActivePageFilter && (
                <ActivePageFilter
                    downloadButton={downloadButton}
                    loading={loading}
                    queryFunc={queryFunc}
                    pathname={pathname}
                />
            )}
        </Row>
    );
}

export default Filter;
