/* eslint-disable no-unsafe-optional-chaining */
import { Modal, message } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentState } from '../../redux/features/SharedSalaryModule/evaluationDataSlice';
import { usePostMerchandiserSalaryMutation } from '../../redux/features/SharedSalaryModule/salaryEvaluationsApi';
import BasicSalary from './BasicSalary';
import FinalSalaryCalculation from './FinalSalaryCalculation';
import PayableVariableSalary from './VariablePayTable';

export default function SalaryModal({ isModalOpen, setIsModalOpen, refetch }) {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState('salary');
    const [postEvaluation, postEvaluationOptions] = usePostMerchandiserSalaryMutation();

    const { evaLuationData, trAmount, tmAmount } = useSelector((state) => state?.currentEvaluation);

    const handleSubmit = async () => {
        try {
            const {
                _id,
                user,
                town,
                updatedAt,
                createdAt,
                deletedAt,
                evaluationType,
                totalPayableIncentive,
                totalPayableSalary,
                ...restData
            } = evaLuationData;

            const totalPayable =
                evaLuationData?.payableBaseSalary?.totalBaseSalary +
                evaLuationData?.payableTravelAllowance?.totalTravelAllowance +
                evaLuationData?.totalPayableIncentive +
                (trAmount + tmAmount);
            const payload = {
                _id,
                rest: {
                    ...restData,
                    totalPayableIncentive:
                        evaLuationData.totalPayableIncentive + trAmount + tmAmount,
                    totalPayableSalary: totalPayable,
                },
                evaluationType,
            };

            const res = await postEvaluation(payload);
            if (res?.data?.message) {
                message.success('Salary details submitted successfully');
                setIsModalOpen(false);
                refetch();
                setCurrentPage('salary');
                dispatch(resetCurrentState({}));
            }
        } catch (error) {
            message.error('Failed to submit salary details');
        }
    };

    const renderContent = () => {
        switch (currentPage) {
            case 'salary':
                return (
                    <BasicSalary
                        onNext={() => setCurrentPage('variable')}
                        onBack={() => {
                            setIsModalOpen(false);
                            setCurrentPage('salary');
                        }}
                        onClose={() => {
                            setIsModalOpen(false);
                            setCurrentPage('salary');
                        }}
                        data={evaLuationData}
                        isFirstPage
                    />
                );
            case 'variable':
                return (
                    <PayableVariableSalary
                        data={evaLuationData}
                        onNext={() => setCurrentPage('summary')}
                        onBack={() => setCurrentPage('salary')}
                    />
                );
            case 'summary':
                return (
                    <FinalSalaryCalculation
                        data={evaLuationData}
                        onBack={() => setCurrentPage('variable')}
                        onSubmit={handleSubmit}
                        isLastPage
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            open={isModalOpen}
            onCancel={() => {
                setIsModalOpen(false);
                setCurrentPage('salary');
                dispatch(resetCurrentState({}));
            }}
            onClose={() => {
                setIsModalOpen(false);
                setCurrentPage('salary');
                dispatch(resetCurrentState({}));
            }}
            footer={null}
            width={850}
            style={{ top: 20 }}
            bodyStyle={{ padding: 0 }}
            maskClosable={false}
        >
            {renderContent()}
        </Modal>
    );
}
