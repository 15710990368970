/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    evaLuationData: {},
    penaltyReason: {},
    monthYearSummary: {},
    updatedEvaluationData: {},
    variablePayTotal: 0,
    trAmount: 0,
    tmAmount: 0,
    tradeRelationship: {},
    teamManagement: {},
    initialBaseSalary: 0,
    initialTravelAllowance: 0,
    initialVariablePay: 0,
    initialTotal: 0,
};

const evaluationDataSlice = createSlice({
    name: 'evaluationDataSlice',
    initialState,
    reducers: {
        setVariablePayTotal: (state, action) => {
            state.variablePayTotal = action.payload;
        },

        setPenaltyDays: (state, action) => {
            state.evaLuationData.payableTravelAllowance = {
                ...state.evaLuationData.payableTravelAllowance,
                penaltyDays: action.payload.penaltyDays,
                totalTravelAllowance: action.payload.total,
            };
        },
        setAdditionalWorkdays: (state, action) => {
            state.evaLuationData.payableTravelAllowance = {
                ...state.evaLuationData.payableTravelAllowance,
                additionalWorkdays: action.payload.additionalWorkdays,
                totalTravelAllowance: action.payload.total,
            };
        },
        setEvaluationData: (state, action) => {
            state.evaLuationData = action.payload;
        },
        setBasicPenaltyData: (state, action) => {
            state.evaLuationData.payableBaseSalary = {
                ...state.evaLuationData.payableBaseSalary,
                penaltyCharge: action.payload.value,
                penaltyCause: action.payload.label,
                totalBaseSalary: action.payload.total,
            };
        },
        setTrAmount: (state, action) => {
            state.trAmount = action.payload.earned;

            const payableIncentive = state.evaLuationData && state.evaLuationData.payableIncentive;
            if (payableIncentive) {
                const resourceManagement = payableIncentive.find(
                    (item) =>
                        item.category.toLowerCase() ===
                        'Resource and relationship Management'.toLowerCase()
                );
                if (resourceManagement) {
                    const tradeRelationship = resourceManagement.assessmentCriteria.find(
                        (criteria) =>
                            criteria.title.toLowerCase() === 'Trade relationship'.toLowerCase()
                    );
                    if (tradeRelationship) {
                        tradeRelationship.achievement = action.payload.achievement;
                        tradeRelationship.earned = action.payload.earned;
                    }
                }
            }
        },

        setTmAmount: (state, action) => {
            state.tmAmount = action.payload.earned;

            const payableIncentive = state.evaLuationData && state.evaLuationData.payableIncentive;
            if (payableIncentive) {
                const resourceManagement = payableIncentive.find(
                    (item) =>
                        item.category.toLowerCase() ===
                        'Resource and relationship Management'.toLowerCase()
                );
                if (resourceManagement) {
                    const tradeManagement = resourceManagement.assessmentCriteria.find(
                        (criteria) =>
                            criteria.title.toLowerCase() === 'Team Management'.toLowerCase()
                    );
                    if (tradeManagement) {
                        tradeManagement.achievement = action.payload.achievement;
                        tradeManagement.earned = action.payload.earned;
                    }
                }
            }
        },

        setTradeRelationship: (state, action) => {
            state.tradeRelationship = action.payload;
        },

        setTeamManagement: (state, action) => {
            state.teamManagement = action.payload;
        },

        setPenaltyReason: (state, action) => {
            state.penaltyReason = action.payload;
        },
        resetBasicPenaltyData: (state, action) => {
            state.evaLuationData.payableBaseSalary = state.updatedEvaluationData.payableBaseSalary;
        },
        resetPenaltyReason: (state) => {
            state.penaltyReason = {};
        },
        resetCurrentState: (state) => {
            state.evaLuationData = {};
            state.penaltyReason = {};
            state.monthYearSummary = {};
            state.updatedEvaluationData = {};
            state.tradeRelationship = {};
            state.teamManagement = {};
            state.variablePayTotal = 0;
            state.trAmount = 0;
            state.tmAmount = 0;
            state.initialBaseSalary = 0;
            state.initialTravelAllowance = 0;
            state.initialVariablePay = 0;
            state.initialTotal = 0;
        },

        setInitialBaseSalary: (state, action) => {
            state.initialBaseSalary = action.payload;
        },
        setInitialTravelAllowance: (state, action) => {
            state.initialTravelAllowance = action.payload;
        },
        setInitialVariablePay: (state, action) => {
            state.initialVariablePay = action.payload;
        },
        setInitialTotal: (state, action) => {
            state.initialTotal = action.payload;
        },
    },
});

export default evaluationDataSlice.reducer;
export const {
    setEvaluationData,
    setBasicPenaltyData,
    resetBasicPenaltyData,
    setPenaltyReason,
    resetPenaltyReason,
    setMonthYearSummary,
    resetMonthYearSummary,
    setAllowanceWithPenalty,
    resetAllowanceWithPenalty,
    setTravelAllowance,
    resetTravelAllowance,
    resetAdditionalWorkdays,
    setAdditionalWorkdays,
    setPenaltyDays,
    resetPenaltyDays,
    setVariablePayTotal,
    resetCurrentState,
    setTrAmount,
    setTmAmount,
    setInitialBaseSalary,
    setInitialTravelAllowance,
    setInitialVariablePay,
    setInitialTotal,
    setTeamManagement,
    setTradeRelationship,
} = evaluationDataSlice.actions;
