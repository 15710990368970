/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

function ShareOfSachetChart({ data }) {
    const [shareByOwnerLabels, setShareByOwnerLabels] = useState([]);
    const [shareByOwnerValues, setShareByOwnerValues] = useState([]);
    const [totalOwnerValue, setTotalOwnerValue] = useState(0);

    const [hangerPresenceLabels, setHangerPresenceLabels] = useState([]);
    const [hangerPresenceValues, setHangerPresenceValues] = useState([]);
    const [totalHangerValue, setTotalHangerValue] = useState(0);

    useEffect(() => {
        if (data) {
            const ownerValues = data?.shareByOwner?.map((doc) => doc.total) || [];
            const hangerValues = data?.hangerPresence?.map((doc) => doc.total) || [];

            setShareByOwnerLabels(data?.shareByOwner?.map((doc) => doc._id) || []);
            setShareByOwnerValues(ownerValues);
            setTotalOwnerValue(ownerValues.reduce((acc, val) => acc + val, 0));

            setHangerPresenceLabels(data?.hangerPresence?.map((doc) => doc._id) || []);
            setHangerPresenceValues(hangerValues);
            setTotalHangerValue(hangerValues.reduce((acc, val) => acc + val, 0));
        }
    }, [data]);

    const chartOptions1 = {
        chart: {
            type: 'donut',
            height: '100%',
            width: '100%',
        },
        colors: [
            '#4F81BD',
            '#82CA9D',
            '#5C6BC0',
            '#29B6F6',
            '#FFA726',
            '#FF7043',
            '#FF8A65',
            '#FFAB91',
            '#FFCCBC',
            '#F8BBD0',
            '#E1BEE7',
            '#D1C4E9',
            '#B3E5FC',
            '#B2EBF2',
            '#B2DFDB',
            '#C8E6C9',
            '#DCEDC8',
            '#F0F4C3',
            '#FFF9C4',
            '#FFECB3',
            '#FFE0B2',
            '#FFCCBC',
            '#FFAB91',
            '#FF8A65',
            '#FF7043',
            '#FFA726',
            '#29B6F6',
            '#5C6BC0',
            '#82CA9D',
            '#4F81BD',
        ],
        dataLabels: { enabled: false },
        tooltip: {
            y: {
                formatter: (val) =>
                    totalOwnerValue > 0
                        ? `${((val / totalOwnerValue) * 100).toFixed(1)}% (${val})`
                        : `0% (${val})`,
            },
        },
        legend: {
            position: 'right',
            horizontalAlign: 'center',
        },
    };

    const chartOptions2 = {
        chart: {
            type: 'donut',
            height: '100%',
            width: '100%',
        },
        colors: [
            '#4F81BD',
            '#82CA9D',
            '#5C6BC0',
            '#29B6F6',
            '#FFA726',
            '#FF7043',
            '#FF8A65',
            '#FFAB91',
            '#FFCCBC',
            '#F8BBD0',
            '#E1BEE7',
            '#D1C4E9',
            '#B3E5FC',
            '#B2EBF2',
            '#B2DFDB',
            '#C8E6C9',
            '#DCEDC8',
            '#F0F4C3',
            '#FFF9C4',
            '#FFECB3',
            '#FFE0B2',
            '#FFCCBC',
            '#FFAB91',
            '#FF8A65',
            '#FF7043',
            '#FFA726',
            '#29B6F6',
            '#5C6BC0',
            '#82CA9D',
            '#4F81BD',
        ],
        dataLabels: { enabled: false },
        tooltip: {
            y: {
                formatter: (val) =>
                    totalHangerValue > 0
                        ? `${((val / totalHangerValue) * 100).toFixed(1)}% (${val})`
                        : `0% (${val})`,
            },
        },
        legend: {
            position: 'right',
            horizontalAlign: 'center',
        },
    };

    return (
        <div
            className="box-container"
            style={{
                height: '260px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <div className="box-heading box-heading-dash" style={{ position: 'absolute' }}>
                Share Of Sachet
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    width: '100%',
                    padding: '35px 12px 12px 12px',
                    position: 'relative',
                    boxSizing: 'border-box',
                }}
            >
                {/* Separator line */}
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        height: 'calc(100% - 50px)',
                        borderLeft: '1px solid #ddd',
                        zIndex: 1,
                    }}
                />

                <div
                    style={{
                        flex: 1,
                        height: '100%',
                        position: 'relative',
                        minWidth: 0, // Fix for flex item overflow
                    }}
                >
                    {shareByOwnerValues.length > 0 && (
                        <>
                            <Chart
                                options={{ ...chartOptions1, labels: shareByOwnerLabels }}
                                series={shareByOwnerValues}
                                type="donut"
                                height="100%"
                                width="100%"
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '32%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                    pointerEvents: 'none',
                                    width: '80%',
                                }}
                            >
                                Share of Sachet
                                <br />
                                by Owner
                            </div>
                        </>
                    )}
                </div>

                <div
                    style={{
                        flex: 1,
                        height: '100%',
                        position: 'relative',
                        minWidth: 0, // Fix for flex item overflow
                    }}
                >
                    {hangerPresenceValues.length > 0 && (
                        <>
                            <Chart
                                options={{ ...chartOptions2, labels: hangerPresenceLabels }}
                                series={hangerPresenceValues}
                                type="donut"
                                height="100%"
                                width="100%"
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '42%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                    pointerEvents: 'none',
                                    width: '80%',
                                }}
                            >
                                Hanger
                                <br />
                                Presence
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ShareOfSachetChart;
