import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../components/DateRange';
import { useGetPosmListQuery } from '../../redux/features/inventory/inventoryApiSlice';
import {
    setCallType,
    setDateRange,
    setFFName,
    setMsCallType,
    setMsId,
    setOutletCode,
    setOutletStatusByAi,
} from '../../redux/features/report/msCallFilterSlice';
import { useSearchEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';

function MsCallFilter({ queryFunc, loading }) {
    const { ffLevel, outletCode } = useSelector((state) => state.msCallFilter);

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    const dispatch = useDispatch();

    // get material info
    // get material name
    const { data, isLoading: materialGetLoading } = useGetPosmListQuery();

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading }] = useSearchEmployeeMutation();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    const getFilterData = (lev) => {
        const bodyData = {};
        if (lev) {
            bodyData.type = lev;
        }
        return bodyData;
    };

    useEffect(() => {
        searchEmployee({
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            ...getFilterData(ffLevel),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchEmployee, circle, region, area, territory, town, ffLevel]);

    // ff search
    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    // ms search
    const [msSearch, setMsSearch] = useState('');
    const onMsSearch = (e) => {
        setMsSearch(e);
    };

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);

    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    loading={isLoading}
                    allowClear
                    showSearch
                    placeholder="MS Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setMsId(e))}
                    options={employeeData?.data
                        ?.filter((x) => x.kind === 'MS')
                        ?.map((emp) => ({
                            label: emp.name,
                            // eslint-disable-next-line no-underscore-dangle
                            value: emp._id,
                        }))}
                    filterOption={(input, option) =>
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    searchValue={msSearch}
                    onSearch={onMsSearch}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    loading={isLoading}
                    allowClear
                    showSearch
                    placeholder="FF Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setFFName(e))}
                    options={employeeData?.data
                        ?.filter((x) => x.kind !== 'MS')
                        .map((emp) => ({
                            label: emp.name,
                            // eslint-disable-next-line no-underscore-dangle
                            value: emp._id,
                        }))}
                    filterOption={(input, option) =>
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    searchValue={search}
                    onSearch={onSearch}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Visit Call Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setCallType(e))}
                    options={[
                        {
                            value: 'Schedule Covered',
                            label: 'Schedule Covered',
                        },
                        {
                            value: 'Pending Covered',
                            label: 'Pending Covered',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Call Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setMsCallType(e))}
                    options={[
                        {
                            value: 'Joint Call',
                            label: 'Joint Call',
                        },
                        {
                            value: 'Control Call',
                            label: 'Control Call',
                        },
                    ]}
                />
            </Col>

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="AI Satisfactory"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setAiSatisfactory(e))}
                    options={[
                        {
                            value: 'yes',
                            label: 'Yes',
                        },
                        {
                            value: 'no',
                            label: 'No',
                        },
                    ]}
                />
            </Col> */}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Status for Display"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOutletStatusByAi(e))}
                    options={[
                        {
                            value: 'passed',
                            label: 'Passed',
                        },
                        {
                            value: 'failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col>

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Status by MS"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOutletStatusByMs(e))}
                    options={[
                        {
                            value: 'passed',
                            label: 'Passed',
                        },
                        {
                            value: 'failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col> */}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={outletCode}
                    placeholder="Outlet Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setOutletCode(e.target.value))}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default MsCallFilter;
