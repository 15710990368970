/* eslint-disable react/no-array-index-key */
import { Card, Segmented } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVariablePayTotal } from '../../redux/features/SharedSalaryModule/evaluationDataSlice';
import EmployeeHeader from './EmployeeHeader';
import NavigationButtons from './NavigationButtons';
import { styles } from './styles';
import VariablePayCalculate from './VariablePayCalculate';

// Payable Variable Salary Component
function PayableVariableSalary({ data, onNext, onBack, isFirstPage }) {
    const dispatch = useDispatch();
    const {
        payableIncentive,
        variablePayEligibility,
        totalPayableIncentive,
        totalPayableSalary,
        strikeRate,
        actualCapturedShelfOutlets,
        scheduledOutletVisits,
    } = useSelector((state) => state?.currentEvaluation?.evaLuationData);
    const { variablePayTotal } = useSelector((state) => state?.currentEvaluation);

    const handleVariablePayEligibility = (value) => {
        const totalIncentive = payableIncentive?.reduce(
            (acc, curr) => acc + curr.totalPayableAmount,
            0
        );
        if (value) {
            const total = totalPayableSalary + totalIncentive;
            dispatch(setVariablePayTotal(totalIncentive));
            // dispatch(setTotalVariablePay(total));
        } else {
            const total = totalPayableSalary - totalIncentive;
            dispatch(setVariablePayTotal(-totalIncentive));
            // dispatch(setTotalVariablePay(total));
        }
    };

    if (!data) return null;

    return (
        <div style={styles.container}>
            <Card style={styles.card}>
                <EmployeeHeader data={data} />
                <h3 style={{ textAlign: 'center', fontSize: '25px', fontWeight: 'bold' }}>
                    Variable Pay Salary
                </h3>

                <div style={{ marginBottom: '20px' }}>
                    <div>
                        <h4 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '-6px' }}>
                            Variable Pay
                        </h4>
                    </div>
                    <hr />
                    <div style={styles.row}>
                        <span>Schedule Outlet Visit in a Month</span>
                        <span>{scheduledOutletVisits}</span>
                    </div>
                    <div style={styles.row}>
                        <span>Actual Shelf Captured Outlet in a Month</span>
                        <span>{actualCapturedShelfOutlets}</span>
                    </div>
                    <div style={styles.row}>
                        <span>Strike Rate</span>
                        <span>{strikeRate}%</span>
                    </div>
                    <div style={styles.row}>
                        <span>Variable Pay Eligibility</span>
                        <Segmented
                            options={[
                                { label: 'NO', value: false },
                                { label: 'YES', value: true },
                            ]}
                            defaultValue={variablePayEligibility}
                            className="salary__evaluation"
                            onChange={(value) => handleVariablePayEligibility(value)}
                        />
                    </div>
                </div>
                <hr />

                {payableIncentive?.map(
                    (
                        {
                            category,
                            totalPayableAmount,
                            assessmentCriteria,
                            allocatedAmount,
                            sharePercentage,
                        },
                        index
                    ) => (
                        <VariablePayCalculate
                            key={`${category}-${index}`}
                            title={category}
                            percentage={sharePercentage}
                            allocatedAmount={allocatedAmount}
                            data={assessmentCriteria}
                            totalPayableAmount={totalPayableAmount}
                        />
                    )
                )}

                <NavigationButtons onBack={onBack} onNext={onNext} isFirstPage={isFirstPage} />
            </Card>
        </div>
    );
}

export default PayableVariableSalary;

// /* eslint-disable react/jsx-props-no-spreading */
// /* eslint-disable react/button-has-type */
// /* eslint-disable react/no-array-index-key */
// import { Card, Segmented, Select } from 'antd';
// import React, { memo } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//     setTmAmount,
//     setTotalVariablePay,
//     setTrAmount,
//     setVariablePayEligibilityTotal,
// } from '../../../redux/features/salaryEvaluation/finalSalaryCalculationSlice';

// // Constants
// const RATING_OPTIONS = [
//     { label: 'Poor', value: 0 },
//     { label: 'Average', value: 33.33 },
//     { label: 'Good', value: 66.67 },
//     { label: 'Excellent', value: 100 },
// ];

// const KPI_CRITERIA = {
//     TRADE_RELATIONSHIP: 'Trade relationship',
//     TEAM_MANAGEMENT: 'Team Management',
// };

// const KPI_BASE_AMOUNT = 470;

// // Styles
// const styles = {
//     container: {
//         // ... your container styles
//     },
//     card: {
//         // ... your card styles
//     },
//     table: {
//         width: '100%',
//         borderCollapse: 'collapse',
//         marginTop: '10px',
//     },
//     cell: {
//         padding: '12px',
//         border: '1px solid #f0f0f0',
//     },
//     headerCell: {
//         fontWeight: 'bold',
//         textAlign: 'center',
//     },
//     title: {
//         fontSize: '20px',
//         fontWeight: 'bold',
//         marginBottom: '-6px',
//     },
//     totalRow: {
//         fontSize: '20px',
//         fontWeight: 'bold',
//     },
//     eligibilityContainer: {
//         display: 'flex',
//         gap: '8px',
//         alignItems: 'center',
//     },
//     row: {
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         marginBottom: '10px',
//     },
// };

// // Reusable Components
// const TableHeader = memo(() => (
//     <thead>
//         <tr>
//             <th style={styles.cell}>Criteria</th>
//             <th style={{ ...styles.cell, ...styles.headerCell }}>Achievement</th>
//             <th style={{ ...styles.cell, ...styles.headerCell }}>Earned Amount</th>
//         </tr>
//     </thead>
// ));

// const KpiSelect = memo(({ onSelect, onClear, field }) => (
//     <Select
//         allowClear
//         style={{ width: 200 }}
//         placeholder="Select Rating"
//         options={RATING_OPTIONS}
//         onSelect={(value) => onSelect(value, field)}
//         onClear={() => onClear(field)}
//     />
// ));

// const TableRow = memo(({ criteria, achievement, earned, isKpiRow, kpiProps }) => {
//     const cellStyle = {
//         ...styles.cell,
//         color: achievement < 100 ? '#ff4d4f' : 'inherit',
//         textAlign: 'center',
//     };

//     return (
//         <tr>
//             <td style={styles.cell}>{criteria}</td>
//             <td style={cellStyle}>{isKpiRow ? <KpiSelect {...kpiProps} /> : `${achievement}%`}</td>
//             <td style={{ ...styles.cell, textAlign: 'center' }}>
//                 {isKpiRow ? kpiProps.amount : earned}
//             </td>
//         </tr>
//     );
// });

// const TotalRow = memo(({ totalPayableAmount }) => (
//     <tr style={styles.totalRow}>
//         <td style={{ ...styles.cell, fontWeight: 'bold' }}>Total</td>
//         <td style={styles.cell} />
//         <td style={{ ...styles.cell, textAlign: 'center', fontWeight: 'bold' }}>
//             {totalPayableAmount} Tk
//         </td>
//     </tr>
// ));

// const VariablePayTable = memo(
//     ({ title, percentage, totalPayableAmount, allocatedAmount, data }) => {
//         const dispatch = useDispatch();
//         const { trAmount, tmAmount } = useSelector((state) => state?.finalSalaryCalculation);

//         const handleKpiCalculation = (value, field) => {
//             if (value === null) {
//                 dispatch(field === 'tr' ? setTrAmount(0) : setTmAmount(0));
//                 return;
//             }

//             const kpiAmount = Math.round((value / 100) * KPI_BASE_AMOUNT);
//             dispatch(field === 'tr' ? setTrAmount(kpiAmount) : setTmAmount(kpiAmount));
//         };

//         const renderTableRow = (item, index) => {
//             const { earned, achievement, title: criteria } = item;
//             const isTradeRelationship =
//                 criteria?.toLowerCase() === KPI_CRITERIA.TRADE_RELATIONSHIP.toLowerCase();
//             const isTeamManagement =
//                 criteria?.toLowerCase() === KPI_CRITERIA.TEAM_MANAGEMENT.toLowerCase();

//             if (isTradeRelationship || isTeamManagement) {
//                 const field = isTradeRelationship ? 'tr' : 'tm';
//                 const amount = isTradeRelationship ? trAmount : tmAmount;

//                 return (
//                     <TableRow
//                         key={`${criteria}-${index}`}
//                         criteria={criteria}
//                         achievement={achievement}
//                         isKpiRow
//                         kpiProps={{
//                             field,
//                             amount,
//                             onSelect: handleKpiCalculation,
//                             onClear: () => handleKpiCalculation(null, field),
//                         }}
//                     />
//                 );
//             }

//             return (
//                 <TableRow
//                     key={`${criteria}-${index}`}
//                     criteria={criteria}
//                     achievement={achievement}
//                     earned={earned}
//                 />
//             );
//         };

//         return (
//             <div style={{ marginBottom: '20px' }}>
//                 <h4 style={styles.title}>
//                     {title} ({percentage}%)
//                     <span style={{ float: 'right' }}>Amount {allocatedAmount} Tk</span>
//                 </h4>
//                 <table style={styles.table}>
//                     <TableHeader />
//                     <tbody>
//                         {data.map(renderTableRow)}
//                         <TotalRow totalPayableAmount={totalPayableAmount} />
//                     </tbody>
//                 </table>
//             </div>
//         );
//     }
// );

// const VariablePayInfo = memo(({ data, onVariablePayEligibilityChange }) => {
//     const {
//         scheduledOutletVisits,
//         actualCapturedShelfOutlets,
//         strikeRate,
//         variablePayEligibility,
//     } = data;

//     return (
//         <div style={{ marginBottom: '20px' }}>
//             <h4 style={styles.title}>Variable Pay</h4>
//             <hr />
//             <div style={styles.row}>
//                 <span>Schedule Outlet Visit in a Month</span>
//                 <span>{scheduledOutletVisits}</span>
//             </div>
//             <div style={styles.row}>
//                 <span>Actual Shelf Captured Outlet in a Month</span>
//                 <span>{actualCapturedShelfOutlets}</span>
//             </div>
//             <div style={styles.row}>
//                 <span>Strike Rate</span>
//                 <span>{strikeRate}%</span>
//             </div>
//             <div style={styles.row}>
//                 <span>Variable Pay Eligibility</span>
//                 <Segmented
//                     options={[
//                         { label: 'NO', value: false },
//                         { label: 'YES', value: true },
//                     ]}
//                     defaultValue={variablePayEligibility}
//                     style={{ color: 'white', backgroundColor: 'green' }}
//                     onChange={onVariablePayEligibilityChange}
//                 />
//             </div>
//         </div>
//     );
// });

// // Main Component
// function PayableVariableSalary({ data, onNext, onBack, isFirstPage }) {
//     const dispatch = useDispatch();

//     const handleVariablePayEligibility = (value) => {
//         const totalIncentive =
//             data?.payableIncentive?.reduce((acc, curr) => acc + curr.totalPayableAmount, 0) || 0;

//         const incentiveAmount = value ? totalIncentive : -totalIncentive;
//         const total = data.totalPayableSalary + (value ? totalIncentive : -totalIncentive);

//         dispatch(setVariablePayEligibilityTotal(incentiveAmount));
//         dispatch(setTotalVariablePay(total));
//     };

//     if (!data) return null;

//     return (
//         <div style={styles.container}>
//             <Card style={styles.card}>
//                 <h3 style={{ textAlign: 'center', fontSize: '25px', fontWeight: 'bold' }}>
//                     Variable Pay Salary
//                 </h3>

//                 <VariablePayInfo
//                     data={data}
//                     onVariablePayEligibilityChange={handleVariablePayEligibility}
//                 />

//                 <hr />

//                 {data?.payableIncentive?.map((incentive, index) => (
//                     <VariablePayTable
//                         key={`${incentive.category}-${index}`}
//                         title={incentive.category}
//                         percentage={incentive.sharePercentage}
//                         allocatedAmount={incentive.allocatedAmount}
//                         data={incentive.assessmentCriteria}
//                         totalPayableAmount={incentive.totalPayableAmount}
//                     />
//                 ))}

//                 <div style={styles.row}>
//                     <button onClick={onBack} disabled={isFirstPage}>
//                         Back
//                     </button>
//                     <button onClick={onNext}>Next</button>
//                 </div>
//             </Card>
//         </div>
//     );
// }

// export default memo(PayableVariableSalary);
