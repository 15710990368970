const sovmMaterialTypes = [
    { label: 'Shelf In Shelf', value: 'Shelf In Shelf' },
    { label: 'Wobbler', value: 'Wobbler' },
    { label: 'Brochure', value: 'Brochure' },
    { label: 'Poster', value: 'Poster' },
    { label: 'Hanger', value: 'Hanger' },
    { label: 'Shelf Talker', value: 'Shelf Talker' },
    { label: 'Sticker', value: 'Sticker' },
    { label: 'Bunting', value: 'Bunting' },
    { label: 'Trade Letter', value: 'Trade Letter' },
    { label: 'Shelf Marker', value: 'Shelf Marker' },
    { label: 'Dengler', value: 'Dengler' },
    { label: 'Shop Identifier', value: 'Shop Identifier' },
    { label: 'Sunshade Banner', value: 'Sunshade Banner' },
    { label: 'Shutter Sticker', value: 'Shutter Sticker' },
    { label: 'Table Poster', value: 'Table Poster' },
    { label: 'Shelf Box', value: 'Shelf Box' },
    { label: 'Price Board', value: 'Price Board' },
    { label: 'PLACARD', value: 'PLACARD' },
    { label: 'SHOP FIN', value: 'SHOP FIN' },
];

export default sovmMaterialTypes;
