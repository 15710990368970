/* eslint-disable import/prefer-default-export */
// Common styles used across salary components
export const styles = {
    container: {
        // minHeight: '100vh',
        // backgroundColor: '#f0f2f5',
        // padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        width: '800px',
        // borderRadius: '12px',
        position: 'relative',
        // padding: '24px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        borderBottom: '1px solid #e8e8e8',
        // paddingBottom: '16px',
    },
    closeButton: {
        position: 'absolute',
        right: '16px',
        top: '16px',
        cursor: 'pointer',
        backgroundColor: '#ff4d4f',
        color: 'white',
        borderRadius: '50%',
        padding: '4px',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px',
        alignItems: 'center',
    },
    section: {
        marginBottom: '24px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '24px',
    },
    table: {
        width: '100%',
        marginBottom: '20px',
        borderCollapse: 'collapse',
        borderStyle: 'solid',
        border: '1px solid #D3D3D3',
        textAlign: 'start',
    },
    cell: {
        padding: '8px',
        borderBottom: '1px solid #D3D3D3',
        textAlign: 'start',
    },
    totalRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '16px',
        padding: '16px 0',
        borderTop: '2px solid #1890ff',
        borderBottom: '2px solid #1890ff',
        fontWeight: 'bold',
        fontSize: '18px',
    },
};
