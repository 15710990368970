/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { Button, Col, Form, Input, message, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ublLogo from '../assets/app_logo.png';
import HelmetHeader from '../components/HelmetHeader';
import routeData from '../data/routeData';
import {
    useForgotPasswordMutation,
    useLoginMutation,
    useRequestNewAccountMutation,
    useResetPasswordMutation,
} from '../redux/features/auth/authApi';

const { TextArea } = Input;
const { Title } = Typography;

function Login() {
    const [form] = Form.useForm();
    const [userId, setUserId] = useState(null);
    const [login, { data, isLoading, error: responseError }] = useLoginMutation();
    const [resetPassword, { data: resetData, isLoading: resetLoading, error: resetError }] =
        useResetPasswordMutation();
    const [createNewAccount, { data: newAccData, isLoading: newAccLoading, error: newAccError }] =
        useRequestNewAccountMutation();

    const [forgotPassword, { isLoading: forgotLoading, error: forgotError }] =
        useForgotPasswordMutation();
    const [errorMessage, setErrorMessage] = useState('');
    const [action, setAction] = useState('signIn');

    const [geoLocation, setGeoLocation] = useState({ lat: null, lon: null });

    const navigate = useNavigate();

    const [isPassReset, setIsPassReset] = useState(false);
    const [isPrivileged, setIsPrivileged] = useState(false);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setGeoLocation({
                        lat: position.coords.latitude,
                        lon: position.coords.longitude,
                    });
                },
                (error) => {
                    message.error('Geolocation is not enabled. Please enable to continue.');
                }
            );
        } else {
            message.error('Geolocation is not supported by this browser.');
        }
    }, []);

    const handleResetPassword = async (values) => {
        if (!geoLocation.lat || !geoLocation.lon) {
            message.error('Unable to retrieve location. Please try again.');
        }

        if (values?.password !== values?.confirmPass) {
            message.error('Password does not match');
            return;
        }

        const payloadData = {
            userId,
            newPassword: values?.password,
        };

        const res = await resetPassword(payloadData);

        if (res?.data) {
            message.success(res?.data?.message || 'Successfully reset password');
            setAction('signIn');
            form.resetFields();
        } else {
            message.error('Something went wrong');
        }
    };

    const onLogin = async (values) => {
        if (!geoLocation.lat || !geoLocation.lon) {
            message.error('Unable to retrieve location. Please try again.');
            return;
        }

        setErrorMessage('');
        const res = await login({ ...values, ...geoLocation, loggedOn: 'Web' });

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { payload, access_token } = res?.data?.data || {};

        if (payload?.requireNewPassword && payload?.id) {
            setAction('resetPass');
            setUserId(payload?.id);
            form.resetFields();
        } else if (!payload?.requireNewPassword && access_token) {
            if (res?.error?.message) {
                setErrorMessage(res?.error?.message);
            }
            if (errorMessage) {
                message?.error(errorMessage || 'Something went wrong');
            }

            if (access_token && payload) {
                const findRoute = routeData.find(
                    (item) => item.name === payload?.landingPage?.label
                );
                setAction('signIn');
                navigate(findRoute.path);
                window.location.reload();
            }
        } else {
            message.error(
                res?.error?.message || res?.error?.data?.message || 'Something went wrong'
            );
            setErrorMessage(
                res?.error?.message || res?.error?.data?.message || 'Something went wrong'
            );
        }
    };

    const onNewAccRequest = async (values) => {
        const payload = {
            ...values,
            username: 'N/A',
        };
        const res = await createNewAccount(payload);

        if (res?.data?.data) {
            message.success('New account request sent successfully');
            form.resetFields();
            setAction('signIn');
        } else {
            message.error('Something went wrong. Please try again');
        }
    };

    const handleForgotPassword = async ({ email }) => {
        const res = await forgotPassword({ email });

        if (res?.data) {
            message.success(res?.data?.message || 'New account request sent successfully');
            form.resetFields();
            setAction('signIn');
        } else {
            message.error(res?.error?.data?.message || 'Something went wrong. Please try again');
        }
    };

    return (
        <>
            <HelmetHeader title="Login" />

            <Row>
                <Col span={12} className="login-left-site-container">
                    <div className="login-left-site">
                        <div style={{ textAlign: 'center' }}>
                            <p className="login-semi-title">Nice to see you again</p>
                            <h1 className="login-title">WELCOME BACK</h1>
                            <div
                                style={{
                                    width: '40px',
                                    height: '5px',
                                    background: 'white',
                                    margin: '0 auto',
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="login-form-container">
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: false,
                            }}
                            // onFinish={onFinish}
                            form={form}
                        >
                            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                                <img alt="ubl logo" src={ublLogo} width={150} />
                                <Title
                                    level={2}
                                    style={{
                                        color: '#213CB3',
                                        marginTop: '10px',
                                    }}
                                >
                                    {action === 'signIn'
                                        ? 'Sign In to Your Account '
                                        : action === 'forgotPass'
                                          ? 'Forgot Password'
                                          : action === 'newAcc' && 'New Account Details'}
                                </Title>
                            </div>
                            {action === 'newAcc' ? (
                                <>
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Name!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="login-input-field-customize"
                                            placeholder="Name"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Email!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="login-input-field-customize"
                                            type="email"
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="territory"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Territory Name!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="login-input-field-customize"
                                            placeholder="Territory Name"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="note"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Note!',
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            className="login-input-field-customize"
                                            placeholder="Note"
                                        />
                                    </Form.Item>
                                </>
                            ) : action === 'forgotPass' ? (
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Email!',
                                        },
                                    ]}
                                >
                                    <Input
                                        className="login-input-field-customize"
                                        type="email"
                                        placeholder="Email"
                                    />
                                </Form.Item>
                            ) : (
                                <>
                                    {action !== 'resetPass' && (
                                        <Form.Item
                                            name="username"
                                            rules={[
                                                {
                                                    required: action === 'signIn',
                                                    message: 'Please input your Username!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="login-input-field-customize"
                                                placeholder="Username"
                                            />
                                        </Form.Item>
                                    )}
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Password!',
                                            },
                                            {
                                                max: 64,
                                                message: 'Maximum 64 characters',
                                            },
                                            {
                                                pattern:
                                                    action === 'resetPass'
                                                        ? /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{7,64})\S$/gm
                                                        : /.*/,
                                                message: `Minimum 8 and maximum 64 characters, at least one uppercase letter, one lowercase letter, one number and one special character`,
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            className="login-input-field-customize"
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                    {action === 'resetPass' && (
                                        <Form.Item
                                            name="confirmPass"
                                            dependencies={['password']}
                                            rules={[
                                                {
                                                    required: action === 'resetPass',
                                                    message: 'Please re-type your Password!',
                                                },
                                                {
                                                    max: 64,
                                                    message: 'Maximum 64 characters',
                                                },
                                                {
                                                    pattern:
                                                        /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{7,64})\S$/gm,
                                                    message: `Minimum 8 and maximum 64 characters, at least one uppercase letter, one lowercase letter, one number and one special character`,
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (
                                                            value &&
                                                            value !== form.getFieldValue('password')
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'The two passwords that you entered do not match!'
                                                                )
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password
                                                className="login-input-field-customize"
                                                placeholder="Confirm Password"
                                            />
                                        </Form.Item>
                                    )}
                                </>
                            )}

                            <Form.Item>
                                <Button
                                    loading={isLoading}
                                    disabled={newAccLoading || resetLoading}
                                    style={{
                                        width: '100%',
                                        borderRadius: '30px',
                                        backgroundColor:
                                            action === 'signIn' || action === 'resetPass'
                                                ? '#1777FF'
                                                : '#fff',
                                        color:
                                            action === 'signIn' || action === 'resetPass'
                                                ? '#fff'
                                                : '#000',
                                        transition: 'background-color 0.5s ease, color 0.5s ease',
                                    }}
                                    size="large"
                                    onClick={() => {
                                        if (action === 'signIn') {
                                            form.validateFields()
                                                .then((values) => {
                                                    onLogin(values);
                                                })
                                                .catch((error) => {
                                                    // message.error(
                                                    //     'Please fill in all the required fields!'
                                                    // );
                                                });
                                        } else if (action === 'resetPass') {
                                            form.validateFields()
                                                .then((values) => {
                                                    handleResetPassword(values);
                                                })
                                                .catch((error) => {
                                                    // message.error(
                                                    //     'Please fill in all the required fields!'
                                                    // );
                                                });
                                        } else {
                                            form.resetFields();
                                            setAction('signIn');
                                        }
                                    }}
                                >
                                    {{
                                        signIn: 'Sign In',
                                        resetPass: 'Reset Password',
                                    }[action] || 'Try To Sign In'}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    loading={newAccLoading}
                                    disabled={isLoading || resetLoading}
                                    style={{
                                        width: '100%',
                                        borderRadius: '30px',
                                        backgroundColor: action === 'newAcc' ? '#1777FF' : '#fff',
                                        color: action === 'newAcc' ? '#fff' : '#000',
                                        transition: 'background-color 0.5s ease, color 0.5s ease',
                                    }}
                                    size="large"
                                    onClick={() => {
                                        if (action === 'newAcc') {
                                            form.validateFields()
                                                .then((values) => {
                                                    onNewAccRequest(values);
                                                })
                                                .catch((error) => {
                                                    // message.error(
                                                    //     'Please fill in all the required fields!'
                                                    // );
                                                });
                                        } else {
                                            form.resetFields();
                                            setAction('newAcc');
                                        }
                                    }}
                                >
                                    {action === 'newAcc'
                                        ? 'Submit Account Request'
                                        : 'Request For A New Account'}
                                </Button>
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    size="large"
                                    loading={resetLoading}
                                    disabled={isLoading || newAccLoading}
                                    style={{
                                        width: '100%',
                                        borderRadius: '30px',
                                        backgroundColor:
                                            action === 'forgotPass' ? '#1777FF' : '#fff',
                                        color: action === 'forgotPass' ? '#fff' : '#000',
                                        transition: 'background-color 0.5s ease, color 0.5s ease',
                                    }}
                                    onClick={() => {
                                        if (action !== 'forgotPass') {
                                            setAction('forgotPass');
                                        } else {
                                            form.validateFields()
                                                .then((values) => {
                                                    handleForgotPassword(values);
                                                })
                                                .catch((error) => {
                                                    // message.error(
                                                    //     'Please fill in all the required fields!'
                                                    // );
                                                });
                                        }
                                    }}
                                >
                                    {action === 'forgotPass' ? 'Submit' : 'Forgot Password?'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Login;
