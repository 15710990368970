/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { resetDataManagementFilter } from '../../redux/features/filter/dataManagementFilterSlice';
import { setReFetchFilter } from '../../redux/features/loaderSlice';
import { useGetMsCallExecutionDataMutation } from '../../redux/features/report/visitCallApi';
import { resetVisitCallFilter } from '../../redux/features/report/visitCallFilterSlice';
import ExecutionSkeleton from '../../ui/ExecutionSkeleton';
import NoResult from '../../ui/NoResult';
import firebaseLog from '../../util/firebaseLog';
import msCallBodyData from '../../util/msCallBodyData';
import MsCallItem from './MsCallItem';

function MsCallReport() {
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);
    const {
        dateRange,
        msId,
        msCallType,
        callType,
        aiSatisfactory,
        outletStatusByAi,
        outletStatusByMs,
        outletCode,
        ffName,
    } = useSelector((state) => state.msCallFilter);

    const [getMsCallExecutionData, { data, isLoading, isError, isSuccess, error }] =
        useGetMsCallExecutionDataMutation();

    const fetchExecutionData = (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }
        getMsCallExecutionData({
            page,
            limit: totalShow,
            ...msCallBodyData({
                region,
                area,
                territory,
                town,
                dateRange,
                msId,
                msCallType,
                visitCallType: callType,
                aiSatisfactory,
                outletStatusByAi,
                outletStatusByMs,
                outletCode,
                ffName,
            }),
        });
    };

    useEffect(() => {
        getMsCallExecutionData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchExecutionData(pageNumber, totalPageChange);
    };

    const dispatch = useDispatch();
    const { reFetchFilter } = useSelector((state) => state.globalLoading);

    // reset existing filter
    useEffect(() => {
        dispatch(setReFetchFilter(!reFetchFilter));
        dispatch(resetDataManagementFilter());
        dispatch(resetVisitCallFilter());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'MS Report', user: user.name });
    }, [user.name]);

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="MS Report" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    loading={isLoading}
                    queryFunc={fetchExecutionData}
                    pathname="/ms-execution"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">MS Report</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {!isLoading ? (
                        <>
                            {data?.data?.length <= 0 || !data ? (
                                <NoResult />
                            ) : (
                                <>
                                    {data?.data?.map((item, i) => (
                                        <MsCallItem loading={isLoading} data={item} key={i} />
                                    ))}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {new Array(5).fill('').map((_, i) => (
                                <ExecutionSkeleton key={i} />
                            ))}
                        </>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Pagination
                            size="large"
                            pageSize={totalShowPage}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultCurrent={1}
                            total={data?.meta.total}
                            onChange={onChange}
                            showTotal={(total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default MsCallReport;
