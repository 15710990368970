const outletTypes = [
    {
        value: 'NS',
        label: 'NS',
    },
    {
        value: 'BS',
        label: 'PS', // Bs will be ps in the future. now changed from only frontend
    },
];

export default outletTypes;
