import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useViewGroupListValuesQuery } from '../../redux/features/superuser/superUserApi';
import {
    setGroupName,
    setUserType,
    setUsername,
} from '../../redux/features/superuser/viewUser/viewUserFilterSlice';

function ViewUserFilter({ loading, queryFunc }) {
    const { username } = useSelector((state) => state.superuserViewUserFilter);

    const dispatch = useDispatch();

    const { data, isLoading } = useViewGroupListValuesQuery();

    return (
        <Row gutter={[5, 5]}>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={username}
                    placeholder="Username"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setUsername(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    loading={isLoading}
                    allowClear
                    placeholder="Group Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setGroupName(e))}
                    options={data?.data}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="User Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setUserType(e))}
                    options={[
                        {
                            value: 'Admin Level',
                            label: 'Admin Level',
                        },
                        {
                            value: 'Region Level',
                            label: 'Cluster Level',
                        },
                        {
                            value: 'Area Level',
                            label: 'Area Level',
                        },
                        {
                            value: 'Territory Level',
                            label: 'Territory Level',
                        },
                        {
                            value: 'Town Level',
                            label: 'Town Level',
                        },
                        {
                            value: 'MS Level',
                            label: 'MS Level',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={() => queryFunc(1, 50, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
        </Row>
    );
}

export default ViewUserFilter;
