/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const authData = sessionStorage.getItem('auth');
const { user = {} } = authData ? JSON.parse(authData) : {};

const initialState = {
    ffLevel: ['CM'],
    ffCode: '',
    nidNo: '',
    status: '',
};

const employeeManagementFilterSlice = createSlice({
    name: 'posmViewFilterSlice',
    initialState,
    reducers: {
        setFFLevel: (state, action) => {
            state.ffLevel = action.payload;
        },
        setFFCode: (state, action) => {
            state.ffCode = action.payload;
        },
        setNidNo: (state, action) => {
            state.nidNo = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        resetHrEmployeeManagementFilter: (state, action) => {
            state.ffLevel = ['CM'];
            state.ffCode = '';
            state.nidNo = '';
            state.status = '';
        },
    },
});

export default employeeManagementFilterSlice.reducer;
export const { setFFLevel, setFFCode, setNidNo, setStatus, resetHrEmployeeManagementFilter } =
    employeeManagementFilterSlice.actions;
