const taskTypes = [
    {
        value: 'DA',
        label: 'Category Shelf Display',
    },
    {
        value: 'SOS',
        label: 'Share of Shelf',
    },
    {
        value: 'SACHET',
        label: 'Share of Sachet',
    },
    {
        value: 'POSM',
        label: 'Visibility Material',
    },
];

export default taskTypes;
