/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

function ShareOfVisibility({ data }) {
    const [sovmByCountLabels, setSovmByCountLabels] = useState([]);
    const [sovmByCountValues, setSovmByCountValues] = useState([]);
    const [totalsovmByCount, setTotalsovmByCount] = useState(0);

    const [sovmBySurfaceAreaLabels, setSovmBySurfaceAreaLabels] = useState([]);
    const [sovmBySurfaceAreaValues, setSovmBySurfaceAreaValues] = useState([]);
    const [totalsovmBySurfaceArea, setTotalsovmBySurfaceArea] = useState(0);

    useEffect(() => {
        if (data) {
            const countValues =
                data?.sovmByCount?.filter((x) => x._id)?.map((doc) => doc.total) || [];
            const surfaceAreaValues =
                data?.sovmBySurfaceArea?.filter((x) => x._id)?.map((doc) => doc.total) || [];

            setSovmByCountLabels(
                data?.sovmByCount?.filter((x) => x._id)?.map((doc) => doc._id) || []
            );
            setSovmByCountValues(countValues);
            setTotalsovmByCount(countValues.reduce((acc, val) => acc + val, 0));

            setSovmBySurfaceAreaLabels(
                data?.sovmBySurfaceArea?.filter((x) => x._id)?.map((doc) => doc._id) || []
            );
            setSovmBySurfaceAreaValues(surfaceAreaValues);
            setTotalsovmBySurfaceArea(surfaceAreaValues.reduce((acc, val) => acc + val, 0));
        }
    }, [data]);

    const chartOptions1 = {
        chart: { type: 'donut', height: '100%', width: '100%' },
        colors: [
            '#4F81BD',
            '#82CA9D',
            '#5C6BC0',
            '#29B6F6',
            '#FFA726',
            '#FF7043',
            '#FF8A65',
            '#FFAB91',
            '#FFCCBC',
            '#F8BBD0',
            '#E1BEE7',
            '#D1C4E9',
            '#B3E5FC',
            '#B2EBF2',
            '#B2DFDB',
            '#C8E6C9',
            '#DCEDC8',
            '#F0F4C3',
            '#FFF9C4',
            '#FFECB3',
            '#FFE0B2',
            '#FFCCBC',
            '#FFAB91',
            '#FF8A65',
            '#FF7043',
            '#FFA726',
            '#29B6F6',
            '#5C6BC0',
            '#82CA9D',
            '#4F81BD',
        ],
        dataLabels: { enabled: false },
        tooltip: {
            y: {
                formatter: (val) =>
                    totalsovmByCount > 0
                        ? `${((val / totalsovmByCount) * 100).toFixed(1)}% (${val})`
                        : `0% (${val})`,
            },
        },
        legend: { position: 'right' },
    };

    const chartOptions2 = {
        chart: { type: 'donut', height: '100%', width: '100%' },
        colors: [
            '#4F81BD',
            '#82CA9D',
            '#5C6BC0',
            '#29B6F6',
            '#FFA726',
            '#FF7043',
            '#FF8A65',
            '#FFAB91',
            '#FFCCBC',
            '#F8BBD0',
            '#E1BEE7',
            '#D1C4E9',
            '#B3E5FC',
            '#B2EBF2',
            '#B2DFDB',
            '#C8E6C9',
            '#DCEDC8',
            '#F0F4C3',
            '#FFF9C4',
            '#FFECB3',
            '#FFE0B2',
            '#FFCCBC',
            '#FFAB91',
            '#FF8A65',
            '#FF7043',
            '#FFA726',
            '#29B6F6',
            '#5C6BC0',
            '#82CA9D',
            '#4F81BD',
        ],
        dataLabels: { enabled: false },
        tooltip: {
            y: {
                formatter: (val) =>
                    totalsovmBySurfaceArea > 0
                        ? `${((val / totalsovmBySurfaceArea) * 100).toFixed(1)}% (${val})`
                        : `0% (${val})`,
            },
        },
        legend: { position: 'right' },
    };

    return (
        <div
            className="box-container"
            style={{
                height: '260px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <div className="box-heading box-heading-dash" style={{ position: 'absolute' }}>
                Share Of Visibility Material
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    width: '100%',
                    padding: '35px 12px 12px 12px',
                    position: 'relative',
                    boxSizing: 'border-box',
                }}
            >
                {/* Middle Separator Line */}
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        height: 'calc(100% - 50px)',
                        borderLeft: '1px solid #ddd',
                        zIndex: 1,
                    }}
                />

                <div
                    style={{
                        flex: 1,
                        height: '100%',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: 0,
                    }}
                >
                    {sovmByCountValues.length > 0 && (
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <Chart
                                options={{ ...chartOptions1, labels: sovmByCountLabels }}
                                series={sovmByCountValues}
                                type="donut"
                                height="100%"
                                width="100%"
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '32%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                    pointerEvents: 'none',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                SOVM By
                                <br />
                                Count
                            </div>
                        </div>
                    )}
                </div>

                <div
                    style={{
                        flex: 1,
                        height: '100%',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: 0,
                    }}
                >
                    {sovmBySurfaceAreaValues.length > 0 && (
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <Chart
                                options={{ ...chartOptions2, labels: sovmBySurfaceAreaLabels }}
                                series={sovmBySurfaceAreaValues}
                                type="donut"
                                height="100%"
                                width="100%"
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '32%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                    pointerEvents: 'none',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                SOVM By
                                <br />
                                Surface Area
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ShareOfVisibility;
