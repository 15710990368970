import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setBasicPenaltyData } from '../../redux/features/SharedSalaryModule/evaluationDataSlice';
import { styles } from './styles';

export default function PayableBaseSalaryCalculation() {
    const dispatch = useDispatch();
    const {
        payableBaseSalary,
        payableTravelAllowance,
        payrollStructure,
        totalAbsenceDays,
        totalLeaveDays,
    } = useSelector((state) => state?.currentEvaluation?.evaLuationData);
    const { monthYearSummary, penaltyReason, initialBaseSalary, initialTravelAllowance } =
        useSelector((state) => state?.currentEvaluation);

    const handleBasicPenalty = (value, obj) => {
        if (value === 'reset') {
            dispatch(
                setBasicPenaltyData({
                    label: '',
                    value: 0,
                    total: initialBaseSalary,
                })
            );
        } else {
            dispatch(
                setBasicPenaltyData({
                    label: obj?.label,
                    value,
                    total: initialBaseSalary - value,
                })
            );
        }
    };

    return (
        <div style={styles.section}>
            <h4
                style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                Payable Basic Salary
            </h4>
            <div style={styles.row}>
                <span>Total Absent</span>
                <span>{totalAbsenceDays} Days</span>
            </div>
            <div style={styles.row}>
                <span>Net Days for Basic</span>
                <span>{payableBaseSalary?.netBasicDays} Days</span>
            </div>
            <div style={styles.row}>
                <span>Penalty Reason</span>
                <Select
                    allowClear
                    style={{ width: 200 }}
                    placeholder="Select Reason"
                    options={penaltyReason?.basicPenaltyReason || []}
                    onSelect={(value, obj) => handleBasicPenalty(value, obj)}
                    onClear={() => handleBasicPenalty('reset')}
                    defaultValue={{
                        label: payableBaseSalary?.penaltyCause,
                        value: payableBaseSalary?.penaltyCharge,
                    }}
                />
            </div>
            <div style={styles.row}>
                <span>Penalty Amount</span>
                <span>{payableBaseSalary?.penaltyCharge} Tk</span>
            </div>
            <hr />
            <div style={styles.row}>
                <span>Total Basic Salary</span>
                <span>
                    <strong>BDT {Math.abs(payableBaseSalary?.totalBaseSalary) || 0}</strong>
                </span>
            </div>
        </div>
    );
}
