import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { styles } from './styles';

export default function PayableTravelAllowanceCalculation({ additionalDays, setAdditionalDays }) {
    const dispatch = useDispatch();
    const {
        payableBaseSalary,
        payableTravelAllowance,
        payrollStructure,
        totalAbsenceDays,
        totalLeaveDays,
    } = useSelector((state) => state?.currentEvaluation?.evaLuationData);
    const { monthYearSummary, penaltyReason } = useSelector((state) => state?.currentEvaluation);

    const handleDayCalculation = (forValue, value) => {
        if (forValue === 'awd') {
            setAdditionalDays({ ...additionalDays, awd: value });
        } else if (forValue === 'pd') {
            setAdditionalDays({ ...additionalDays, pd: value });
        }
    };

    return (
        <div style={styles.section}>
            <h4
                style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                Payable Travel Allowance
            </h4>
            <div style={styles.row}>
                <span>Total Working Days</span>
                <span>{payableTravelAllowance?.totalWorkDays} Days</span>
            </div>
            <div style={styles.row}>
                <span>Total Leave</span>
                <span>{totalLeaveDays} Days</span>
            </div>
            <div style={styles.row}>
                <span>Total Absent</span>
                <span>{totalAbsenceDays} Days</span>
            </div>
            <div style={styles.row}>
                <span>Additional Working Days</span>
                <span>
                    <Select
                        // allowClear
                        name="additionalWorkdays"
                        style={{ width: 200 }}
                        placeholder="Select Days"
                        options={[
                            {
                                label: '0',
                                value: 0,
                            },
                            {
                                label: '1',
                                value: 1,
                            },
                            {
                                label: '2',
                                value: 2,
                            },
                            {
                                label: '3',
                                value: 3,
                            },
                            {
                                label: '4',
                                value: 4,
                            },
                        ]}
                        onSelect={(value, obj) => handleDayCalculation('awd', value)}
                        defaultValue={payableTravelAllowance?.additionalWorkdays}
                        onClear={() => handleDayCalculation('awd', 0)}
                    />
                </span>
            </div>
            <div style={styles.row}>
                <span>Penalty Days</span>
                <Select
                    // allowClear
                    name="penaltyDays"
                    style={{ width: 200 }}
                    placeholder="Select Day"
                    options={[
                        {
                            label: '0',
                            value: 0,
                        },
                        {
                            label: '1',
                            value: 1,
                        },
                        {
                            label: '2',
                            value: 2,
                        },
                        {
                            label: '3',
                            value: 3,
                        },
                        {
                            label: '4',
                            value: 4,
                        },
                    ]}
                    onSelect={(value, obj) => handleDayCalculation('pd', value)}
                    defaultValue={payableTravelAllowance?.penaltyDays}
                    onClear={() => handleDayCalculation('pd', 0)}
                />
            </div>
            <hr />
            <div style={styles.row}>
                <span>Total Travel Allowance Salary</span>
                <span>
                    <strong>BDT {payableTravelAllowance?.totalTravelAllowance}</strong>
                </span>
            </div>
        </div>
    );
}
