import { Pagination, Table, message } from 'antd';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import { setGlobalLoading } from '../../redux/features/loaderSlice';
import { useGetPjpDataMutation } from '../../redux/features/pjpManagement/pjpManagementApi';
import TableSkeleton from '../../ui/TableSkeleton';
import firebaseLog from '../../util/firebaseLog';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import getMonthName from '../../util/getMonthName';

function PjpStatus() {
    // filter data
    const { region, area, territory, town } = useSelector((state) => state.dataManagement);
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    const { month, route, outletCode, channel, outletType } = useSelector(
        (state) => state.pjpStatusFilter
    );

    const [getPjpData, { data, isLoading }] = useGetPjpDataMutation();

    function getBodyData(mName, routeName, oCode, channelName, outletTypeName) {
        const bodyData = {};

        if (mName) {
            bodyData.month = getMonthName(mName);
        }
        if (routeName) {
            bodyData.route = routeName;
        }
        if (oCode) {
            bodyData.outletcode = oCode;
        }
        if (channelName) {
            bodyData.channel = channelName;
        }
        if (outletTypeName.length) {
            bodyData.outletType = outletTypeName;
        }

        return bodyData;
    }

    const fetchPjpData = (pageNumber, totalPageChange, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }
        getPjpData({
            ...getDataManagementFilterData({ region, area, territory, town }),
            ...getBodyData(month, route, outletCode, channel, outletType),
            page: pageNumber,
            limit: totalPageChange,
        });
    };

    useEffect(() => {
        getPjpData({
            from: dayjs().startOf('day'),
            to: dayjs().endOf('day'),
            page: currentPage,
            limit: totalShowPage,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPjpData]);

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchPjpData(pageNumber, totalPageChange);
    };

    // user information log
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        // Log a custom event
        firebaseLog({ page: 'PJP Status', user: user.name });
    }, [user.name]);

    // normalize pjp status data
    const pjpData = data?.data?.map(
        ({
            _id: id,
            town: { region: regionName, area: areaName, territory: territoryName, name: townName },
            outlet: {
                name: outletName,
                channel: channelName,
                outletType: outletTypeName,
                slab: slabName,
            },
            assignTo: { name: cmName },
            serviceDay,
            weekNo,
            planVisit,
            visitFrequency,
        }) => ({
            id,
            regionName,
            areaName,
            territoryName,
            townName,
            outletName,
            channelName,
            outletTypeName,
            slabName,
            cmName,
            serviceDay,
            weekNo,
            planVisit,
            visitFrequency,
        })
    );

    // render custom table tag
    function renderTableVal(val) {
        return <span style={{ fontSize: '12px', padding: 0, margin: 0 }}>{val}</span>;
    }

    async function downLoadingFile(doc) {
        const excName = doc?.headers.get('Content-Disposition').split('"')[1] || 'report.xlsx';
        const fResult = await doc.arrayBuffer();
        const blob = new Blob([fResult]);

        const urla = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urla;
        link.setAttribute('download', `${excName}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.auth);
    // download link function
    const [loading, setLoading] = useState(false);

    const download = async ({ url, fileName }) => {
        try {
            dispatch(setGlobalLoading(true));
            setLoading(true);
            // eslint-disable-next-line no-await-in-loop
            const result = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: ` Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    ...getDataManagementFilterData({ region, area, territory, town }),
                    ...getBodyData(month, route, outletCode, channel, outletType),
                    // eslint-disable-next-line no-plusplus
                }),
                mode: 'cors',
            });

            const arr = [];

            arr.push(downLoadingFile(result));

            // eslint-disable-next-line no-await-in-loop
            await Promise.all(arr);
        } catch (error) {
            message.error('Something went wrong');
        } finally {
            dispatch(setGlobalLoading(false));
            setLoading(false);
        }
    };

    return (
        <>
            {/* page title and description */}
            <HelmetHeader title="PJP Status" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    downloadButton={download}
                    loading={isLoading || loading}
                    queryFunc={fetchPjpData}
                    pathname="/pjp-status"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">PJP Status</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <Table
                            size="small"
                            rowKey="id"
                            loading={isLoading}
                            dataSource={pjpData || []}
                            scroll={{ x: 800, y: 500 }}
                            pagination={false}
                        >
                            <Column
                                title="Cluster"
                                dataIndex="regionName"
                                key="regionName"
                                render={(e) => renderTableVal(e)}
                            />
                            <Column
                                title="Area"
                                dataIndex="areaName"
                                key="areaName"
                                render={(e) => renderTableVal(e)}
                            />
                            <Column
                                title="Territory"
                                dataIndex="territoryName"
                                key="territoryName"
                                render={(e) => renderTableVal(e)}
                            />
                            <Column
                                title="Town"
                                dataIndex="townName"
                                key="townName"
                                render={(e) => renderTableVal(e)}
                            />
                            <Column
                                title="Outlet"
                                dataIndex="outletName"
                                key="outletName"
                                render={(e) => renderTableVal(e)}
                            />
                            <Column
                                title="Channel"
                                dataIndex="channelName"
                                key="channelName"
                                render={(e) => renderTableVal(e)}
                            />
                            <Column
                                title="Outlet Type"
                                dataIndex="outletTypeName"
                                key="outletTypeName"
                                render={(e) => renderTableVal(e === 'BS' ? 'PS' : e)}
                            />
                            <Column
                                title="Slab"
                                dataIndex="slabName"
                                key="slabName"
                                render={(e) => renderTableVal(e)}
                                width="10%"
                            />
                            <Column
                                title="Merchandiser Name"
                                dataIndex="cmName"
                                key="cmName"
                                render={(e) => renderTableVal(e)}
                            />
                            <Column
                                title="Service Day"
                                dataIndex="serviceDay"
                                key="serviceDay"
                                render={(e) => renderTableVal(e)}
                            />
                            <Column
                                title="Week No"
                                dataIndex="weekNo"
                                key="weekNo"
                                render={(e) => renderTableVal(e)}
                            />
                            <Column
                                title="Plan Visit"
                                dataIndex="planVisit"
                                key="planVisit"
                                render={(e) => renderTableVal(e)}
                                width="3%"
                            />
                            <Column
                                title="Visit F."
                                dataIndex="visitFrequency"
                                key="visitFrequency"
                                render={(e) => renderTableVal(e)}
                                width="3%"
                            />
                        </Table>
                    )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0 0 10px 0',
                    }}
                >
                    <Pagination
                        size="large"
                        pageSize={totalShowPage}
                        showSizeChanger
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={data?.meta.count}
                        onChange={onChange}
                        pageSizeOptions={[10, 50, 100, 200, 500]}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    />
                </div>
            </div>
        </>
    );
}

export default PjpStatus;
