import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPosmListQuery } from '../../redux/features/inventory/inventoryApiSlice';
import {
    setEligibility,
    setFFCode,
    setFFLevel,
    setMonth,
    setReceived,
    setStatus,
} from '../../redux/features/SharedSalaryModule/salaryEvaluationFilterSlice';
import labelChange from '../../util/labelChange';

function SalaryEvaluationFilters({ pathname, queryFunc, loading, downloadButton }) {
    // user information
    const { user } = useSelector((state) => state.auth);
    const { month, year, eligibility, ffCode } = useSelector(
        (state) => state?.salaryEvaluationFilter
    );
    const projectAccessData = user?.projectAccess
        ?.map((x) => ({ label: labelChange(x), value: x }))
        ?.filter((x) => x.value !== 'MS' && x.value !== 'MTCM');

    const { data, isLoading } = useGetPosmListQuery();

    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    return (
        <>
            {pathname !== '/salary-status' && (
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                    <Select
                        mode="single"
                        allowClear
                        placeholder="Eligibility"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setEligibility(e))}
                        options={[
                            {
                                value: true,
                                label: 'Yes',
                            },
                            {
                                value: false,
                                label: 'No',
                            },
                        ]}
                    />
                </Col>
            )}

            {pathname === '/salary-evolution' && (
                <>
                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                        <DatePicker
                            style={{ width: '100%' }}
                            size="large"
                            picker="month"
                            format="MMMM-YYYY"
                            onChange={(e, v) => dispatch(setMonth(v))}
                            placeholder="Select Month"
                            defaultValue={dayjs(`${month}-${year}`, 'MMMM-YYYY')}
                        />
                    </Col>

                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Input
                            alowClear
                            placeholder="FF Code"
                            size="large"
                            style={{ width: '100%' }}
                            onChange={(e) => dispatch(setFFCode(e?.target?.value))}
                        />
                    </Col>
                </>
            )}

            {pathname === '/salary-status' && (
                <>
                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Select
                            // mode=""
                            loading={isLoading}
                            allowClear
                            placeholder="FF Level"
                            size="large"
                            style={{
                                width: '100%',
                            }}
                            onChange={(e) => dispatch(setFFLevel(e))}
                            options={[
                                {
                                    value: 'MS',
                                    label: 'MS',
                                },
                                {
                                    value: 'CM',
                                    label: 'Merchandiser',
                                },
                            ]}
                        />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Select
                            mode="single"
                            allowClear
                            placeholder="Status"
                            size="large"
                            style={{
                                width: '100%',
                            }}
                            onChange={(e) => dispatch(setStatus(e))}
                            options={[
                                {
                                    value: true,
                                    label: 'Active',
                                },
                                {
                                    value: false,
                                    label: 'InActive',
                                },
                            ]}
                        />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                        <DatePicker
                            style={{ width: '100%' }}
                            size="large"
                            picker="month"
                            format="MMMM-YYYY"
                            onChange={(e, v) => dispatch(setMonth(v))}
                            placeholder="Select Month"
                            defaultValue={dayjs(`${month}-${year}`, 'MMMM-YYYY')}
                        />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Select
                            mode="single"
                            allowClear
                            placeholder="Received"
                            size="large"
                            style={{
                                width: '100%',
                            }}
                            onChange={(e) => dispatch(setReceived(e))}
                            options={[
                                {
                                    value: true,
                                    label: 'Yes',
                                },
                                {
                                    value: false,
                                    label: 'No',
                                },
                            ]}
                        />
                    </Col>
                </>
            )}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    onClick={() => {
                        downloadButton({
                            url: '/v1',
                            fileName: 'Town Visibility Material History.xlsx',
                        });
                    }}
                    size="large"
                    type="primary"
                    danger
                    className="filter-btn"
                    icon={<DownloadOutlined />}
                >
                    Download
                </Button>
            </Col> */}
        </>
    );
}

export default SalaryEvaluationFilters;
