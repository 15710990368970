/* eslint-disable no-underscore-dangle */
import { Button, message, Pagination, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
// In your table component
import {
    setEvaluationData,
    setPenaltyReason,
} from '../../redux/features/SharedSalaryModule/evaluationDataSlice';
import {
    useDisbursedSalaryMutation,
    useGetAllMSEvaluationMutation,
} from '../../redux/features/SharedSalaryModule/salaryEvaluationsApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import generateMonthColumns from '../../util/generateMonthColumns';
import useUserType from '../../util/useUserType';
import SalaryModal from '../SharedSalaryModule/SalaryModal';
import SalarySummary from './SalarySummary';

function MSSalary() {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const userType = useUserType();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    const [triggerMerchandiser, merchandiserOptions] = useGetAllMSEvaluationMutation();
    const [disburseSalary, disbursedOptions] = useDisbursedSalaryMutation();

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);
    const { eligibility, month, year } = useSelector((state) => state?.salaryEvaluationFilter);

    const handleTriggerMerchandiser = useCallback(
        async (payload) => {
            try {
                const res = await triggerMerchandiser(payload);
            } catch (error) {
                message.error('Something went wrong');
            }
        },
        [triggerMerchandiser]
    );

    useEffect(() => {
        handleTriggerMerchandiser({
            month,
            year,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleTriggerMerchandiser]);

    const { evaluations, travelPenaltyReason, basicPenaltyReason, monthSummary, yearSummary } =
        merchandiserOptions?.data?.data || {};

    const handleEvaluationClick = (record) => {
        dispatch(
            setPenaltyReason({
                travelPenaltyReason,
                basicPenaltyReason,
            })
        );
        dispatch(setEvaluationData({ ...record, evaluationType: 're-evaluate' }));
        setIsModalOpen(true);
    };

    // ------
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [visible, setVisible] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState(null);

    const handleModalCancel = () => {
        setVisible(false);
    };

    const getFilterData = (eligible) => {
        const bodyData = {};

        if (eligibility) {
            bodyData.employeeCode = eligible;
        }

        return bodyData;
    };

    const fetchDhHistoryData = async (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }

        const res = await triggerMerchandiser({
            page,
            limit: totalShow,
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            ...getFilterData(eligibility),
        });
    };

    // Generate columns dynamically based on the first row
    const monthColumns = generateMonthColumns(evaluations || []);

    // Table columns
    const columns = [
        {
            title: 'MS Name',
            dataIndex: 'user',
            key: 'user',
            render: (user) => user?.name,
        },
        { title: 'FF Code', dataIndex: 'user', key: 'user', render: (user) => user?.usercode },
        { title: 'NID No', dataIndex: 'nid', key: 'nid' },
        {
            title: 'Cluster',
            dataIndex: 'town',
            key: 'town',
            render: (regions) => regions?.map((t) => t.region).join(', '),
        },
        {
            title: 'Area',
            dataIndex: 'town',
            key: 'town',
            render: (areas) => areas?.map((t) => t.area).join(', '),
        },
        {
            title: 'Territory',
            dataIndex: 'town',
            key: 'town',
            render: (territorys) => territorys?.map((t) => t.territory).join(', '),
        },
        {
            title: 'Town',
            dataIndex: 'town',
            key: 'town',
            render: (towns) => towns?.map((t) => t.name).join(', '),
        },
        ...monthColumns,
        {
            title: 'Eligibility',
            dataIndex: 'variablePayEligibility',
            key: 'variablePayEligibility',
            render: (variablePayEligibility) =>
                variablePayEligibility ? 'Eligible' : 'Not Eligible',
        },
        { title: 'Total', dataIndex: 'totalPayableSalary', key: 'totalPayableSalary' },
        {
            title: 'Basic',
            dataIndex: ['payableBaseSalary', 'totalBaseSalary'],
            key: 'payableBaseSalary',
            align: 'center',
        },
        {
            title: 'Travel Allowance',
            dataIndex: ['payableTravelAllowance', 'totalTravelAllowance'],
            key: 'payableTravelAllowance',
            align: 'center',
        },
        {
            title: 'Variable Pay',
            dataIndex: 'totalPayableIncentive',
            key: 'totalPayableIncentive',
            align: 'center',
        },
        {
            title: 'Action',
            dataIndex: 'approvalWorkflow',
            key: 'approvalWorkflow',
            render: (approvalWorkflow, record) => {
                let isDisabled = false;
                if (
                    approvalWorkflow[userType]?.disbursedAt ||
                    approvalWorkflow[userType]?.reEvaluatedAt
                ) {
                    isDisabled = true;
                }

                return (
                    <Space size="middle">
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => handleEvaluationClick(record)}
                            disabled={isDisabled}
                        >
                            Re-Evaluate
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const handleDisbursed = async () => {
        if (selectedRowKeys?.length <= 0) {
            message.error('Please select at least one record');
            return;
        }
        const res = await disburseSalary(selectedRowKeys);
        console.log(res);

        if (res.data) {
            message.success('Salary  Successfully Disbursed ');
        } else {
            message.error('Failed to Disburse salary');
        }
    };

    return (
        <>
            <HelmetHeader title="MS Salary" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    // downloadButton={download}
                    loading={merchandiserOptions?.isLoading}
                    queryFunc={fetchDhHistoryData}
                    pathname="/salary-disbursement"
                />
            </div>

            <SalarySummary yearSummary={yearSummary} monthSummary={monthSummary} />

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">MS Salary</div>
                <div style={{ padding: '10px', width: '100%' }}>
                    <Table
                        loading={merchandiserOptions?.isLoading}
                        rowSelection={{
                            type: 'checkbox',
                            onChange: (rowKeys, rows) => {
                                setSelectedRows(rows);
                                setSelectedRowKeys(rowKeys);
                            },
                            getCheckboxProps: (record) => ({
                                disabled:
                                    record.approvalWorkflow[userType]?.disbursedAt ||
                                    record.approvalWorkflow[userType]?.disbursedAt,
                            }),
                        }}
                        columns={columns}
                        dataSource={evaluations || []}
                        size="small"
                        rowKey={(record) => record?._id}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                    />
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
                <Pagination
                    size="large"
                    pageSize={totalShowPage}
                    showSizeChanger
                    showQuickJumper
                    current={currentPage}
                    // total={data?.meta.total || 0}
                    onChange={(pageNumber, totalPageChange) => {
                        setTotalPageShow(() => totalPageChange);
                        setCurrentPageShow(pageNumber);
                        fetchDhHistoryData(pageNumber, totalPageChange);
                    }}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div>

            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => handleDisbursed()} type="primary">
                    Disbursed
                </Button>
            </div>

            <SalaryModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                refetch={() => fetchDhHistoryData(currentPage, totalShowPage)}
            />
        </>
    );
}

export default MSSalary;
