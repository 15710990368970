import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../components/DateRange';
import channelListSelect from '../../constents/channelList';
import outletTypes from '../../constents/outletTypes';
import taskTypes from '../../constents/visitCallTaskTypes';
import { useGetPosmListQuery } from '../../redux/features/inventory/inventoryApiSlice';
import { useGetMaterialQuery } from '../../redux/features/materialManagement/materialManagementApi';
import {
    setAiRun,
    setAuditStatus,
    setCallType,
    setChannel,
    setDateRange,
    setDisplayChallengeStatus,
    setDisplayRemarks,
    setDisplayStatus,
    setExclusivity,
    setExecutionDone,
    setFFCode,
    setFFLevel,
    setFFName,
    setFatType,
    setHangerPresence,
    setHotspot,
    setOutletCode,
    setOutletStatusByAi,
    setOutletType,
    setOverallComplianceMet,
    setPlanogramAdherence,
    setPosmCode,
    setPosmName,
    setQpdsStatus,
    setShelfTalkerChallengeStatus,
    setShelfTalkerQuality,
    setShelvingNormMaintained,
    setTask,
    setUblSOSHigh,
    setUblSOVMHigh,
    setUblSachetPresenceHigh,
    setVariantComplianceMet,
} from '../../redux/features/report/visitCallFilterSlice';
import { useSearchEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import labelChange from '../../util/labelChange';

function VisitCallFilter({ queryFunc, loading }) {
    const { outletCode, tmsCode, errorReason, callType, tmrCode, ffLevel, posmCode } = useSelector(
        (state) => state.visitCallFilter
    );

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    const dispatch = useDispatch();

    // get material info
    // get material name
    const { data, isLoading: materialGetLoading } = useGetPosmListQuery();

    // search employee api hook
    const [searchEmployee, { data: employeeData, isLoading }] = useSearchEmployeeMutation();

    // date picker function
    const dataPickerFunc = (_, date) => {
        dispatch(setDateRange(date));
    };

    const getFilterData = (lev) => {
        const bodyData = {};
        if (lev) {
            bodyData.type = lev;
        }
        return bodyData;
    };

    useEffect(() => {
        searchEmployee({
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            ...getFilterData(ffLevel),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchEmployee, circle, region, area, territory, town, ffLevel]);

    const [search, setSearch] = useState('');
    const onSearch = (e) => {
        setSearch(e);
    };

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    const isEditableUser = !!userInfo?.permission?.find((x) => x.label === 'User Edit')?.label;

    const projectAccessData = userInfo?.projectAccess
        ?.map((x) => ({
            label: labelChange(x),
            value: x,
        }))
        ?.filter((x) => x.label !== 'MTM');

    const {
        data: posmData,
        refetch,
        isFetching,
    } = useGetMaterialQuery({
        limit: 10000,
    });
    return (
        <>
            <DateRange dataPickerFunc={dataPickerFunc} />

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    mode="multiple"
                    allowClear
                    placeholder="Task"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setTask(e))}
                    options={taskTypes}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    value={ffLevel}
                    mode="multiple"
                    allowClear
                    placeholder="FF Level"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setFFLevel(e))}
                    options={projectAccessData?.filter((x) => x.label !== 'MTCM') || []}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    loading={isLoading}
                    allowClear
                    showSearch
                    placeholder="FF Name"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setFFName(e))}
                    options={employeeData?.data?.map((emp) => ({
                        label: emp.name,
                        // eslint-disable-next-line no-underscore-dangle
                        value: emp._id,
                    }))}
                    filterOption={(input, option) =>
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    searchValue={search}
                    onSearch={onSearch}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={tmrCode}
                    placeholder="FF Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setFFCode(e.target.value))}
                />
            </Col>

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Error Reason"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setErrorReason(e))}
                    options={[
                        {
                            value: 'qrError',
                            label: 'QR code not match',
                        },
                        {
                            value: 'location',
                            label: 'Location not match',
                        },
                    ]}
                />
            </Col> */}

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setErrorReason(e))}
                    options={[
                        {
                            value: 'passed',
                            label: 'Passed',
                        },
                        {
                            value: 'failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col> */}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Visit Call Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setCallType(e))}
                    options={[
                        {
                            value: 'Schedule Covered',
                            label: 'Schedule Covered',
                        },
                        {
                            value: 'Pending Covered',
                            label: 'Pending Covered',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Channel"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setChannel(e))}
                    options={channelListSelect}
                />
            </Col>

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Visibility Material"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmId(e))}
                    // eslint-disable-next-line no-underscore-dangle
                    options={data?.data?.data?.map((x) => ({ label: x.name, value: x._id }))}
                />
            </Col> */}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    mode="multiple"
                    allowClear
                    placeholder="Outlet Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOutletType(e))}
                    options={outletTypes}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={outletCode}
                    placeholder="Outlet Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setOutletCode(e.target.value))}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Status for Display"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setDisplayStatus(e))}
                    options={[
                        {
                            value: 'Passed',
                            label: 'Passed',
                        },
                        {
                            value: 'Failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Status for QPDS"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setQpdsStatus(e))}
                    options={[
                        {
                            value: 'Passed',
                            label: 'Passed',
                        },
                        {
                            value: 'Failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col>

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Status for Display"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOutletStatusByAi(e))}
                    options={[
                        {
                            value: 'passed',
                            label: 'Passed',
                        },
                        {
                            value: 'failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col> */}

            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Outlet Status by MS"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOutletStatusByMs(e))}
                    options={[
                        {
                            value: 'passed',
                            label: 'Passed',
                        },
                        {
                            value: 'failed',
                            label: 'Failed',
                        },
                    ]}
                />
            </Col> */}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Execution Done"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setExecutionDone(e))}
                    options={[
                        {
                            value: 'yes',
                            label: 'Yes',
                        },
                        {
                            value: 'no',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Fat Asset Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setFatType(e))}
                    options={[
                        {
                            value: 'Backlit',
                            label: 'Backlit',
                        },
                        {
                            value: 'Nonlit',
                            label: 'Nonlit',
                        },
                        {
                            value: 'Distribution Board',
                            label: 'Distribution Board',
                        },
                        {
                            value: 'Mega Hanger',
                            label: 'Mega Hanger',
                        },
                    ]}
                />
            </Col>
            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Shelf Talker Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setShelfTalkerType(e))}
                    options={[
                        {
                            value: 'METRO',
                            label: 'METRO',
                        },
                        {
                            value: 'RURAL',
                            label: 'RURAL',
                        },
                        {
                            value: 'URBAN',
                            label: 'URBAN',
                        },
                        {
                            value: 'NON-LIT',
                            label: 'NON-LIT',
                        },
                        {
                            value: 'PVC LIT',
                            label: 'PVC LIT',
                        },
                        {
                            value: 'LIT',
                            label: 'LIT',
                        },
                    ]}
                />
            </Col> */}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Visibility Material"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPosmName(e))}
                    // eslint-disable-next-line no-underscore-dangle
                    options={posmData?.data?.data?.map((x) => ({ label: x.name, value: x.name }))}
                    showSearch
                    filterOption={(input, option) =>
                        option.value.toLowerCase().includes(input.toLowerCase())
                    }
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={posmCode}
                    placeholder="Visibility Material Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setPosmCode(e.target.value))}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Display Challenge Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setDisplayChallengeStatus(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Display Remarks"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setDisplayRemarks(e))}
                    options={[
                        {
                            value: 'Program Participation Issue',
                            label: 'Program Participation Issue',
                        },
                        {
                            value: 'Shelf Talker Issue',
                            label: 'Shelf Talker Issue',
                        },
                        {
                            value: 'Shelf Space Issue- Low Height',
                            label: 'Shelf Space Issue- Low Height',
                        },
                        {
                            value: 'Shelf Space Issue- Low Length',
                            label: 'Shelf Space Issue- Low Length',
                        },
                        {
                            value: 'Shelf Position Issue',
                            label: 'Shelf Position Issue',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Shelf Talker Challenge Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setShelfTalkerChallengeStatus(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Audit Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setAuditStatus(e))}
                    options={[
                        {
                            value: 'Pending',
                            label: 'Pending',
                        },
                        {
                            value: 'Done',
                            label: 'Done',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Hotspot"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setHotspot(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Exclusivity"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setExclusivity(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Planogram Adherence"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setPlanogramAdherence(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    // value={posmCode}
                    placeholder="Shelf Talker Quality"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setShelfTalkerQuality(e.target.value))}
                />
            </Col> */}
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Variant Compliance Met"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setVariantComplianceMet(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Overall Compliance Met"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setOverallComplianceMet(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                {/* <Input
                    // value={posmCode}
                    placeholder="Shelf Talker Quality"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setShelfTalkerQuality(e.target.value))}
                /> */}
                <Select
                    allowClear
                    placeholder="Shelf Talker"
                    options={[
                        {
                            label: 'Faded',
                            value: 'Faded',
                        },
                        {
                            label: 'Damaged',
                            value: 'Damaged',
                        },
                        {
                            label: 'Ok',
                            value: 'Ok',
                        },
                        {
                            label: 'Not Present',
                            value: 'Not Present',
                        },
                    ]}
                    onChange={(e) => dispatch(setShelfTalkerQuality(e))}
                    size="large"
                    style={{
                        width: '100%',
                    }}
                />
            </Col>
            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="UBL SOS (High)"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    // onChange={(e) => dispatch(setUblSOSHigh(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col> */}
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="UBL Visibility Material (High)"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setUblSOVMHigh(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Shelving Normal Maintained"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setShelvingNormMaintained(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="UBL SOS (High)"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setUblSOSHigh(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="UBL Sachet Presence (High)"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setUblSachetPresenceHigh(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Hanger Presence"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setHangerPresence(e))}
                    options={[
                        {
                            value: 'Brand',
                            label: 'Brand',
                        },
                        {
                            value: 'Combined',
                            label: 'Combined',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>

            {isEditableUser ? (
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                    <Select
                        allowClear
                        placeholder="AI Run"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setAiRun(e))}
                        options={[
                            {
                                value: 'yes',
                                label: 'Yes',
                            },
                            {
                                value: 'no',
                                label: 'No',
                            },
                        ]}
                    />
                </Col>
            ) : null}

            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    loading={loading}
                    disabled={loading}
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default VisitCallFilter;
