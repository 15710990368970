/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { Button, message, Pagination, Space, Table } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../components/Filter';
import HelmetHeader from '../../components/HelmetHeader';
import {
    setEvaluationData,
    setPenaltyReason,
} from '../../redux/features/SharedSalaryModule/evaluationDataSlice';
import { resetSalaryDisbursementFilter } from '../../redux/features/SharedSalaryModule/salaryEvaluationFilterSlice';
import {
    useApproveSalaryEvaluationMutation,
    useGetAllMerchandiserEvaluationMutation,
} from '../../redux/features/SharedSalaryModule/salaryEvaluationsApi';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import generateMonthColumns from '../../util/generateMonthColumns';
import useUserType from '../../util/useUserType';
import SalaryModal from '../SharedSalaryModule/SalaryModal';

// Extend Day.js with the isBetween plugin
dayjs.extend(isBetween);

function MerchandiserEvaluation() {
    const userType = useUserType();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    const [triggerMerchandiser, merchandiserOptions] = useGetAllMerchandiserEvaluationMutation();
    const [approveSalaryEvaluation, salaryEvaluationOptions] = useApproveSalaryEvaluationMutation();

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);
    const { month, year, ffCode, eligibility } = useSelector(
        (state) => state?.salaryEvaluationFilter
    );

    const getTownPosmHistory = () => {};
    const data = {
        data: [],
        meta: {
            total: 0,
        },
    };

    const handleTriggerMerchandiser = useCallback(
        async (payload) => {
            try {
                const res = await triggerMerchandiser(payload);
            } catch (error) {
                message.error('Something went wrong');
            }
        },
        [triggerMerchandiser]
    );

    useEffect(() => {
        dispatch(resetSalaryDisbursementFilter());
        handleTriggerMerchandiser({
            month: month || dayjs().format('MMMM'),
            year: year || dayjs().format('YYYY'),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleTriggerMerchandiser]);

    const {
        evaluations,
        travelPenaltyReason,
        basicPenaltyReason,
        approvalWorkflow: appWorkflow,
        monthSummary,
        yearSummary,
    } = merchandiserOptions?.data?.data || {};

    useEffect(() => {
        getTownPosmHistory({ page: currentPage, limit: totalShowPage });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEvaluationClick = (record) => {
        dispatch(
            setPenaltyReason({
                travelPenaltyReason,
                basicPenaltyReason,
            })
        );

        dispatch(setEvaluationData({ ...record, evaluationType: 're-evaluate' }));
        setIsModalOpen(true);
    };

    const getFilterData = (mon, yer, eligi, ffC) => {
        const bodyData = {};

        if (mon) {
            bodyData.month = mon;
        }
        if (yer) {
            bodyData.year = yer;
        }
        if (eligibility) {
            bodyData.eligibility = eligi;
        } else if (eligibility === false) {
            bodyData.eligibility = eligi;
        }
        if (ffCode) {
            bodyData.usercode = ffC;
        }

        return bodyData;
    };

    const filterData = async (page, totalShow, clean) => {
        if (clean === 'cleanShowResultOnPage') {
            setCurrentPageShow(1);
            setTotalPageShow(10);
        }

        const res = await triggerMerchandiser({
            page,
            limit: totalShow,
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            ...getFilterData(month, year, eligibility, ffCode),
        });
    };

    const { reFetchFilter } = useSelector((state) => state.globalLoading);

    // Generate columns dynamically based on the first row
    const monthColumns = generateMonthColumns(evaluations || []);

    // Table columns
    const columns = [
        {
            title: ' ',
            children: [
                {
                    title: 'FF Name',
                    dataIndex: 'user',
                    key: 'user',
                    render: (user) => user?.name,
                },
                {
                    title: 'FF Code',
                    dataIndex: 'user',
                    key: 'user',
                    render: (user) => user?.usercode,
                },
                // { title: 'NID No', dataIndex: 'nid', key: 'nid' },
                {
                    title: 'Cluster',
                    dataIndex: 'town',
                    key: 'region',
                    render: (t) => [...new Set(t?.map((r) => r?.region))].join(', '),
                },
                {
                    title: 'Area',
                    dataIndex: 'town',
                    key: 'area',
                    render: (t) => [...new Set(t?.map((r) => r?.area))].join(', '),
                },
                {
                    title: 'Territory',
                    dataIndex: 'town',
                    key: 'territory',
                    render: (t) => [...new Set(t?.map((r) => r?.territory))].join(', '),
                },
                {
                    title: 'Town',
                    dataIndex: 'town',
                    key: 'name',
                    render: (t) => [
                        ...new Set(
                            t?.map((r, index, arr) => (
                                <span key={r?.towncode}>
                                    {r?.name} <small>({r?.towncode})</small>
                                    {index < arr.length - 1 ? ', ' : ''}
                                </span>
                            ))
                        ),
                    ],
                },
                {
                    title: 'Eligibility',
                    dataIndex: 'variablePayEligibility',
                    key: 'variablePayEligibility',
                    render: (variablePayEligibility) => (variablePayEligibility ? 'Yes' : 'No'),
                    align: 'center',
                },
                ...monthColumns,
            ],
        },
        {
            title: `${month} Breakdown`,
            align: 'center',
            children: [
                {
                    title: 'Basic',
                    dataIndex: ['payableBaseSalary', 'totalBaseSalary'],
                    key: 'payableBaseSalary',
                    align: 'center',
                },
                {
                    title: 'Travel Allowance',
                    dataIndex: ['payableTravelAllowance', 'totalTravelAllowance'],
                    key: 'payableTravelAllowance',
                    align: 'center',
                },
                {
                    title: 'Variable Pay',
                    dataIndex: 'totalPayableIncentive',
                    key: 'totalPayableIncentive',
                    align: 'center',
                },
            ],
        },
        {
            title: ' ',
            children: [
                {
                    title: 'Action',
                    dataIndex: 'approvalWorkflow',
                    key: 'approvalWorkflow',
                    render: (approvalWorkflow, record) => {
                        let isApproved = false;
                        let isReEvaluate = false;
                        let btnDisabled = false;
                        if (approvalWorkflow[userType]?.reEvaluatedAt) {
                            isReEvaluate = true;
                        }
                        if (approvalWorkflow[userType]?.approvedAt) {
                            isApproved = true;
                        }
                        if (isApproved || isReEvaluate) {
                            btnDisabled = true;
                        }

                        return (
                            <Space size="middle">
                                <Button
                                    type="primary"
                                    size="small"
                                    disabled={btnDisabled}
                                    onClick={() => handleEvaluationClick(record)}
                                >
                                    Re-Evaluate
                                </Button>
                            </Space>
                        );
                    },
                },
            ],
        },
    ];

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        filterData(pageNumber, totalPageChange);
    };

    const handleApprovalClick = async () => {
        if (selectedRowKeys?.length <= 0) {
            message.error('Please select at least one record');
            return;
        }
        const res = await approveSalaryEvaluation(selectedRowKeys);

        if (res.data) {
            message.success('Salary evaluation approved successfully');
            filterData(currentPage, totalShowPage);
        } else {
            message.error('Failed to approve salary evaluation');
        }
    };

    return (
        <>
            <HelmetHeader title="Merchandiser Evaluation" />

            <div style={{ margin: '16px 0' }}>
                <Filter
                    // downloadButton={download}
                    loading={merchandiserOptions?.isLoading}
                    queryFunc={filterData}
                    pathname="/salary-evolution"
                />
            </div>

            <div style={{ borderRadius: '10px' }}>
                <div className="box-heading">Merchandiser Evaluation</div>

                <div style={{ padding: '10px', width: '100%' }}>
                    {' '}
                    <Table
                        loading={merchandiserOptions?.isLoading}
                        rowSelection={{
                            type: 'checkbox',
                            onChange: (rowKeys, rows) => {
                                setSelectedRows(rows);
                                setSelectedRowKeys(rowKeys);
                            },
                            getCheckboxProps: (record, r) =>
                                // console.log(record?._id, selectedRowKeys);
                                {
                                    const staticType = {
                                        areaManager: 'areaManager',
                                        territoryManager: 'territoryManager',
                                    };

                                    return {
                                        disabled:
                                            record.approvalWorkflow[staticType.areaManager]
                                                ?.approvedAt ||
                                            record.approvalWorkflow[staticType.territoryManager]
                                                ?.approvedAt,
                                    };
                                },
                        }}
                        rowClassName={(record) =>
                            !record.variablePayEligibility ? 'not_eligible' : null
                        }
                        scroll={{
                            x: 'auto',
                        }}
                        columns={columns}
                        dataSource={evaluations || []}
                        size="small"
                        rowKey={({ _id }) => _id}
                        pagination={false}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Pagination
                    size="large"
                    pageSize={totalShowPage}
                    showSizeChanger
                    showQuickJumper
                    current={currentPage}
                    defaultCurrent={1}
                    total={merchandiserOptions?.data?.meta.total || 0}
                    onChange={onChange}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div>

            {evaluations?.length > 0 ? (
                <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        type="primary"
                        disabled={evaluations?.length <= 0}
                        onClick={() => handleApprovalClick()}
                    >
                        Approve
                    </Button>
                </div>
            ) : null}

            <SalaryModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                // triggerMerchandiser={triggerMerchandiser}
                refetch={() => filterData(currentPage, totalShowPage)}
            />
        </>
    );
}

export default MerchandiserEvaluation;
