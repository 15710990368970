/* eslint-disable no-unsafe-optional-chaining */
import { Card } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import EmployeeHeader from './EmployeeHeader';
import NavigationButtons from './NavigationButtons';
import { styles } from './styles';

function SalaryRow({ label, value, isTotal = false }) {
    const rowStyle = isTotal
        ? {
              ...styles.row,
              //   marginTop: '24px',
              //   borderTop: '2px solid #1890ff',
              //   borderBottom: '2px solid #1890ff',
              //   padding: '16px 0',
              fontWeight: 'bold',
              fontSize: '18px',
          }
        : {
              ...styles.row,
              padding: '5px 0',
              //   borderBottom: '1px solid #f0f0f0',
          };

    return (
        <div style={rowStyle}>
            <span>{label}</span>
            <span>{value}</span>
        </div>
    );
}

export default function FinalSalaryCalculation({ data, onBack, onSubmit, isLastPage }) {
    const { totalPayableSalary } = data || {};
    const { evaLuationData, trAmount, tmAmount, variablePayTotal } = useSelector(
        (state) => state?.currentEvaluation
    );
    if (!data) return null;

    const totalPayable =
        evaLuationData?.payableBaseSalary?.totalBaseSalary +
        evaLuationData?.payableTravelAllowance?.totalTravelAllowance +
        evaLuationData?.totalPayableIncentive +
        (trAmount + tmAmount);

    return (
        <div style={styles.container}>
            <Card style={styles.card}>
                {/* <X style={styles.closeButton} size={20} onClick={onBack} /> */}
                <EmployeeHeader data={data} />

                <h3
                    style={{
                        textAlign: 'center',
                        fontSize: '25px',
                        fontWeight: 'bold',
                    }}
                >
                    Total Payable Salary
                </h3>

                <div>
                    <SalaryRow
                        label="Payable Basic Salary"
                        value={`BDT ${evaLuationData?.payableBaseSalary?.totalBaseSalary}`}
                    />
                    <SalaryRow
                        label="Payable Travel Allowance Amount"
                        value={`BDT ${evaLuationData?.payableTravelAllowance?.totalTravelAllowance}`}
                    />
                    <SalaryRow
                        label="Payable Variable Pay Amount"
                        value={`BDT ${evaLuationData.totalPayableIncentive + trAmount + tmAmount}`}
                    />
                    <SalaryRow
                        label="Festive Bonus"
                        value={`BDT ${evaLuationData?.payrollStructure?.specialBonus}`}
                    />
                    <hr />
                    <SalaryRow label="Total Salary" value={`BDT ${totalPayable}`} isTotal />
                </div>

                <NavigationButtons onBack={onBack} onSubmit={onSubmit} isLastPage={isLastPage} />
            </Card>
        </div>
    );
}
