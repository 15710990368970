import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchEmployeeMutation } from '../../redux/features/teamManagement/teamManagementApi';
import {
    setEmpCode,
    setEmpLevel,
    setEmpName,
    setEmpUsername,
    setStatus,
    setSupervisor,
} from '../../redux/features/teamManagement/teamManagementFilterSlice';
import getDataManagementFilterData from '../../util/generateDataManagementFilterData';
import labelChange from '../../util/labelChange';

function TeamManagementFilter({ queryFunc, loading, downloadButton }) {
    const { empId, empName, empUsername, empCode } = useSelector(
        (state) => state.teamManagementFilter
    );

    // filter data
    const { circle, region, area, territory, town } = useSelector((state) => state.dataManagement);

    const [searchEmployee, { data, isLoading }] = useSearchEmployeeMutation();

    useEffect(() => {
        searchEmployee({
            ...getDataManagementFilterData({ circle, region, area, territory, town }),
            type: 'MS',
        });
    }, [circle, region, area, territory, town, searchEmployee]);

    const dispatch = useDispatch();

    // user information
    const { user } = useSelector((state) => state.auth);
    const projectAccessData = user?.projectAccess?.map((x) => ({
        label: labelChange(x),
        value: x,
    }));

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="FF Level"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setEmpLevel(e))}
                    options={projectAccessData || []}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    disabled={isLoading}
                    loading={isLoading}
                    allowClear
                    placeholder="Supervisor"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setSupervisor(e))}
                    options={data?.data?.map(({ name, _id }) => ({ label: name, value: _id }))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={empCode}
                    placeholder="FF Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmpCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={empUsername}
                    placeholder="FF Username"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmpUsername(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={empName}
                    placeholder="FF Name"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmpName(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="Status"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setStatus(e))}
                    options={[
                        {
                            value: 'active',
                            label: 'Active',
                        },
                        {
                            value: 'inactive',
                            label: 'Inactive',
                        },
                    ]}
                />
            </Col>
            {/*  */}
            {/* <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    value={empId}
                    placeholder="Emp ID"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(setEmpId(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    placeholder="ID Lock"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setIdLock(e))}
                    options={[
                        {
                            value: 'yes',
                            label: 'Yes',
                        },
                        {
                            value: 'no',
                            label: 'No',
                        },
                    ]}
                />
            </Col> */}
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 5,
                    }}
                >
                    <Button
                        loading={loading}
                        disabled={loading}
                        size="large"
                        className="filter-btn"
                        icon={<SearchOutlined />}
                        type="primary"
                        onClick={() => queryFunc(1, 10, 'cleanShowResultOnPage')}
                    >
                        Search
                    </Button>
                    <Button
                        loading={loading}
                        disabled={loading}
                        onClick={() => {
                            downloadButton({
                                url: '/v1/report/employee-list',
                                fileName: 'Employee List.xlsx',
                            });
                        }}
                        size="large"
                        danger
                        type="primary"
                    >
                        Download
                    </Button>
                </div>
            </Col>
        </>
    );
}

export default TeamManagementFilter;
